import React from "react";
import { LinkProps, NavLink } from "react-router-dom";
import "./style.scss";
import classNames from "classnames";

interface buttonElement extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  tag?: "button";
}
interface anchorElement extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag?: "a";
}

interface anchorTag extends LinkProps {
  tag?: "NavLink";
}

interface generalProps {
  className?: string;
  theme?: "clear" | "with_border" | "dark" | "bright";
  size?: "large" | "medium" | "small" | "xsmall";
  rounded?: boolean;
  active?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export type ButtonProps = generalProps &
  (buttonElement | anchorElement | anchorTag);

export const Button = ({
  tag = "button",
  type,
  className,
  theme = "clear",
  size = "large",
  rounded,
  children,
  ...props
}: ButtonProps) => {
  const btnClassName = classNames(
    "button",
    className,
    `button_${size}`,
    `button_${theme}`,
    {
      rounded_border: rounded,
    }
  );

  if (tag === "NavLink") {
    const { to, ...restProps } = props as anchorTag;
    return React.createElement(
      NavLink,
      { ...restProps, to, className: btnClassName },
      children
    );
  } else {
    return React.createElement(
      tag,
      {
        ...props,
        className: btnClassName,
        type: !type && tag === "button" ? "button" : type,
      },
      children
    );
  }
};
