import React from "react";
import "./style.scss";
import { SuccessIcon } from "../Icons/SuccessIcon";
import { ErrorIcon } from "../Icons/ErrorIcon";

interface NotificationBoxProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  className?: string;
  correct?: boolean;
  position?: "absolute" | "relation";
}

export const NotificationBox = ({
  title,
  content,
  className,
  correct,
  position = "relation",
}: NotificationBoxProps) => {
  return (
    <div
      className={
        "notificationBox" +
        (className ? " " + className : "") +
        " notificationBox_position_" +
        position
      }
    >
      {title && <h2 className="notificationBox__head">{title}</h2>}
      <div className="notificationBox__content">
        {correct ? <SuccessIcon /> : correct === false && <ErrorIcon />}
        <span className="notificationBox__text">{content}</span>
      </div>
    </div>
  );
};
