import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Html5Qrcode,
  Html5QrcodeResult,
  Html5QrcodeSupportedFormats,
  QrcodeSuccessCallback,
} from "html5-qrcode";
import { Html5QrcodeScannerConfig } from "html5-qrcode/esm/html5-qrcode-scanner";

interface QRScannerProps extends React.HTMLAttributes<HTMLDivElement> {
  isEnabled: boolean;
  QRScannerStop?: () => void;
  QRScannerSuccess?: (v: string) => void;
  QRScannerSuccessUseResult?: (v: Html5QrcodeResult) => void;
  QRScannerError?: (v: unknown) => void;
  QRboxWidth?: number;
  QRboxHeight?: number;
}

export const QRScanner = ({
  isEnabled,
  QRScannerStop,
  QRScannerError,
  QRScannerSuccess,
  QRScannerSuccessUseResult,
  QRboxHeight = 300,
  QRboxWidth = 300,
  className,
  ...props
}: QRScannerProps) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const qrcodeRegionId = "qrcodeRegionId";

  useEffect(() => {
    const html5Qrcode = new Html5Qrcode(qrcodeRegionId);
    const config: Html5QrcodeScannerConfig = {
      fps: 10,
      qrbox: { width: QRboxWidth, height: QRboxHeight },
      aspectRatio: window.innerWidth / window.innerHeight,
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    };

    const scannerSuccess: QrcodeSuccessCallback = (
      decodedText: string,
      result: Html5QrcodeResult
    ) => {
      setIsSuccess(true);
      setTimeout(() => {
        if (QRScannerSuccess) QRScannerSuccess(decodedText);
        if (QRScannerSuccessUseResult) QRScannerSuccessUseResult(result);
      }, 1000);
    };

    const scannerStop = () => {
      if (html5Qrcode && html5Qrcode.isScanning) {
        html5Qrcode
          .stop()
          .then(() => {
            html5Qrcode.clear();
            if (QRScannerStop) QRScannerStop();
            setIsSuccess(false);
          })
          .catch((error) => {
            if (QRScannerError) QRScannerError(error);
          });
      }
    };

    if (isEnabled) {
      html5Qrcode.start(
        { facingMode: "environment" },
        config,
        scannerSuccess,
        () => {
          setIsSuccess(false);
        }
      );
    } else {
      scannerStop();
      setIsSuccess(false);
    }

    return () => {
      scannerStop();
      setIsSuccess(false);
    };
  }, [isEnabled]);

  return (
    <div
      {...props}
      className={
        "QRScanner" +
        (className ? " " + className : "") +
        (isSuccess ? " QRScanner_success" : "")
      }
      id={qrcodeRegionId}
    />
  );
};

type QRScannerModalProps = {
  labelBtn?: string;
  QRScannerStop?: () => void;
  QRScannerSuccess?: (v: string) => void;
  QRScannerSuccessUseResult?: (v: Html5QrcodeResult) => void;
  QRScannerError?: (v: unknown) => void;
  QRboxWidth?: number;
  QRboxHeight?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const QRScannerModal = ({
  QRScannerError,
  QRScannerStop,
  QRScannerSuccess,
  QRScannerSuccessUseResult,
  QRboxHeight = 300,
  QRboxWidth = 300,
}: QRScannerModalProps) => {
  const [isEnabled, setIsEnable] = useState<boolean>(true);
  return (
    <QRScanner
      QRboxWidth={QRboxWidth}
      QRboxHeight={QRboxHeight}
      isEnabled={isEnabled}
      QRScannerSuccess={(v) => {
        if (QRScannerSuccess) QRScannerSuccess(v);
        // setIsEnable(false);
      }}
      QRScannerSuccessUseResult={(v) => {
        if (QRScannerSuccessUseResult) QRScannerSuccessUseResult(v);
        // setIsEnable(false);
      }}
      QRScannerStop={() => {
        if (QRScannerStop) QRScannerStop();
        setIsEnable(false);
      }}
      QRScannerError={(e) => {
        if (QRScannerError) QRScannerError(e);
      }}
    />
  );
};
