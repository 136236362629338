import React, { useLayoutEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { routeEndpoint } from "Shared/Routes";
import { LogoutForm } from "Features/Logout";
import { InfoItem, Modal, NotificationBox } from "Shared/UI";
import { PenIcon } from "Shared/UI/Icons/PenIcon";
import { LogoutQuestionForm } from "Widgets/LogoutQuestionForm";
import { ChoiseUserImgForm } from "Widgets/ChoseUserImgForm";
import { CancleEditingForm } from "Widgets/CancleEditingForm";
import { getPersonalInfo } from "Features/GetPersonalInfo";
import { updatePersonalInfo } from "Features/UpdatePersonalInfo";
import { imgsList } from "Shared/Assets";
import { DeleteAccountButton } from "Features/DeleteAccount";

export const ProfilePage = () => {
  const AuthState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const userImgsArr = imgsList.UserAvatar;

  const [isShowMessegeLogout, setIsShowMessegeLogout] =
    useState<boolean>(false);
  const [isShowImgArrForm, setIsShowImgArrForm] = useState<boolean>(false);
  const [isShowCancleForm, setIsShowCancleForm] = useState<boolean>(false);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(getPersonalInfo());
  }, []);

  const handleShowChosienArrForm = () => {
    if (setIsShowImgArrForm)
      if (!isShowImgArrForm) {
        setIsShowImgArrForm(true);
      }
  };

  const userAvatarImg: string =
    AuthState.userItem?.avatar && Array.isArray(userImgsArr)
      ? userImgsArr.find((item) => item.id === AuthState.userItem?.avatar)
          ?.img || userImgsArr[0].img
      : userImgsArr[0].img;

  const OnClickSaveBtnChoiseUserImgForm = async (v: string | null) => {
    await dispatch(updatePersonalInfo({ avatar: v !== null ? v : "1" }));
    setIsShowImgArrForm(false);
  };
  return (
    <main className={"profilePage"}>
      <div className={"profilePage__wrap"}>
        <h1 className="profilePage__head">Личный кабинет</h1>
        <div className="profilePage__img">
          <img src={userAvatarImg} alt="Аватар" />
          <div
            onClick={handleShowChosienArrForm}
            className="profilePage__penIcon"
          >
            <PenIcon />
          </div>
        </div>
        <div className="profilePage__items">
          {AuthState.isAuth && (
            <InfoItem
              className="profilePage__itme profilePage__nameItem"
              itemType="Name"
              typeValue={AuthState.userItem?.name}
              onClick={() => {
                navigate("/" + routeEndpoint.updateName);
              }}
            />
          )}
          <div className="profilePage__boxes">
            {AuthState.isAuth && (
              <InfoItem
                typeValue={AuthState.userItem?.email}
                itemType="Email"
                onClick={() => {
                  navigate("/" + routeEndpoint.updateEmail);
                }}
                className="profilePage__itme"
              />
            )}
            {AuthState.isAuth && (
              <InfoItem
                typeValue={AuthState.userItem?.password}
                itemType="Пароль"
                className="profilePage__itme"
                onClick={() => {
                  navigate("/" + routeEndpoint.updatePersonalPassword);
                }}
              />
            )}
          </div>
        </div>
        <div className="profilePage__buttons">
          <LogoutForm
            isShowForm={isShowMessegeLogout}
            setSowingForm={setIsShowMessegeLogout}
            className="profilePage__logout"
          />
          <DeleteAccountButton />
        </div>
      </div>

      {AuthState.statusMessage !== null && (
        <NotificationBox
          content={AuthState.statusMessage?.message}
          correct={AuthState.statusMessage?.status === "success"}
          className="profilePage__notification"
        />
      )}

      <Modal setIsShow={setIsShowMessegeLogout} isShow={isShowMessegeLogout}>
        <LogoutQuestionForm
          isShowForm={isShowMessegeLogout}
          setShowingForm={setIsShowMessegeLogout}
        />
      </Modal>

      <Modal isShow={isShowImgArrForm} setIsShow={setIsShowImgArrForm}>
        <ChoiseUserImgForm
          isShowForm={isShowCancleForm}
          setIsShowForm={setIsShowCancleForm}
          imgsArr={userImgsArr}
          initialValue={AuthState.userItem?.avatar && "1"}
          onClickSaveBtn={OnClickSaveBtnChoiseUserImgForm}
        />
      </Modal>
      <Modal isShow={isShowCancleForm} setIsShow={setIsShowCancleForm}>
        <CancleEditingForm
          isShowForm={isShowCancleForm}
          setShowingForm={setIsShowCancleForm}
          onClickCancelFunc={() => {
            setIsShowImgArrForm(false);
            setIsShowCancleForm(false);
          }}
        />
      </Modal>
      {AuthState.statusMessage !== null && (
        <NotificationBox
          correct={AuthState.statusMessage.status === "success"}
          content={AuthState.statusMessage.message}
          className={"profilePage__notification"}
        />
      )}
    </main>
  );
};
