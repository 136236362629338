import { AxiosResponse } from "axios";
import { FileResponse } from "Entities/File";
import { $api, urlEndpoints } from "Shared/API";

export class FileService {
  static async delete(id: number): Promise<AxiosResponse<FileResponse>> {
    return $api.delete<FileResponse>(urlEndpoints.FILE.delete, {
      data: {
        id,
      },
    });
  }
}
