import React, { useEffect, useState } from "react";
import "./style.scss";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../Header/UI/ui";
import { Footer } from "../Footer/UI/ui";
import { routeEndpoint } from "Shared/Routes";

export const Layout = () => {
  const location = useLocation();
  const [isNotShowFooter, setIsNotShowFooter] = useState<boolean>(
    location.pathname === "/" + routeEndpoint.scannerQR &&
      window.innerWidth < 768
  );

  useEffect(() => {
    setIsNotShowFooter(
      location.pathname === "/" + routeEndpoint.scannerQR &&
        window.innerWidth < 768
    );
  }, [window.innerWidth, location.pathname]);

  return (
    <div className="layout">
      <Header className="layout__header" />
      <Outlet />
      {!isNotShowFooter && <Footer className="layout__footer" />}
    </div>
  );
};
