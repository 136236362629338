import { useAppDispatch, useAppSelector } from "Shared/Model";
import { Button } from "Shared/UI";
import React from "react";
import { createCatalog } from "../Model/Store/actionCreator";
import { CATALOG_TYPE } from "Shared/Types";

interface CreateCatalogButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  name: string;
}

export const CreateCatalogButton = ({
  className,
  name,
  onClick,
  ...props
}: CreateCatalogButtonProps) => {
  const dispatch = useAppDispatch();
  const AuthState = useAppSelector((state) => state.auth);
  const PasswordsOrFiles = useAppSelector(
    (state) => state.catalogPage.passwordsOrFiles
  );

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // eslint-disable-next-line no-constant-condition
    if ((AuthState.isAuth && AuthState.userItem !== null) || true)
      dispatch(
        createCatalog(
          PasswordsOrFiles === "left"
            ? CATALOG_TYPE.PASSWORDS
            : CATALOG_TYPE.FILES,
          AuthState?.userItem?.id || 0,
          name
        )
      );
    if (onClick) onClick(e);
  };
  return (
    <Button
      {...props}
      theme="bright"
      type="submit"
      onClick={onSubmit}
      className={"createCatalogButton" + (className ? " " + className : "")}
    >
      Добавить
    </Button>
  );
};
