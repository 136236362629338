import React, { useEffect, useState } from "react";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { NavLink, useNavigate } from "react-router-dom";
import { registration } from "../Model/Store/actionCreator";
import { Button, Checkbox, EyeBtnForPassword, Input, Modal } from "Shared/UI";
import { useValidationFieldForm } from "Shared/Lib";

export const RegistrationForm = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [nickName, setNickName] = useState<string>("");

  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isDirtyPasswordModal, setIsDirtyPasswordModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [isVisiblePasswordRepeat, setIsVisiblePasswordRepeat] =
    useState<boolean>(false);

  const [isShowPasswordModal, setIsShowPasswordModal] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const AuthState = useAppSelector((state) => state.auth);

  const emailValidation = useValidationFieldForm({
    value: email.trim(),
    options: { required: true, maxLength: 256, isEmail: true },
    messageText: {
      isEmail: "Проверьте правильность введенной почты.",
    },
  });
  const nickNameValidation = useValidationFieldForm({
    value: nickName.trim(),
    options: { required: true, maxLength: 256, isOnlyRussAndEngLetters: true },
  });
  const passwordValidation = useValidationFieldForm({
    value: password,
    options: {
      required: true,
      maxLength: 50,
      minLength: 8,
      isPassword: true,
      isContainCapitalLetter: true,
      isContainDigit: true,
      isContainSpecCharacter: true,
    },
    messageText: {
      maxLength: "Пароль должен состоять от 8 до 50 символов",
      minLength: "Пароль должен состоять от 8 до 50 символов",
    },
  });
  const repeatPasswordValidation = useValidationFieldForm({
    value: repeatPassword,
    options: {
      required: true,
    },
  });

  const [error, setError] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  useEffect(() => {
    if (AuthState.error !== null) {
      if (AuthState.error.errorCode === "409") {
        setErrorEmail(
          "Пользователь с таким email уже зарегистрирован в системе"
        );
      } else {
        setError("Не удалось выполнить регистрацию, попробуйте позже.");
      }
    } else {
      setErrorEmail("");
      setError("");
    }
  }, [AuthState.error]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(registration(email.trim(), password, nickName.trim()));
  };

  const emailHelperText =
    errorEmail ||
    (emailValidation.isDirty &&
      Object.values(emailValidation.message).find((item) => {
        return item !== "";
      }));

  const nickNameHelperText =
    nickNameValidation.isDirty &&
    Object.values(nickNameValidation.message).find((item) => {
      return item !== "";
    });

  const passwordHelperText =
    passwordValidation.isDirty &&
    Object.values(passwordValidation.message).find((item) => {
      return item !== "";
    })
      ? Object.values(passwordValidation.message).find((item) => {
          return item !== "";
        })
      : "";

  // const passwordHelperText =
  //   passwordValidation.isDirty &&
  //   Object.values(passwordValidation.message).find((item) => {
  //     return item !== "";
  //   })
  //     ? Object.values(passwordValidation.message).find((item) => {
  //         return item !== "";
  //       })
  //     : !(repeatPasswordValidation.isDirty && repeatPassword !== password) &&
  //       !error
  //     ? "не менее 8 символов"
  //     : "";

  const repeatPasswordHephelperText =
    repeatPasswordValidation.isDirty && repeatPassword !== password
      ? "Пароли не совпадают"
      : (repeatPasswordValidation.isDirty &&
          !repeatPasswordValidation.isValid &&
          Object.values(repeatPasswordValidation.message).find((item) => {
            return item !== "";
          })) ||
        error;

  const passwordIsError =
    (repeatPasswordValidation.isDirty &&
      (!repeatPasswordValidation.isValid ||
        repeatPassword !== password ||
        !!error)) ||
    (passwordValidation.isDirty && (!passwordValidation.isValid || !!error));

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
    setErrorEmail("");
  };

  const onChangeNickName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNickName(e.target.value);
    setError("");
  };
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError("");
  };
  const onChangePasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(e.target.value);
    setError("");
  };

  const setStatusIsAgree = (v: boolean) => {
    setIsAgree(v);
    setError("");
  };

  return (
    <form onSubmit={onSubmit} className="registrationForm">
      <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onChangeEmail}
        value={email}
        type="text"
        placeholder="Введите адрес электронной почты"
        onBlur={() => {
          emailValidation.setIsDirty(true);
        }}
        isError={
          (!emailValidation.isValid || !!error || !!errorEmail) &&
          emailValidation.isDirty
        }
        helperText={emailHelperText}
      />
      <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onChangeNickName}
        value={nickName}
        type="text"
        placeholder="Введите имя пользователя"
        onBlur={() => {
          nickNameValidation.setIsDirty(true);
        }}
        isError={
          (!nickNameValidation.isValid || !!error) && nickNameValidation.isDirty
        }
        helperText={nickNameHelperText}
      />
      {/* <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onChangePassword}
        value={password}
        type={isVisiblePassword ? "text" : "password"}
        placeholder="Придумайте пароль"
        afterSlot={
          <EyeBtnForPassword
            val={isVisiblePassword}
            setVal={setIsVisiblePassword}
            className="registrationForm__eyeBtnForPassword"
          />
        }
        onBlur={() => {
          passwordValidation.setIsDirty(true);
        }}
        isError={passwordIsError}
        helperText={passwordHelperText}
      /> */}
      <Input
        sizeTheme="large"
        className="registrationForm__field"
        // onChange={onChangePassword}
        value={password}
        type={isVisiblePassword ? "text" : "password"}
        placeholder="Придумайте пароль"
        afterSlot={
          <EyeBtnForPassword
            val={isVisiblePassword}
            setVal={setIsVisiblePassword}
            className="registrationForm__eyeBtnForPassword"
          />
        }
        // onBlur={() => {
        //   passwordValidation.setIsDirty(true);
        // }}
        isError={passwordIsError}
        onClick={() => {
          setIsShowPasswordModal(true);
          passwordValidation.setIsDirty(true);
        }}
        helperText={passwordHelperText}
      />
      <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onChangePasswordRepeat}
        value={repeatPassword}
        type={isVisiblePasswordRepeat ? "text" : "password"}
        placeholder="Подтвердите пароль"
        afterSlot={
          <EyeBtnForPassword
            val={isVisiblePasswordRepeat}
            setVal={setIsVisiblePasswordRepeat}
            className="registrationForm__eyeBtnForPassword"
          />
        }
        onBlur={() => {
          repeatPasswordValidation.setIsDirty(true);
        }}
        isError={passwordIsError}
        helperText={repeatPasswordHephelperText}
      />
      <Checkbox checked={isAgree} setStatus={setStatusIsAgree}>
        Я ознакомлен и согласен с{" "}
        <NavLink
          to={"/registration"}
          className={"registrationForm__politicLink"}
        >
          Политикой конфиденциальности
        </NavLink>{" "}
      </Checkbox>

      <Button
        type="submit"
        className="registrationForm__btn"
        theme="bright"
        disabled={
          !emailValidation.isValid ||
          !passwordValidation.isValid ||
          !nickNameValidation.isValid ||
          !repeatPasswordValidation.isValid ||
          !isAgree ||
          !!error ||
          !!errorEmail ||
          passwordIsError ||
          AuthState.isLoading
        }
      >
        Зарегистрироваться
      </Button>
      <Button
        type="button"
        className="registrationForm__btn"
        theme="clear"
        onClick={() => {
          navigate(-1);
        }}
      >
        Назад
      </Button>
      <Modal setIsShow={setIsShowPasswordModal} isShow={isShowPasswordModal}>
        <form className={`passwordModal`}>
          <h2 className="passwordModal__head">Введите пароль</h2>
          <div className="passwordModal__content">
            <p className="passwordModal__content">
              Пароль от нашего сервиса должен:
            </p>
            <ul className="passwordModal__contentUl">
              <li>
                {"Состоять только из латинских букв, цифр и специальных символов из списка:" +
                  `!@#$%^&*_-+='|` +
                  "\\" +
                  `(){}[]:""<>,.?/"`}
              </li>
              <li>Иметь длину не менее 8 и не более 50 символов</li>
              <li>Включать в себя хотя бы одну букву в верхнем регистре</li>
              <li>Включать в себя хотя бы одну букву в нижнем регистре</li>
              <li>Включать в себя хотя бы один специальный символ</li>
            </ul>
          </div>
          <Input
            sizeTheme="large"
            className="registrationForm__field"
            onChange={onChangePassword}
            value={password}
            type={isVisiblePassword ? "text" : "password"}
            placeholder="Придумайте пароль"
            afterSlot={
              <EyeBtnForPassword
                val={isVisiblePassword}
                setVal={setIsVisiblePassword}
                className="registrationForm__eyeBtnForPassword"
              />
            }
            onBlur={() => {
              passwordValidation.setIsDirty(true);
              setIsDirtyPasswordModal(true);
            }}
            isError={!!passwordHelperText && isDirtyPasswordModal}
            helperText={passwordHelperText && isDirtyPasswordModal}
          />
          <div className="passwordModal__content">
            <p>
              <b>Внимание!</b> Запомните придуманный пароль. Сервис не
              предусматривает возможность восстановление пароля с целью защиты
              персональных данных
            </p>
          </div>

          <hr className="passwordModal__delimiter" />
          <div className="registrationForm__btn">
            <Button
              theme="bright"
              type="button"
              onClick={() => {
                setIsShowPasswordModal(false);
              }}
            >
              Применить
            </Button>
          </div>
        </form>
      </Modal>
    </form>
  );
};
