import React, { useEffect, useRef, useState } from "react";
import { BackgroundVideo, Modal, NotificationBox } from "Shared/UI";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { PasswordItem } from "Entities/Password";
import { CatalogItem } from "Entities/Catalog";
import { getAllPassword } from "Features/GetAllPasswords";
import { DeleteCatalogForm } from "Features/DeleteCatalog";
import { DeletePasswordForm } from "Features/DeletePassword";
import { PassowrdsAndFilesList } from "Widgets/PasswordsList";
import { CatalogList } from "Widgets/CatalogList";
import { CreateAndChangeCatalogForm } from "Widgets/CreateAndChangeCatalogForm";
import { CatalogPageSlice } from "Entities/CatalogPage";
import { DisplayDataAboutSiteForm } from "Features/DisplayDataAboutSiteForm";
import { FileCatalogItem } from "Entities/FileCatalog";
import { getAllFiles } from "Features/GetAllFiles";
import { FileItem } from "Entities/File";
import { DeleteFileForm } from "Features/DeleteFile";
import { DisplayDataAboutFile } from "Features/DisplayDataAboutFile";
import { CATALOG_TYPE } from "Shared/Types";

export const CatalogPage = () => {
  const AuthState = useAppSelector((state) => state.auth);
  const PasswordState = useAppSelector((state) => state.passwords);
  const FileState = useAppSelector((state) => state.files);
  const CatalogState = useAppSelector((state) => state.catalogs);
  const FileCatalogState = useAppSelector((state) => state.fileCatalogs);
  const CatalogPageState = useAppSelector((state) => state.catalogPage);
  const {
    onChengeCurrentCatalog,
    onChengeCurrentPassword,
    onChengeCurrentFile,
    onChengePasswordOrFile,
    onChengeSelectedCatalog,
    onChengeSelectedPassword,
    onChengeSelectedFile,
  } = CatalogPageSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (CatalogPageState.selectedCatalog !== null) {
      if (CatalogPageState.passwordsOrFiles === "left") {
        dispatch(getAllPassword(CatalogPageState.selectedCatalog));
      } else if (CatalogPageState.passwordsOrFiles === "right") {
        dispatch(getAllFiles(CatalogPageState.selectedCatalog));
      }
    }
  }, [CatalogPageState.selectedCatalog]);

  const [isShowAddCatalogForm, setIsShowAddCatalogForm] =
    useState<boolean>(false);
  const [isShowUpdateCatalogForm, setIsShowUpdateCatalogForm] =
    useState<boolean>(false);
  const [isShowDeleteCatalogForm, setIsShowDeleteCatalogForm] =
    useState<boolean>(false);
  const [isShowDeletePasswordForm, setIsShowDeletePasswordForm] =
    useState<boolean>(false);
  const [isShowDeleteFileForm, setIsShowDeleteFileForm] =
    useState<boolean>(false);

  const onClickNewCatalogBtn = () => {
    setIsShowAddCatalogForm(true);
  };

  const onClickUpdateCatalog = (
    v: CatalogItem | PasswordItem | FileCatalogItem
  ) => {
    dispatch(onChengeCurrentCatalog(v as CatalogItem));
    setIsShowUpdateCatalogForm(true);
  };

  const onClickDeleteCatalog = (
    v: CatalogItem | PasswordItem | FileCatalogItem
  ) => {
    dispatch(onChengeCurrentCatalog(v as CatalogItem));
    setIsShowDeleteCatalogForm(true);
  };

  const onClickDeletePassword = (v: PasswordItem) => {
    dispatch(onChengeCurrentPassword(v as PasswordItem));
    setIsShowDeletePasswordForm(true);
  };
  const onClickDeleteFile = (v: FileItem) => {
    dispatch(onChengeCurrentFile(v as FileItem));
    setIsShowDeleteFileForm(true);
  };

  const onClickCancelFuncInCreateCatalog = () => {
    setIsShowAddCatalogForm(false);
  };

  const onClickCancelFuncInUpdateCatalog = () => {
    setIsShowUpdateCatalogForm(false);
    dispatch(onChengeCurrentCatalog(null));
  };

  const onClickCancelFuncInDeleteCatalog = () => {
    setIsShowDeleteCatalogForm(false);
    dispatch(onChengeCurrentCatalog(null));
  };

  const cancelFuncInDeletePassword = () => {
    setIsShowDeletePasswordForm(false);
    dispatch(onChengeCurrentPassword(null));
  };
  const cancelFuncInDeleteFile = () => {
    setIsShowDeleteFileForm(false);
    dispatch(onChengeSelectedFile(null));
  };

  const setSelectedPassword = (v: number | null) => {
    const currentPassword =
      PasswordState.passwordItems !== undefined &&
      PasswordState.passwordItems.find((item) => {
        return item.id === v;
      });

    dispatch(onChengeCurrentPassword(currentPassword ? currentPassword : null));
    dispatch(onChengeSelectedPassword(v));
  };
  const setSelectedFile = (v: number | null) => {
    const currentFile =
      FileState.fileItems !== undefined &&
      FileState.fileItems.find((item) => {
        return item.id === v;
      });

    dispatch(onChengeCurrentFile(currentFile ? currentFile : null));
    dispatch(onChengeSelectedFile(v));
  };

  const siteDataRef = useRef<HTMLDivElement>(null);
  const fileDataRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (CatalogPageState.currentPassword && siteDataRef.current) {
      siteDataRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (CatalogPageState.currentFile && fileDataRef.current) {
      fileDataRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [CatalogPageState.currentPassword, CatalogPageState.currentFile]);

  return (
    <div className="catalogPage">
      <BackgroundVideo />
      <CatalogList
        catalogType={
          CatalogPageState.passwordsOrFiles === "left"
            ? CATALOG_TYPE.PASSWORDS
            : CATALOG_TYPE.FILES
        }
        className="catalogPage__listCatalog"
        catalogList={
          CatalogPageState.passwordsOrFiles === "left"
            ? CatalogState.catalogItems
            : FileCatalogState.fileCatalogItems
        }
        catalogChoisen={CatalogPageState.selectedCatalog}
        showPassowrdsOrFiles={CatalogPageState.passwordsOrFiles}
        setShowPasswordsOrFiles={(v) => {
          dispatch(onChengePasswordOrFile(v));
        }}
        setChoisenCatalog={(v) => {
          dispatch(onChengeSelectedCatalog(v));
        }}
        onClickNewCatalogBtn={onClickNewCatalogBtn}
        onClickUpdateCatalog={onClickUpdateCatalog}
        onClickDeleteCatalog={onClickDeleteCatalog}
      />
      {CatalogPageState.selectedCatalog !== null &&
        (CatalogPageState.passwordsOrFiles === "left" ? (
          <PassowrdsAndFilesList
            listArr={PasswordState.passwordItems}
            showPasswordsOrFiles={true}
            choisenCatalog={CatalogPageState.selectedCatalog}
            onClickDeleteItem={onClickDeletePassword}
            selectedItem={CatalogPageState.selectedPassword}
            setSelectedItem={setSelectedPassword}
          />
        ) : (
          <PassowrdsAndFilesList
            listArr={FileState.fileItems}
            showPasswordsOrFiles={false}
            choisenCatalog={CatalogPageState.selectedCatalog}
            selectedItem={CatalogPageState.selectedFile}
            onClickDeleteItem={onClickDeleteFile}
            setSelectedItem={setSelectedFile}
          />
        ))}
      {CatalogPageState.currentPassword !== null && AuthState.userItem && (
        <div ref={siteDataRef}>
          <DisplayDataAboutSiteForm
            titleForm={"Данные об аккаунте"}
            labelBtn={"Передать пароль"}
            gapForm={"16"}
            readyBtnFunc={() => {
              navigate("/" + routeEndpoint.scannerQR);
            }}
            login={
              CatalogPageState.currentPassword.login
                ? CatalogPageState.currentPassword.login
                : ""
            }
            siteName={CatalogPageState.currentPassword.name}
            password={
              CatalogPageState.currentPassword.password
                ? CatalogPageState.currentPassword.password
                : ""
            }
            userName={
              AuthState.userItem.name ? AuthState.userItem.name : "Неизвестный"
            }
            siteDate={CatalogPageState.currentPassword.updated_at}
          />
        </div>
      )}
      {CatalogPageState.currentFile !== null && AuthState.userItem && (
        <DisplayDataAboutFile
          currentFile={CatalogPageState.currentFile}
          titleForm={"Данные о файле"}
          labelBtn={"Передать файл"}
          gapForm={"16"}
          createDate={CatalogPageState.currentFile?.created_at || ""}
          readyBtnFunc={() => {
            navigate("/" + routeEndpoint.scannerQR);
          }}
        />
      )}

      <Modal isShow={isShowAddCatalogForm} setIsShow={setIsShowAddCatalogForm}>
        <CreateAndChangeCatalogForm
          formType="create"
          onClickCancelFunc={onClickCancelFuncInCreateCatalog}
          onClickSubmitFunc={onClickCancelFuncInCreateCatalog}
        />
      </Modal>

      <Modal
        isShow={isShowUpdateCatalogForm}
        setIsShow={setIsShowUpdateCatalogForm}
      >
        {CatalogPageState.currentCatalog !== null && (
          <CreateAndChangeCatalogForm
            formType="change"
            catalogItem={CatalogPageState.currentCatalog}
            onClickCancelFunc={onClickCancelFuncInUpdateCatalog}
            onClickSubmitFunc={onClickCancelFuncInUpdateCatalog}
          />
        )}
      </Modal>
      <Modal
        isShow={isShowDeleteCatalogForm}
        setIsShow={setIsShowDeleteCatalogForm}
      >
        {CatalogPageState.currentCatalog !== null && (
          <DeleteCatalogForm
            catalogId={CatalogPageState.currentCatalog.id}
            onClickCancelFunc={onClickCancelFuncInDeleteCatalog}
            onClickSubmitFunc={onClickCancelFuncInDeleteCatalog}
          />
        )}
      </Modal>
      <Modal
        isShow={isShowDeletePasswordForm}
        setIsShow={setIsShowDeletePasswordForm}
      >
        {CatalogPageState.currentPassword !== null && (
          <DeletePasswordForm
            passwordId={CatalogPageState.currentPassword.id}
            cancelFunc={cancelFuncInDeletePassword}
            submitFunc={cancelFuncInDeletePassword}
          />
        )}
      </Modal>
      <Modal
        isShow={isShowDeleteFileForm && CatalogPageState.currentFile !== null}
        setIsShow={setIsShowDeleteFileForm}
      >
        {CatalogPageState.currentFile !== null && (
          <DeleteFileForm
            fileId={CatalogPageState.currentFile.id}
            cancelFunc={cancelFuncInDeleteFile}
            submitFunc={cancelFuncInDeleteFile}
          />
        )}
      </Modal>
      {CatalogState.statusMessage !== null && (
        <NotificationBox
          correct={CatalogState.statusMessage.status === "success"}
          content={CatalogState.statusMessage.message}
          className={"catalogPage__notification"}
        />
      )}
      {PasswordState.statusMessage !== null && (
        <NotificationBox
          correct={PasswordState.statusMessage.status === "success"}
          content={PasswordState.statusMessage.message}
          className={"catalogPage__notification"}
        />
      )}
    </div>
  );
};
