import React from "react";
import "./style.scss";
import { Button } from "Shared/UI";

interface Props {
  className?: string;
  isShowForm: boolean;
  setShowingForm: (v: boolean) => void;
  onClickCancelFunc?: () => void;
}
export const CancleEditingForm = ({
  className,
  isShowForm,
  setShowingForm,
  onClickCancelFunc,
}: Props) => {
  const cancleShowQuestionForm = () => {
    setShowingForm(false);
  };
  return (
    <form
      className={`cancleEditingForm ${
        !isShowForm ? "cancleEditingForm_hidden" : ""
      } ${className && className}`}
    >
      <div className="cancleEditingForm__container">
        <div className="cancleEditingForm__txtHead">
          <h2 className="cancleEditingForm__head">
            Вы действительно хотите
            <br />
            закрыть редактирование?
          </h2>
          <p className="cancleEditingForm__action">Изменения не сохранятся</p>
        </div>
        <hr className="cancleEditingForm__delimiter" />
        <div className="cancleEditingForm__btns">
          <Button theme="bright" type="button" onClick={onClickCancelFunc}>
            Закрыть редактирование
          </Button>
          <Button theme="clear" type="button" onClick={cancleShowQuestionForm}>
            Отмена
          </Button>
        </div>
      </div>
    </form>
  );
};
