import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { CatalogService } from "../../API/services";
import { CatalogItem, CatalogSlice } from "Entities/Catalog";
import { CATALOG_TYPE } from "Shared/Types";

export const updateCatalog =
  (catalogItem: CatalogItem, catalogType: CATALOG_TYPE) =>
  async (dispatch: AppDispatch) => {
    const {
      onChangeCatalog,
      onFetchCatalog,
      onFetchCatalogError,
      onChengeStatusMessageCatalog,
    } = CatalogSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageCatalog({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageCatalog(null));
      }, 3000);
    };

    try {
      dispatch(onFetchCatalog());
      const response = await CatalogService.update(catalogItem, catalogType);
      const catalog = response.data.catalogue;
      if (catalog) {
        dispatch(
          onChangeCatalog(Array.isArray(catalog) ? catalog[0] : catalog)
        );
      } else {
        dispatch(onChangeCatalog(catalogItem));
      }

      onChengeStatusMessage("success", "Данные успешно обновлены");
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchCatalogError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChengeStatusMessage(
          "error",
          "Не удалось применить изменения, попробуйте позже"
        );
      } else {
        dispatch(
          onFetchCatalogError({
            message: "Неизвестная ошибка создания каталога",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage(
          "error",
          "Не удалось применить изменения, попробуйте позже"
        );
      }
    }
  };
