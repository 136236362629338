import axios from "axios";
import Cookies from "js-cookie";

// export const API_URL = "https://data-transfer.abdrashitov-academy.ru" + "/api";
export const API_URL = window.location.origin + "/api";

export const urlEndpoints = {
  AUTH: {
    login: "/login",
    registration: "/register",
    logout: "/logout",
    refresh: "/refresh",
  },
  CATALOG: {
    create: "/catalogue/create",
    update: "/catalogue/rename",
    delete: "/catalogue/delete",
    fetchAll: "/catalogue/get",
  },
  FILE_CATALOG: {
    create: "/file/catalogue/create",
    update: "/file/catalogue/rename",
    delete: "/file/catalogue/delete",
    fetchAll: "/file/catalogue/get",
  },
  PASSWORD: {
    create: "/password/create",
    update: "/password/redact",
    delete: "/password/delete",
    fetchAll: "/password/get",
  },
  FILE: {
    create: "/file/create",
    update: "/file/redact",
    delete: "/file/delete",
    fetchAll: "/file/get",
    getLink: "/file/_get",
  },
  QR: {
    send: "/qr/send",
    sendFile: "/qr/send/file",
    create: "/qr/create",
    recieve: "/qr/recieve",
  },
  PERSONAL: {
    update: "/patch",
    get: "/get",
    delete: "/delete",
  },
};

export const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

const urlSkipAuth = [
  urlEndpoints.AUTH.login,
  urlEndpoints.AUTH.registration,
  urlEndpoints.QR.create,
  urlEndpoints.QR.recieve,
];

$api.interceptors.request.use((config) => {
  if (config.url && urlSkipAuth.includes(config.url)) {
    return config;
  }

  const accessToken = Cookies.get("token");
  if (accessToken) {
    config.headers.Authorization = "Bearer " + accessToken;
  }
  return config;
});
