import React from "react";
import './style.scss';

interface Props {
    className?: string,
    imgSrc: string,
    onClick?: () => void
}

export const PhotoSlider = ({className, imgSrc, onClick}: Props) => {
    
    return(
        <div className={`photoSlider ${className}`} onClick={onClick}>
            <img className="photoSlider__img" src={imgSrc} alt=""/>
        </div>
    )
}