import { useAppDispatch, useAppSelector } from "Shared/Model";
import "./style.scss";
import { Button, DelimiterForm } from "Shared/UI";
import React from "react";
import { deleteCatalog } from "../Model/Store/actionCreator";
import { CATALOG_TYPE } from "Shared/Types";

interface CreateCatalogButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  catalogId: number;
  onClickCancelFunc?: () => void;
  onClickSubmitFunc?: () => void;
}

export const DeleteCatalogForm = ({
  className,
  catalogId,
  onClickCancelFunc,
  onClickSubmitFunc,
  ...props
}: CreateCatalogButtonProps) => {
  const dispatch = useAppDispatch();

  const { passwordsOrFiles } = useAppSelector((state) => state.catalogPage);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(
      deleteCatalog(
        catalogId,
        passwordsOrFiles === "left"
          ? CATALOG_TYPE.PASSWORDS
          : CATALOG_TYPE.FILES
      )
    );
    if (onClickSubmitFunc) onClickSubmitFunc();
  };

  const onClickNoDelFunc = () => {
    if (onClickCancelFunc) onClickCancelFunc();
  };
  return (
    <form className={"deleteCatalogForm" + (className ? " " + className : "")}>
      <h2 className="deleteCatalogForm__head">
        Вы действительно хотите удалить рубрику?
      </h2>
      <p className="deleteCatalogForm__description">
        В случае удаления, все сайты и службы в этом каталоге будут удалены
      </p>
      <DelimiterForm />
      <div className="deleteCatalogForm__btnWrap">
        <Button className="deleteCatalogForm__btn" onClick={onClickNoDelFunc}>
          Не удалять
        </Button>
        <Button
          {...props}
          theme="bright"
          type="button"
          onClick={onSubmit}
          className={"deleteCatalogForm__btn"}
        >
          Удалить рубрику
        </Button>
      </div>
    </form>
  );
};
