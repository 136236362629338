import axios from "axios";
import Cookies from "js-cookie";
import AuthService from "../../API/services";
import { AppDispatch } from "Shared/Model";
import { AuthSlice } from "Entities/User";
import { CatalogPageSlice } from "Entities/CatalogPage";
import { CatalogSlice } from "Entities/Catalog";
import { PasswordSlice } from "Entities/Password";
import { FileSlice } from "Entities/File";
import { FileCatalogSlice } from "Entities/FileCatalog";

export const logout = () => async (dispatch: AppDispatch) => {
  const { onFetchAuth, onFetchAuthSucces, onFetchAuthError } =
    AuthSlice.actions;
  const { onResetStateToInitial } = CatalogPageSlice.actions;
  const { onResetCatalogState } = CatalogSlice.actions;
  const { onResetPasswordState } = PasswordSlice.actions;
  const { onResetFileState } = FileSlice.actions;
  const { onResetFileCatalogState } = FileCatalogSlice.actions;
  try {
    dispatch(onFetchAuth());
    await AuthService.logout();
    Cookies.remove("token");
    dispatch(onFetchAuthSucces(null));
    dispatch(onResetStateToInitial());
    dispatch(onResetCatalogState());
    dispatch(onResetPasswordState());
    dispatch(onResetFileState());
    dispatch(onResetFileCatalogState());
    return true;
  } catch (e) {
    if (axios.isAxiosError(e) && typeof e.response?.data.message === "string") {
      dispatch(
        onFetchAuthError({
          message: "",
          errorCode: e.response?.data.message,
        })
      );
    } else {
      dispatch(
        onFetchAuthError({
          message: "Неизвестная ошибка логанизации",
          errorCode: "unknownError",
        })
      );
    }
    return false;
  }
};
