export const NameValidation = {
  options: {
    required: true,
    maxLength: 256,
    isOnlyRussAndEngLetters: true,
  },
  messageText: {
    required: "Имя пользователя не должно быть пустым",
    maxLength: `Поле должно содержать не более 256 символов`,
    isOnlyRussAndEngLetters:
      "Допустимые символы: A-Z, a-z, А-Я, а-я, тире и пробел",
  },
};
