import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { CatalogService } from "../../API/services";
import { CatalogSlice } from "Entities/Catalog";
import { CATALOG_TYPE } from "Shared/Types";
import { FileCatalogSlice } from "Entities/FileCatalog";

export const fetchAllCatalog =
  (catalogs: CATALOG_TYPE) => async (dispatch: AppDispatch) => {
    const {
      onFetchAllCatalog,
      onFetchCatalog,
      onFetchCatalogError,
      onChengeStatusMessageCatalog,
    } = CatalogSlice.actions;

    const {
      onFetchAllFileCatalog,
      onFetchFileCatalog,
      onFetchFileCatalogError,
      onChangeStatusMessageFileCatalog,
    } = FileCatalogSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      const onChangeStatusMessageFunc =
        catalogs === CATALOG_TYPE.FILES
          ? onChangeStatusMessageFileCatalog
          : onChengeStatusMessageCatalog;
      dispatch(
        onChangeStatusMessageFunc({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChangeStatusMessageFunc(null));
      }, 3000);
    };

    try {
      if (catalogs === CATALOG_TYPE.FILES) {
        dispatch(onFetchFileCatalog());
        const response = await CatalogService.fetchAll(catalogs);
        if (response.data && "Fcatalogue" in response.data) {
          dispatch(onFetchAllFileCatalog(response.data?.Fcatalogue || []));
        }
      }
      if (catalogs === CATALOG_TYPE.PASSWORDS) {
        dispatch(onFetchCatalog());
        const response = await CatalogService.fetchAll(catalogs);
        if (response.data && "catalogue" in response.data) {
          dispatch(onFetchAllCatalog(response.data.catalogue));
        }
      }
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        if (catalogs === CATALOG_TYPE.FILES) {
          dispatch(
            onFetchFileCatalogError({
              message: e.response?.data.message,
              errorCode: e.response?.status.toString(),
            })
          );
        }
        if (catalogs === CATALOG_TYPE.PASSWORDS) {
          dispatch(
            onFetchCatalogError({
              message: e.response?.data.message,
              errorCode: e.response?.status.toString(),
            })
          );
        }
        onChengeStatusMessage(
          "error",
          "Не удалось подгрузить ваши рубрики, попробуйте обновить страницу"
        );
      } else {
        if (catalogs === CATALOG_TYPE.FILES) {
          dispatch(
            onFetchFileCatalogError({
              message: "Неизвестная ошибка загрузки каталогов",
              errorCode: "unknownError",
            })
          );
        }
        if (catalogs === CATALOG_TYPE.PASSWORDS) {
          dispatch(
            onFetchCatalogError({
              message: "Неизвестная ошибка загрузки каталогов",
              errorCode: "unknownError",
            })
          );
        }
        onChengeStatusMessage(
          "error",
          "Не удалось подгрузить ваши рубрики, попробуйте обновить страницу"
        );
      }
    }
  };
