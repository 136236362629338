import React from "react";
import { Button } from "../../Shared/UI/Button/ui";
import "./style.scss";
import { routeEndpoint } from "Shared/Routes";

interface Props {
  classNames?: string;
}

export const SelectingTrustForm = ({ classNames }: Props) => {
  return (
    <section
      className={`selectingTrustForm ${classNames ? " " + classNames : ""}`}
    >
      <div className="selectingTrustForm__messege">
        <h3 className="selectingTrustForm__title">
          Вы доверяете этому устройству?
        </h3>
        <p className="selectingTrustForm__description">
          Это нужно для того, чтобы продолжить работу<br/>
          с устройством и передавать данные в профиле.
        </p>
      </div>
      <Button
        tag="NavLink"
        to={"/" + routeEndpoint.selectAuth}
        theme="clear"
        className={`choise`}
      >
        Доверяю устройству
      </Button>
      <Button
        tag="NavLink"
        to={"/" + routeEndpoint.notTrust}
        theme="bright"
        className={`choise`}
      >
        Не доверяю
      </Button>
    </section>
  );
};
