import { useAppDispatch } from "Shared/Model";
import { Button } from "Shared/UI";
import React from "react";
import { updatePassword } from "../Model/Store/actionCreator";
import { PasswordItem } from "Entities/Password";

interface UpdatePasswordButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  passwordItem: PasswordItem;
}

export const UpdatePasswordButton = ({
  className,
  passwordItem,
  onClick,
  ...props
}: UpdatePasswordButtonProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await dispatch(updatePassword(passwordItem));
    if (onClick && result) onClick(e);
  };
  return (
    <Button
      {...props}
      theme="bright"
      type="submit"
      onClick={onSubmit}
      className={"updatePasswordButton" + (className ? " " + className : "")}
    >
      Сохранить
    </Button>
  );
};
