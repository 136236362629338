import { useAppDispatch } from "Shared/Model";
import { Button } from "Shared/UI";
import React from "react";
import { createFile } from "../Model/Store/actionCreator";
import { FileCreateRequest } from "../Model/Type/CreateFileType";

interface CreatePasswordButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  setLoading?: (loading: boolean) => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  fileItem: FileCreateRequest;
}

export const CreateFileButton = ({
  setLoading,
  loading,
  disabled = false,
  className,
  fileItem,
  onClick,
  ...props
}: CreatePasswordButtonProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading?.(true);
    const result = await dispatch(createFile(fileItem));
    if (result) {
      if (onClick) onClick(e);
    }
    setLoading?.(false);
  };
  return (
    <Button
      {...props}
      theme="bright"
      type="button"
      disabled={loading || disabled}
      onClick={onSubmit}
      className={className}
    >
      Добавить
    </Button>
  );
};
