import React from "react";

export const AddIcon = () => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#6200FF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16 11.625C16.302 11.625 16.5469 11.8698 16.5469 12.1719V15.4531H19.8281C20.1302 15.4531 20.375 15.698 20.375 16C20.375 16.302 20.1302 16.5469 19.8281 16.5469H16.5469V19.8281C16.5469 20.1302 16.302 20.375 16 20.375C15.698 20.375 15.4531 20.1302 15.4531 19.8281V16.5469H12.1719C11.8698 16.5469 11.625 16.302 11.625 16C11.625 15.698 11.8698 15.4531 12.1719 15.4531H15.4531V12.1719C15.4531 11.8698 15.698 11.625 16 11.625Z" fill="white"/>
        </svg>

    )
}