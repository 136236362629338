import { useEffect } from "react";
import { useAppSelector } from "Shared/Model";
import { routeEndpoint } from "Shared/Routes";
import { useNavigate } from "react-router-dom";

type IsAuthProps = {
  children: JSX.Element;
  message?: string;
  path?: routeEndpoint;
  delay?: number;
};

export const IsAuth = ({
  children,
  message = "Авторизуйтесь, чтобы получить доступ к данной странице",
  path = routeEndpoint.login,
  delay = 0,
}: IsAuthProps) => {
  const navigate = useNavigate();

  const AuthState = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (!AuthState.isAuth) {
      setTimeout(() => {
        navigate("/" + path, {
          state: {
            authError: message,
          },
        });
      }, delay);
    }
  }, [AuthState.isAuth]);
  return children;
};
export const IsNotAuth = ({
  children,
  message = "Разлогинтесь, чтобы воспользоваться данной страницей",
  path = routeEndpoint.catalog,
  delay = 0,
}: IsAuthProps) => {
  const navigate = useNavigate();

  const AuthState = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (AuthState.isAuth) {
      setTimeout(() => {
        navigate("/" + path, {
          state: {
            authError: message,
          },
        });
      }, delay);
    }
  }, [AuthState.isAuth]);
  return children;
};
