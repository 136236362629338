import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CatalogItem } from "Entities/Catalog/Model/Type/catalogType";
import { FileItem } from "Entities/File";
import { FileCatalogItem } from "Entities/FileCatalog";
import { PasswordItem } from "Entities/Password";

interface InitialStateProps {
  selectedCatalog: number | null;
  currentCatalog: CatalogItem | FileCatalogItem | null;
  selectedPassword: number | null;
  currentPassword: PasswordItem | null;
  selectedFile: number | null;
  currentFile: FileItem | null;
  passwordsOrFiles: "left" | "right";
}
const initialState: InitialStateProps = {
  selectedCatalog: null,
  selectedPassword: null,
  selectedFile: null,
  currentCatalog: null,
  currentPassword: null,
  currentFile: null,
  passwordsOrFiles: "left",
};
export const CatalogPageSlice = createSlice({
  name: "catalogPage",
  initialState,
  reducers: {
    onChengeSelectedCatalog(state, action: PayloadAction<number | null>) {
      state.selectedCatalog = action.payload;
      state.currentPassword = null;
      state.currentFile = null;
      state.selectedPassword = null;
      state.selectedFile = null;
    },
    onChengeSelectedPassword(state, action: PayloadAction<number | null>) {
      state.selectedPassword = action.payload;
    },
    onChengeSelectedFile(state, action: PayloadAction<number | null>) {
      state.selectedFile = action.payload;
    },
    onChengeCurrentCatalog(state, action: PayloadAction<CatalogItem | null>) {
      state.currentCatalog = action.payload;
    },
    onChengeCurrentPassword(state, action: PayloadAction<PasswordItem | null>) {
      state.currentPassword = action.payload;
    },
    onChengeCurrentFile(state, action: PayloadAction<FileItem | null>) {
      state.currentFile = action.payload;
    },
    onChengePasswordOrFile(state, action: PayloadAction<"left" | "right">) {
      state.passwordsOrFiles = action.payload;
    },
    onResetStateToInitial(state) {
      state.currentCatalog = initialState.currentCatalog;
      state.currentPassword = initialState.currentPassword;
      state.selectedCatalog = initialState.selectedCatalog;
      state.selectedPassword = initialState.selectedPassword;
      state.passwordsOrFiles = initialState.passwordsOrFiles;
    },
  },
});

export default CatalogPageSlice.reducer;
