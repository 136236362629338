import React from "react";
import "./style.scss";

interface Props {
  withUsingExplain: boolean;
  className?: string;
}

export const GeneralInfo = ({ withUsingExplain, className }: Props) => {
  if (withUsingExplain) {
    return (
      <div className={`generalInfo ${className ? className : ""}`}>
        <div className="generalInfo__head">
          <h2 className="generalInfo__title">Безопасная авторизация</h2>
          <p className="generalInfo__description">
            Копируйте, передавайте и используйте данные.
          </p>
        </div>
        <div className="generalInfo__usingSite">
          <div className="generalInfo__question">
            Как пользоваться сервисом?
          </div>
          <ul className="generalInfo__answer">
            <li>
              Если вы доверяете этому устройству, в форме ниже нажмите кнопку
              “Доверяю устройству” и перейдите к авторизации или регистрации.
            </li>
            <li>
              Если вы не доверяете данному устройству, отсканируйте QR-код{" "}
              <br />с другого устройства и передайте пароль или данные
              единоразово.
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`generalInfo ${className ? className : ""}`}>
        <div className="generalInfo__head">
          <h2 className="generalInfo__title">Безопасная авторизация</h2>
          <p className="generalInfo__description">
            Копируйте, передавайте и используйте данные.
          </p>
        </div>
      </div>
    );
  }
};
