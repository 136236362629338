import React from "react";
import "./style.scss";
import { CreateAndChangePasswordForm } from "Widgets/CreateAndChengePasswordForm";
import { useNavigate } from "react-router-dom";
import { PasswordItem } from "Entities/Password";
import { routeEndpoint } from "Shared/Routes";
import { useAppSelector } from "Shared/Model";
import { NotificationBox } from "Shared/UI";

export const AddPasswordPage = () => {
  const navigate = useNavigate();
  const CatalogPageState = useAppSelector((state) => state.catalogPage);
  const PasswordsState = useAppSelector((state) => state.passwords);

  const PasswordItem: PasswordItem | undefined =
    CatalogPageState.selectedCatalog !== null
      ? {
          id: 0,
          name: "",
          catalogue_id: CatalogPageState.selectedCatalog,
        }
      : undefined;
  return (
    <main className="addPasswordPage">
      {PasswordsState.statusMessage && (
        <NotificationBox
          position="absolute"
          correct={PasswordsState.statusMessage.status === "success"}
          content={PasswordsState.statusMessage.message}
        />
      )}
      <CreateAndChangePasswordForm
        formType="create"
        passwordItem={PasswordItem}
        onClickCancelFunc={() => {
          navigate("/" + routeEndpoint.catalog);
        }}
        onClickSubmitFunc={() => {
          navigate("/" + routeEndpoint.catalog);
        }}
      />
    </main>
  );
};
