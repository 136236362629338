import React, { useEffect, useRef } from "react";
import "./style.scss";
import QRCodeStyling from "qr-code-styling";
import { Button, DelimiterForm, QRFocusIcon } from "Shared/UI";
import { QRSesseonItem } from "Entities/QR";

type QRShowProps = {
  className?: string;
  width?: number;
  height?: number;
  value: QRSesseonItem | null;
  isLoading?: boolean;
  onClickUpdateQRBtn?: () => void;
};
export const QRShow = ({
  className,
  width = 200,
  height = 200,
  onClickUpdateQRBtn,
  value,
  isLoading,
}: QRShowProps) => {
  const QRWrapRef = useRef<HTMLDivElement>(null);

  const qrCodeExeplar = new QRCodeStyling({
    qrOptions: {
      errorCorrectionLevel: "L",
      typeNumber: 0,
    },
    cornersDotOptions: {
      type: "dot",
    },
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    width: width,
    height: height,
    type: "svg",
    margin: 0,
    data: JSON.stringify(value),
    dotsOptions: {
      color: "rgba(28, 28, 28, 1)",
      type: "classy-rounded",
    },
    backgroundOptions: {
      color: "white",
    },
  });

  useEffect(() => {
    if (QRWrapRef.current !== null) {
      qrCodeExeplar.append(QRWrapRef.current);
    }
  }, [QRWrapRef]);

  const onClick = () => {
    if (onClickUpdateQRBtn) onClickUpdateQRBtn();
  };

  return (
    <div className={"QRShow" + (className ? " " + className : "")}>
      <div className="QRShow__headWrap">
        <QRFocusIcon />
        <h2 className="QRShow__head">Сканируй, чтобы создать связь</h2>
      </div>
      <DelimiterForm />
      {isLoading ? (
        <div className="QRShow__QRWrap">
          Идет загрузка данных для генерации QR-кода
        </div>
      ) : (
        <div ref={QRWrapRef} className="QRShow__QRWrap"></div>
      )}
      <Button
        onClick={onClick}
        className="QRShow__btn"
        type="button"
        theme="bright"
      >
        Обновить QR
      </Button>
    </div>
  );
};
