import React from "react";
import "./style.scss";
import { EyeIcon } from "../Icons/EyeIcon/ui";

interface EyeBtnForPasswordProps {
  val: boolean;
  setVal: (v: boolean) => void;
  className?: string;
}
export const EyeBtnForPassword = ({
  val,
  setVal,
  className,
}: EyeBtnForPasswordProps) => {
  return (
    <button
      type="button"
      className={"eyeBtnForPassword" + (className ? " " + className : "")}
      onClick={() => {
        setVal(!val);
      }}
    >
      {val ? <EyeIcon isVisible /> : <EyeIcon />}
    </button>
  );
};
