import React, { useState } from "react";
import "./style.scss";
import { Button, PhotoSlider } from "Shared/UI";

interface ImgArrFormProps {
  className?: string;
  isShowForm: boolean;
  setIsShowForm: (v: boolean) => void;
  imgsArr?: { id: string; img: string }[];
  onClickSaveBtn?: (v: string | null) => void;
  initialValue?: string | null;
}

export const ChoiseUserImgForm = ({
  className,
  setIsShowForm,
  imgsArr,
  onClickSaveBtn,
  initialValue,
}: ImgArrFormProps) => {
  const [selectedImgId, setSelectedImgId] = useState<string | null>(
    initialValue ? initialValue : null
  );

  const handleChoisenImg = (id: string) => {
    setSelectedImgId(id);
  };

  const onClickSaveBtnHendler = () => {
    if (onClickSaveBtn) onClickSaveBtn(selectedImgId);
  };
  return (
    <form className={`choiseUserImgForm ${className}`}>
      <h2 className="choiseUserImgForm__head">Доступные аватары</h2>

      <ul className="choiseUserImgForm__allChoises">
        {imgsArr &&
          imgsArr.map((e) => {
            return (
              <li key={e.id} className="choiseUserImgForm__choise">
                <PhotoSlider
                  imgSrc={e.img}
                  onClick={() => handleChoisenImg(e.id)}
                  className={selectedImgId === e.id ? "photoSlider-active" : ""}
                />
              </li>
            );
          })}
      </ul>
      <div className="choiseUserImgForm__btns">
        <Button
          type="button"
          className="choiseUserImgForm__btnSave"
          onClick={onClickSaveBtnHendler}
          theme="bright"
        >
          Выбрать это фото
        </Button>
        <Button type="button" theme="dark" onClick={() => setIsShowForm(true)}>
          Отмена
        </Button>
      </div>
    </form>
  );
};
