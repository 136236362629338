import axios from "axios";
import AuthService from "../../API/services";
import Cookies from "js-cookie";
import { AppDispatch } from "Shared/Model";
import { AuthSlice } from "Entities/User";

export const checkAuthAction = () => async (dispatch: AppDispatch) => {
  const { onFetchAuth, onFetchAuthSucces, onFetchAuthError } =
    AuthSlice.actions;
  try {
    dispatch(onFetchAuth());
    const response = await AuthService.check();
    Cookies.set("token", response.data.authorisation.token);
    dispatch(onFetchAuthSucces(response.data.user));
    return !!response.data.user;
  } catch (e) {
    if (axios.isAxiosError(e) && typeof e.response?.data.message === "string") {
      dispatch(
        onFetchAuthError({
          message: "",
          errorCode: e.response?.data.message,
        })
      );
    } else {
      dispatch(
        onFetchAuthError({
          message: "Неизвестная ошибка refresh",
          errorCode: "unknownErrorRefresh",
        })
      );
    }
    return false;
  }
};
