import React from "react";
import "./style.scss";
import { BackgroundVideo } from "../../../Shared/UI/BackgroundVideo/ui";
import { GeneralInfo } from "../../../Shared/UI/GeneralInfo/ui";
import { SelectingTrustForm } from "../../../Features/SelectingTrustForDeviceForm/ui";

export const MainPage = () => {
  return (
    <main className={"mainPage"}>
      <BackgroundVideo />
      <GeneralInfo withUsingExplain={true} className="mainPage__generalInfo" />
      <SelectingTrustForm classNames="mainPage__selectingTrust" />
    </main>
  );
};
