import { AxiosResponse } from "axios";
import { FileRedactRequest, FileResponse } from "Entities/File";
import { $api, urlEndpoints } from "Shared/API";

export class FileService {
  static async update(
    fileItem: FileRedactRequest
  ): Promise<AxiosResponse<FileResponse>> {
    if (
      !fileItem.id ||
      !fileItem.catalogue_id ||
      !fileItem.name ||
      !fileItem.extension
    ) {
      return Promise.reject(
        `${fileItem.id} ${fileItem.catalogue_id} ${fileItem.name} ${fileItem.extension}`
      );
    }

    const formData = new URLSearchParams();
    formData.append("id", fileItem.id.toString());
    formData.append("catalogue_id", fileItem.catalogue_id.toString());
    formData.append("name", fileItem.name);
    formData.append("extension", fileItem.extension);

    return $api.put<FileResponse>(
      urlEndpoints.FILE.update,
      formData.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
}
