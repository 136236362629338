import { AxiosResponse } from "axios";
import { AuthResponse } from "Entities/User";
import { $api, urlEndpoints } from "Shared/API";

export class AuthService {
  static async login(
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>(urlEndpoints.AUTH.login, {
      email,
      password,
    });
  }
}
