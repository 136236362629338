import React from "react";
import "./style.scss";
import { CreateAndChangeFileForm } from "Widgets/CreateAndChangeFileForm";
import { useNavigate } from "react-router-dom";
import { FileItem } from "Entities/File";
import { useAppSelector } from "Shared/Model";
import { routeEndpoint } from "Shared/Routes";

export const UpdateFilePage = () => {
  const navigate = useNavigate();

  const CatalogPageState = useAppSelector((state) => state.catalogPage);

  const fileItem: FileItem | undefined =
    CatalogPageState.currentFile !== null
      ? CatalogPageState.currentFile
      : undefined;

  return (
    <main className="updateFilePage">
      {fileItem && (
        <CreateAndChangeFileForm
          formType="change"
          fileItem={fileItem}
          onClickCancelFunc={() => {
            navigate("/" + routeEndpoint.catalog);
          }}
          onClickSubmitFunc={() => {
            navigate("/" + routeEndpoint.catalog);
          }}
        />
      )}
    </main>
  );
};
