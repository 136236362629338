const emailRegex = /^[A-Za-z0-9!@#$%^&*_\-+='\\|(){}[\]:"<>,.?/]+$/;

export const EmailValidation = {
    options: {
      required: true,
      maxLength: 256,
      isMatchPattern: emailRegex,
      isEmail: true,
    },
    messageText: {
      required: "Почта пользователя не должно быть пустым",
      maxLength: `Поле не соответствует требованиям: до 256 символов, A-Z, a-z, 0-9 спецсимволы !@#$%^&* _-+='|\\() {} []:"" <>,.? /`,
      isMatchPattern:  `Поле не соответствует требованиям: до 256 символов, A-Z, a-z, 0-9 спецсимволы !@#$%^&* _-+='|\\() {} []:"" <>,.? /`,
      isEmail:  `Неверный формат email`,
    },
};
