import { AxiosResponse } from "axios";
import { PasswordResponse } from "Entities/Password";
import { $api, urlEndpoints } from "Shared/API";

export class PasswordService {
  static async delete(id: number): Promise<AxiosResponse<PasswordResponse>> {
    return $api.delete<PasswordResponse>(urlEndpoints.PASSWORD.delete, {
      data: {
        id,
      },
    });
  }
}
