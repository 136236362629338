import { useAppDispatch } from "Shared/Model";
import "./style.scss";
import { Button, DelimiterForm } from "Shared/UI";
import React from "react";
import { deleteFile } from "../Model/Store/actionCreator";

interface DeleteFileButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  fileId: number;
  cancelFunc?: () => void;
  submitFunc?: () => void;
}

export const DeleteFileForm = ({
  className,
  fileId,
  cancelFunc,
  submitFunc,
  ...props
}: DeleteFileButtonProps) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading?.(true);
    e.preventDefault();
    const result = await dispatch(deleteFile(fileId));
    if (submitFunc && result) submitFunc();
    setLoading?.(false);
  };

  const onClickNoDelFunc = () => {
    if (cancelFunc) cancelFunc();
  };
  return (
    <form className={"deleteFileForm" + (className ? " " + className : "")}>
      <h2 className="deleteFileForm__head">
        Вы действительно хотите удалить данные?
      </h2>
      <DelimiterForm />
      <div className="deleteFileForm__btnWrap">
        <Button className="deleteFileForm__btn" onClick={onClickNoDelFunc}>
          Нет
        </Button>
        <Button
          disabled={loading}
          {...props}
          theme="bright"
          type="button"
          onClick={onSubmit}
          className={"deleteFileForm__btn"}
        >
          Да
        </Button>
      </div>
    </form>
  );
};
