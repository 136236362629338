import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { FileService } from "../../API/services";
import { FileRedactRequest, FileSlice } from "Entities/File";
import { CatalogPageSlice } from "Entities/CatalogPage";

export const updateFile =
  (fileItem: FileRedactRequest) => async (dispatch: AppDispatch) => {
    const {
      onChangeFile,
      onFetchFile,
      onFetchFileError,
      onChangeStatusMessageFile,
    } = FileSlice.actions;
    const { onChengeCurrentFile } = CatalogPageSlice.actions;
    const onChangeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChangeStatusMessageFile({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChangeStatusMessageFile(null));
      }, 3000);
    };
    try {
      dispatch(onFetchFile());
      const response = await FileService.update(fileItem);
      console.log("updateFile", response);

      dispatch(onChangeFile(response.data.file));
      dispatch(onChengeCurrentFile(response.data.file));
      onChangeStatusMessage("success", "Данные успешно обновлены");

      return true;
    } catch (e) {
      console.log("updateFile error", e);

      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchFileError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChangeStatusMessage("error", "Не удалось обновить данные");
      } else {
        dispatch(
          onFetchFileError({
            message: "Неизвестная ошибка обновления записи о пароле",
            errorCode: "unknownError",
          })
        );
        onChangeStatusMessage("error", "Не удалось обновить данные");
      }
    }
    return false;
  };
