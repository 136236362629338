import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthError } from "../Type/authType";
import { UserType } from "../Type/UserType";

type StatusType = {
  status: "success" | "error";
  message: string;
};

interface InitialStateProps {
  userItem: UserType | null;
  isAuth: boolean;
  isLoading: boolean;
  error: AuthError | null;
  statusMessage: StatusType | null;
}

const initialState: InitialStateProps = {
  userItem: null,
  isAuth: false,
  isLoading: false,
  error: null,
  statusMessage: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onFetchAuth(state) {
      state.isLoading = true;
    },
    onFetchAuthSucces(state, action: PayloadAction<UserType | null>) {
      state.isLoading = false;
      state.error = null;
      state.userItem = action.payload;
      state.isAuth = action.payload !== null;
    },
    onFetchAuthError(state, action: PayloadAction<AuthError | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    onChengeUserItem(state, action: PayloadAction<Partial<UserType> | null>) {
      if (action.payload === null) {
        state.userItem = null;
      } else {
        if (state.userItem !== null) {
          state.userItem = { ...state.userItem, ...action.payload };
        } else {
          state.userItem = {
            id: Infinity,
            name: "",
            email: "",
            updated_at: "",
            created_at: "",
            ...action.payload,
          };
        }
      }
    },
    onChengeStatusMessageAuth(state, action: PayloadAction<StatusType | null>) {
      state.statusMessage = action.payload;
    },
  },
});

export default AuthSlice.reducer;
