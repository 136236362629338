import React, { useState } from "react";
import "./style.scss";
import { ChangePersonalPasswordForm } from "Widgets/ChangePersonalPasswordForm";
import { useNavigate } from "react-router-dom";
import { Modal } from "Shared/UI";
import { CancleEditingForm } from "Widgets/CancleEditingForm";

export const ChangePersonalPasswordPage = () => {
  const [isShowMessegeDontSave, setIsShowMessegeDontSave] =
    useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <main className="changePersonalPasswordPage">
      <ChangePersonalPasswordForm
        isShow={isShowMessegeDontSave}
        setIsShow={setIsShowMessegeDontSave}
      />

      <Modal
        setIsShow={setIsShowMessegeDontSave}
        isShow={isShowMessegeDontSave}
      >
        <CancleEditingForm
          isShowForm={isShowMessegeDontSave}
          setShowingForm={setIsShowMessegeDontSave}
          onClickCancelFunc={() => {
            navigate(-1);
          }}
        />
      </Modal>
    </main>
  );
};
