import React from "react";
import "./style.scss";
import { CreateAndChangeFileForm } from "Widgets/CreateAndChangeFileForm";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";
import { useAppSelector } from "Shared/Model";
import { FileItem } from "Entities/File";

export const AddFilePage = () => {
  const navigate = useNavigate();
  const CatalogPageState = useAppSelector((state) => state.catalogPage);

  const FileItem: FileItem | undefined =
    CatalogPageState.currentCatalog !== null
      ? {
          id: 0,
          name: "",
          catalogue_id: CatalogPageState.currentCatalog.id as number,
        }
      : undefined;
  return (
    <main className="addPasswordPage">
      <CreateAndChangeFileForm
        formType="create"
        fileItem={FileItem}
        onClickCancelFunc={() => {
          navigate("/" + routeEndpoint.catalog);
        }}
        onClickSubmitFunc={() => {
          navigate("/" + routeEndpoint.catalog);
        }}
      />
    </main>
  );
};
