import { AxiosResponse } from "axios";
import { PasswordItem, PasswordResponse } from "Entities/Password";
import { $api, urlEndpoints } from "Shared/API";

export class PasswordService {
  static async create(
    passwordItem: Omit<PasswordItem, "id">
  ): Promise<AxiosResponse<PasswordResponse>> {
    return $api.post<PasswordResponse>(
      urlEndpoints.PASSWORD.create,
      passwordItem
    );
  }
}
