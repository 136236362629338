import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CatalogError, CatalogItem } from "../Type/catalogType";

type StatusType = {
  status: "success" | "error";
  message: string;
};

interface InitialStateProps {
  catalogItems: CatalogItem[];
  isLoading: boolean;
  error: CatalogError | null;
  statusMessage: StatusType | null;
}
const initialState: InitialStateProps = {
  catalogItems: [],
  isLoading: false,
  error: null,
  statusMessage: null,
};
export const CatalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    onFetchCatalog(state) {
      state.isLoading = true;
    },
    onFetchCatalogError(state, action: PayloadAction<CatalogError | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    onChengeStatusMessageCatalog(
      state,
      action: PayloadAction<StatusType | null>
    ) {
      state.statusMessage = action.payload;
    },

    onFetchAllCatalog(state, action: PayloadAction<CatalogItem[]>) {
      state.isLoading = false;
      state.error = null;
      state.catalogItems = action.payload;
    },

    onCreateCatalog(state, action: PayloadAction<CatalogItem>) {
      state.catalogItems.push(action.payload);
    },

    onChangeCatalog(state, action: PayloadAction<CatalogItem>) {
      const indexItem = state.catalogItems.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (indexItem >= 0) {
        state.catalogItems[indexItem] = action.payload;
      } else {
        throw new Error("Каталога с таким id не существует");
      }
    },

    onDeleteCatalog(state, action: PayloadAction<number>) {
      const indexItem = state.catalogItems.findIndex((item) => {
        return item.id === action.payload;
      });
      if (indexItem >= 0) {
        state.catalogItems.splice(indexItem, 1);
      } else {
        throw new Error("Каталога с таким id не существует");
      }
    },
    onResetCatalogState(state) {
      state.catalogItems = initialState.catalogItems;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.statusMessage = initialState.statusMessage;
    },
  },
});

export default CatalogSlice.reducer;
