import { FileResponseAll } from "Entities/File";
import { $api, urlEndpoints } from "Shared/API";
import { AxiosResponse } from "axios";

export class FileService {
  static async fetchAll(
    catalogId: number
  ): Promise<AxiosResponse<FileResponseAll>> {
    return $api.get<FileResponseAll>(urlEndpoints.FILE.fetchAll, {
      params: {
        catalogue_id: catalogId,
      },
    });
  }
}
