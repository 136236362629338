import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { FileService } from "../../API/services";
import { FileItem, FileSlice } from "Entities/File";
import { FileCreateRequest } from "../Type/CreateFileType";

export const createFile =
  (fileItem: FileCreateRequest) => async (dispatch: AppDispatch) => {
    const {
      onCreateFile,
      onFetchFile,
      onFetchFileError,
      onChangeStatusMessageFile,
    } = FileSlice.actions;
    const onChangeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChangeStatusMessageFile({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChangeStatusMessageFile(null));
      }, 3000);
    };
    try {
      dispatch(onFetchFile());
      const response = await FileService.create(fileItem);
      console.log("createFile: ", response);

      dispatch(onCreateFile(response.data.file as FileItem));

      onChangeStatusMessage("success", "Данные успешно добавлены");

      return true;
    } catch (e) {
      console.log("createFile error", e);

      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchFileError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChangeStatusMessage(
          "error",
          "Не смогли добавить ваши данные, попробуйте позже"
        );
      } else {
        dispatch(
          onFetchFileError({
            message: "Неизвестная ошибка создания каталога",
            errorCode: "unknownError",
          })
        );
        onChangeStatusMessage(
          "error",
          "Не смогли добавить ваши данные, попробуйте позже"
        );
      }
      return false;
    }
  };
