import React from "react";
import { FileIcon } from "../../Icons/FileIcon";
import "./style.scss";

interface FileComponentProps {
  fileName: string;
  fileSize?: string;
  download?: boolean;
}

export const FileComponent = ({ fileName, fileSize }: FileComponentProps) => {
  return (
    <div className="FileComponent__container">
      <div className={`FileComponent__file `}>
        <div className="FileComponent__fileName">
          <FileIcon color={"white"} />
          <p className="FileComponent__fileNameText">{fileName}</p>
        </div>
        <div className="FileComponent__fileSizeContainer">
          {fileSize && <p className="FileComponent__fileSize">{fileSize}</p>}
        </div>
      </div>
    </div>
  );
};
