import { QRSlice } from "Entities/QR";
import { QRService } from "../../API/services";
import { AppDispatch } from "Shared/Model";
import axios from "axios";

export const getQRSessionActionCreator =
  () => async (dispatch: AppDispatch) => {
    const {
      onFetchQR,
      onFetchQRError,
      onChengeQRSesion,
      onChengeQRResponsePasswordItem,
      onChengeStatusMessageQR,
    } = QRSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageQR({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageQR(null));
      }, 3000);
    };

    try {
      dispatch(onFetchQR());
      const response = await QRService.getQRSession();
      if (response.data.qr) {
        dispatch(onChengeQRResponsePasswordItem(null));
        dispatch(onChengeQRSesion(response.data.qr));
      } else {
        onChengeStatusMessage(
          "error",
          "Не можем получить данные для генерации QR"
        );
      }
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchQRError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChengeStatusMessage("error", "Ошибка создание сессии QR");
      } else {
        dispatch(
          onFetchQRError({
            message: "Неизвестная ошибка создание сессии QR",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage("error", "Ошибка создание сессии QR");
      }
    }
  };
