import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { PasswordService } from "../../API/services";
import { PasswordSlice } from "Entities/Password";

export const deletePassword = (id: number) => async (dispatch: AppDispatch) => {
  const {
    onDeletePassword,
    onFetchPassword,
    onFetchPasswordError,
    onChengeStatusMessagePassword,
  } = PasswordSlice.actions;

  const onChengeStatusMessage = (
    status: "error" | "success",
    message: string
  ) => {
    dispatch(
      onChengeStatusMessagePassword({
        status,
        message,
      })
    );
    setTimeout(() => {
      dispatch(onChengeStatusMessagePassword(null));
    }, 3000);
  };
  try {
    dispatch(onFetchPassword());
    await PasswordService.delete(id);
    dispatch(onDeletePassword(id));
    onChengeStatusMessage("success", "Данные успешно удалены");
    return true;
  } catch (e) {
    if (axios.isAxiosError(e) && typeof e.response?.data.message === "string") {
      dispatch(
        onFetchPasswordError({
          message: e.response?.data.message,
          errorCode: e.response?.status.toString(),
        })
      );
      onChengeStatusMessage(
        "error",
        "Не удалось удалить данные о сайте, попробуйте позже"
      );
    } else {
      dispatch(
        onFetchPasswordError({
          message: "Неизвестная ошибка удаления пароля",
          errorCode: "unknownError",
        })
      );
      onChengeStatusMessage(
        "error",
        "Не удалось удалить данные о сайте, попробуйте позже"
      );
    }
  }
  return false;
};
