import React, { useEffect, useLayoutEffect, useState } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { LoginForm } from "Features/Login";
import { NotificationBox } from "Shared/UI";
import { AuthSlice } from "Entities/User";

export const LoginPage = () => {
  const { isAuth, userItem } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [authNotification, setAuthNotification] = useState<string>("");

  useLayoutEffect(() => {
    const { onFetchAuthError } = AuthSlice.actions;
    dispatch(onFetchAuthError(null));
  }, []);

  useEffect(() => {
    if (typeof location.state?.authError === "string") {
      setAuthNotification(location.state.authError);
      setTimeout(() => {
        setAuthNotification("");
      }, 3000);
    }
  }, []);

  return (
    <main className={"loginPage"}>
      <h1 className="loginPage__head">Авторизация</h1>
      {isAuth ? (
        <NotificationBox
          title={
            <>
              Добро пожаловать, <span>{userItem?.name}</span>!
            </>
          }
          content={
            "Перенаправляем на страницу со списком сохраненых сервисов и паролей."
          }
        />
      ) : (
        <>
          <LoginForm />
        </>
      )}
      {authNotification !== "" && (
        <NotificationBox
          content={authNotification}
          className="loginPage__notification"
        />
      )}
    </main>
  );
};
