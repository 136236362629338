import { AxiosResponse } from "axios";
import { CatalogResponse } from "Entities/Catalog";
import { FileCatalogResponse } from "Entities/FileCatalog";
import { $api, urlEndpoints } from "Shared/API";
import { CATALOG_TYPE } from "Shared/Types";

export class CatalogService {
  static async create(
    catalogType: CATALOG_TYPE,
    userId: number,
    name: string
  ): Promise<AxiosResponse<CatalogResponse | FileCatalogResponse>> {
    const formData = new FormData();
    formData.append("user_id", userId.toString());
    formData.append("name", name);

    return $api.post<CatalogResponse | FileCatalogResponse>(
      catalogType === CATALOG_TYPE.FILES
        ? urlEndpoints.FILE_CATALOG.create
        : urlEndpoints.CATALOG.create,
      formData
    );
  }
}
