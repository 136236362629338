import React from "react";
import "./style.scss";
import classNames from "classnames";

interface Props {
  className?: string;
  labeLeftSigment: string;
  labelRightSigment: string;
  choisenSigment?: "left" | "right";
  setChoisenSigment?: (v: "left" | "right") => void;

}

export const PickerSigment = ({
  className,
  labeLeftSigment,
  labelRightSigment,
  choisenSigment,
  setChoisenSigment,
}: Props) => {
  const leftSigmentClassName = classNames(
    "pickerSigment__sigment",
    choisenSigment === "left" && "pickerSigment__sigment-active"
  );
  const rightSigmentClassName = classNames(
    "pickerSigment__sigment",
    choisenSigment === "right" && "pickerSigment__sigment-active"
  );

  const handleChoisenSigment = (e: "left" | "right") => {
    if(setChoisenSigment) {
      if(e === 'left' && choisenSigment !== 'left'){
        setChoisenSigment ('left');
      } else {
        setChoisenSigment('right');
      }
    }
  };

  return (
    <div className={`pickerSigment ${className}`} >
      <div
        className={leftSigmentClassName}
        onClick={() => handleChoisenSigment("left")}
      >
        {labeLeftSigment}
      </div>
      <div
        className={rightSigmentClassName}
        onClick={() => handleChoisenSigment("right")}
      >
        {labelRightSigment}
      </div>
    </div>
  );
};
