import React, { useEffect, useState } from "react";
import "./style.scss";
import { PasswordItem } from "Entities/Password";
import { Button, ItemPasswordAndFile } from "Shared/UI";
import { AddIcon } from "Shared/UI/Icons/AddIcon";
import { SearchInput } from "Features/SearchInput";
import { FileItem } from "Entities/File";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";

interface Props {
  className?: string;
  choisenCatalog: number | null;
  listArr: PasswordItem[] | FileItem[];
  showPasswordsOrFiles: boolean;
  onClickDeleteItem?: (v: PasswordItem) => void;
  selectedItem: number | null;
  setSelectedItem: (v: number | null) => void;
}

export const PassowrdsAndFilesList = ({
  className,
  listArr,
  showPasswordsOrFiles,
  onClickDeleteItem,
  selectedItem,
  setSelectedItem,
  choisenCatalog,
}: Props) => {
  const [list, setList] = useState<PasswordItem[] | FileItem[]>(listArr);
  const [filterList, setFilterList] = useState(list);

  const navigate = useNavigate();

  useEffect(() => {
    setList(listArr);
    setFilterList(listArr);
  }, [listArr]);

  const handleActiveItem = (id: number) => {
    setSelectedItem(id);
  };

  const deletePasswordFunc = (item: PasswordItem | FileItem) => {
    if (onClickDeleteItem) onClickDeleteItem(item);
  };

  const handleShowPasswords = (list: PasswordItem[] | FileItem[]) => {
    return Array.isArray(list) ? (
      <div className="passwordsList__list">
        {list.map((item) => (
          <ItemPasswordAndFile
            isPassword={showPasswordsOrFiles}
            item={item}
            key={item.id}
            handleActiveItem={() => handleActiveItem(item.id)}
            activeId={selectedItem}
            editItem={() => {
              navigate(
                "/" +
                  (showPasswordsOrFiles
                    ? routeEndpoint.updatePassword
                    : routeEndpoint.updateFile),
                {
                  state: {
                    passwordItem: item,
                  },
                }
              );
            }}
            deleteItem={() => {
              deletePasswordFunc(item);
            }}
          />
        ))}
      </div>
    ) : (
      []
    );
  };

  return (
    <div className={`passowrdsList ${className}`}>
      <div className="passowrdsList__contain">
        {showPasswordsOrFiles ? (
          <h2 className="passowrdsList__head">Список сохраненных паролей</h2>
        ) : (
          <h2 className="passowrdsList__head">Сохраненные файлы</h2>
        )}
        <SearchInput<(typeof listArr)[0]>
          allItems={listArr}
          setFilterListItem={setFilterList}
          placeholder="Одноклассники..."
        />
        {handleShowPasswords(filterList)}
      </div>
      {showPasswordsOrFiles ? (
        <Button
          onClick={() => {
            navigate("/" + routeEndpoint.addPassword);
          }}
          className="passowrdsList__addPassword"
        >
          Добавить пароль <AddIcon />
        </Button>
      ) : (
        <Button
          className="passowrdsList__addPassword"
          onClick={() => {
            navigate("/" + routeEndpoint.addFile, {
              state: {
                currentCatalog: choisenCatalog,
              },
            });
          }}
        >
          Добавить Файл <AddIcon />
        </Button>
      )}
    </div>
  );
};
