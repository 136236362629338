import React from "react";
import "./style.scss";
import { dropDownItemProps } from "../Dropdown/ui";
import classNames from "classnames";
import { DropDownItem } from "../Icons/DropDownItem";

interface Props {
  isMultiSelecting?: boolean;
  chickedChoise?: dropDownItemProps | null;
  assignedList?: dropDownItemProps[] | null;
  child: dropDownItemProps;
  handleAssign: (v: dropDownItemProps) => void;
  handleChickedChoise: (v: dropDownItemProps) => void;
  sizeTheme?: "large" | "medium" | "small" | "xsmall";
  status?: "success" | "checked" | "error";
}

export const ChoiseForDropDown = ({
  isMultiSelecting,
  chickedChoise,
  assignedList,
  sizeTheme,
  child,
  handleAssign,
  handleChickedChoise,
}: Props) => {
  const classNameChoise = classNames(
    "dropDwonChoise",
    sizeTheme && "dropDwonChoise-" + sizeTheme,
    isMultiSelecting
      ? {
          "dropDwonChoise-active": assignedList?.some((e) => {
            return e.itemId === child.itemId;
          }),
        }
      : { "dropDwonChoise-active": child.itemId === chickedChoise?.itemId }
  );

  return (
    <li
      className={classNameChoise}
      key={child.itemId}
      id={child.itemId.toString()}
      onClick={
        isMultiSelecting
          ? () => handleAssign(child)
          : () => handleChickedChoise(child)
      }
    >
      <span className="dropDwonChoise__leftPart">
        {child.leftIcon && <DropDownItem />}
        {child.label}
      </span>
      <span className="dropDwonChoise__rightPart">
        {child.rightIcon && <DropDownItem />}
        {child.count && (
          <span className="dropDwonChoise__count">{child.count}</span>
        )}
      </span>
    </li>
  );
};
