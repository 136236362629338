import userImg1 from "./userImg1.jpeg";
import userImg2 from "./userImg2.jpeg";
import userImg3 from "./userImg3.jpeg";
import userImg4 from "./userImg4.jpeg";

export const imgsList = {
  UserAvatar: [
    { img: userImg1, id: "1" },
    { img: userImg2, id: "2" },
    { img: userImg3, id: "3" },
    { img: userImg4, id: "4" },
  ],
};
