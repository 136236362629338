import React from "react";

interface Props {
    onClick?: () => void
}
export const PenEditIcon = ({onClick}: Props) => {
    return(
        <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 16H3.2615L13.498 5.7635L12.2365 4.502L2 14.7385V16ZM0.5 17.5V14.1155L13.6905 0.93075C13.8417 0.793416 14.0086 0.687333 14.1913 0.6125C14.3741 0.5375 14.5658 0.5 14.7663 0.5C14.9668 0.5 15.1609 0.535584 15.3488 0.60675C15.5367 0.677917 15.7032 0.791083 15.848 0.946249L17.0693 2.18275C17.2244 2.32758 17.335 2.49425 17.401 2.68275C17.467 2.87125 17.5 3.05975 17.5 3.24825C17.5 3.44942 17.4657 3.64133 17.397 3.824C17.3283 4.00683 17.2191 4.17383 17.0693 4.325L3.8845 17.5H0.5ZM12.8562 5.14375L12.2365 4.502L13.498 5.7635L12.8562 5.14375Z" fill="#A4A4A4"/>
        </svg>
    )
}