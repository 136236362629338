import { AuthResponse } from "Entities/User";
import { $api, urlEndpoints } from "Shared/API";
import { AxiosResponse } from "axios";

export default class AuthService {
  static async registration(
    email: string,
    password: string,
    nickname: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>(urlEndpoints.AUTH.registration, {
      email,
      password,
      name: nickname,
    });
  }
}
