import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { FileService } from "../../API/services";
import { FileSlice } from "Entities/File";

export const fetchAllFiles =
  (catalogId: number | null) => async (dispatch: AppDispatch) => {
    const { onFetchAllFile, onFetchFile, onFetchFileError } = FileSlice.actions;
    try {
      dispatch(onFetchFile());

      if (catalogId !== null) {
        const response = await FileService.fetchAll(catalogId);
        console.log("fetchAllFile", response);

        dispatch(onFetchAllFile(response.data.file));
      } else {
        dispatch(onFetchAllFile([]));
      }
    } catch (e) {
      console.log("fetchAllFile error", e);
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchFileError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
      } else {
        dispatch(
          onFetchFileError({
            message: "Неизвестная ошибка загрузки паролей",
            errorCode: "unknownError",
          })
        );
      }
    }
  };
