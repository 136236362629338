import React from "react";
import Video from "../../Assets/Background/-3ad9-4979-b1e9-0e714c0139a3.mp4";
import "./style.scss";

export const BackgroundVideo = () => {
  return (
    <>
      <div className="backgroundVideo__videoOverlay"></div>
      <video className="backgroundVideo" autoPlay loop muted playsInline>
        <source src={Video} type="video/mp4" />
      </video>
    </>
  );
};
