import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { CatalogService } from "../../API/services";
import { CatalogItem, CatalogSlice } from "Entities/Catalog";
import { CATALOG_TYPE } from "Shared/Types";
import { FileCatalogItem, FileCatalogSlice } from "Entities/FileCatalog";

export const createCatalog =
  (catalogType: CATALOG_TYPE, user_id: number, name: string) =>
  async (dispatch: AppDispatch) => {
    const {
      onCreateCatalog,
      onFetchCatalog,
      onFetchCatalogError,
      onChengeStatusMessageCatalog,
    } = CatalogSlice.actions;

    const { onCreateFileCatalog, onFetchFileCatalogError } =
      FileCatalogSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageCatalog({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageCatalog(null));
      }, 3000);
    };

    try {
      dispatch(onFetchCatalog());
      const response = await CatalogService.create(catalogType, user_id, name);

      if (response.data) {
        if (
          catalogType === CATALOG_TYPE.FILES &&
          "Fcatalogue" in response.data
        ) {
          dispatch(
            onCreateFileCatalog(response.data.Fcatalogue as FileCatalogItem)
          );
        } else if ("catalogue" in response.data) {
          dispatch(onCreateCatalog(response.data.catalogue as CatalogItem));
        }
        onChengeStatusMessage("success", "Каталог успешно добавлен");
      }
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        if (catalogType === CATALOG_TYPE.FILES) {
          dispatch(onFetchFileCatalogError(e.response?.data.message));
        } else {
          dispatch(
            onFetchCatalogError({
              message: e.response?.data.message,
              errorCode: e.response?.status.toString(),
            })
          );
        }
        onChengeStatusMessage(
          "error",
          "Не удалось создать каталог, попробуйте позже"
        );
      } else {
        dispatch(
          onFetchCatalogError({
            message: "Неизвестная ошибка создания каталога",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage(
          "error",
          "Не удалось создать каталог, попробуйте позже"
        );
      }
    }
  };
