import { FileService } from "../API/services";

export const downloadFile = async (fileId: number) => {
  try {
    const response = await FileService.downloadFile(fileId);
    const url = response.data.file;
    window.open(url);
  } catch (e) {
    console.log(e);
  }
};
