import React, { useState } from "react";
import "./style.scss";
import { UserType } from "Entities/User/Models/Type/UserType";
import { Button, Input } from "Shared/UI";
import { useValidationFieldForm, ValidationReturn } from "Shared/Lib";
import { NameValidation } from "../Model/Validation/NameValidation";
import { EmailValidation } from "../Model/Validation/EmailValidation";
import { useAppDispatch } from "Shared/Model";
import { updatePersonalInfo } from "Features/UpdatePersonalInfo";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";

type changeName = {
  className?: string;
  formType?: "email" | "name";
  userInfo?: UserType;
};
type changeEmail = {
  className?: string;
  formType?: "email" | "name";
  userInfo: UserType;
};

type changeNameAndEmailForm = (changeName | changeEmail) & {
  isShow?: boolean;
  setIsShow?: (v: boolean) => void;
} & React.FormHTMLAttributes<HTMLFormElement>;

const createHelperTextFunc = (validator: ValidationReturn) => {
  return (
    (validator.isDirty &&
      Object.values(validator.message).find((item) => {
        return item !== "";
      })) ||
    ""
  );
};

export const ChangePersonalNameAndEmailForm = ({
  className,
  formType,
  userInfo,
  isShow,
  setIsShow,
  ...props
}: changeNameAndEmailForm) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let initialValue = "";
  if (userInfo) {
    if (formType === "name") {
      initialValue = userInfo.name;
    } else {
      initialValue = userInfo.email;
    }
  }
  const validationOptions =
    formType === "name" ? NameValidation : EmailValidation;

  const [value, setValue] = useState(initialValue);
  const validator = useValidationFieldForm({
    value: value,
    ...validationOptions,
  });

  const handleShowingModal = () => {
    if (setIsShow) {
      setIsShow(!isShow);
    }
  };

  const onClickHendler = () => {
    if (value !== initialValue) {
      const userInfo = formType === "name" ? { name: value } : { email: value };
      dispatch(updatePersonalInfo({ ...userInfo }));
    }
    navigate("/" + routeEndpoint.profile);
  };
  return (
    <form
      {...props}
      className={`changePersonalNameAndEmailForm ${className ? className : ""}`}
    >
      <h2 className="changePersonalNameAndEmailForm__head">Изменение данных</h2>
      <Input
        label={formType === "name" ? "Имя пользователя" : "Почта"}
        value={value}
        placeholder={userInfo?.name}
        onChange={(e) => {
          validator.setIsDirty(true);
          setValue(e.target.value);
        }}
        isError={validator.isDirty && !validator.isValid}
        helperText={createHelperTextFunc(validator)}
      />
      <div className="changePersonalNameAndEmailForm__btns">
        <Button
          theme="bright"
          onClick={onClickHendler}
          disabled={!validator.isValid}
        >
          Сохранить изменения
        </Button>
        <Button type="button" theme="clear" onClick={handleShowingModal}>
          Отмена
        </Button>
      </div>
    </form>
  );
};
