import { QRRecieveResponse } from "Entities/QR";
import { $api, urlEndpoints } from "Shared/API";
import { AxiosResponse } from "axios";

export class QRService {
  static async recieve(
    id: number | string,
    security_key: string
  ): Promise<AxiosResponse<QRRecieveResponse>> {
    return $api.get<QRRecieveResponse>(urlEndpoints.QR.recieve, {
      params: {
        id,
        security_key,
      },
    });
  }
}
