import { QRSlice } from "Entities/QR";
import { QRService } from "../../API/services";
import { AppDispatch } from "Shared/Model";
import axios from "axios";

export const recieveQRActionCreator =
  (id: string | number, security_key: string) =>
  async (dispatch: AppDispatch) => {
    const {
      onFetchQR,
      onFetchQRError,
      onChengeQRResponsePasswordItem,
      onChengeQRSesion,
    } = QRSlice.actions;

    try {
      dispatch(onFetchQR());
      const response = await QRService.recieve(id, security_key);
      if (response.data.qr) {
        dispatch(onChengeQRResponsePasswordItem(response.data.qr));
        dispatch(onChengeQRSesion(null));
      }
      return true;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 500) {
          setTimeout(() => {
            dispatch(recieveQRActionCreator(id, security_key));
          }, 1000);
        } else if (typeof e.response?.data.message === "string") {
          dispatch(
            onFetchQRError({
              message: e.response?.data.message,
              errorCode: e.response?.status.toString(),
            })
          );
        }
      } else {
        dispatch(
          onFetchQRError({
            message: "Неизвестная ошибка создание сессии QR",
            errorCode: "unknownError",
          })
        );
      }
      return false;
    }
  };
