const regStr = /^[A-Za-zА-Яа-я0-9!@#$%^&*_\-+='|\\(){}[\]:""<>.,?/]+$/;

export const LoginValidationOptions = {
  options: {
    required: true,
    maxLength: 256,
    isMatchPattern: regStr,
  },
  messageText: {
    required: "Поле логина не должно быть пустым",
    isMatchPattern:
      "Логин для записи должен состоять лишь из русских или латинских букв, цифр и специальных символов: " +
      `!@#$%^&* _-+='|` +
      "\\" +
      `() {} []:"" <>,.? / "`,
  },
};
