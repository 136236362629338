import React from "react";
import { Button } from "../../../../Shared/UI/Button/ui";
import "./style.scss";
import { TelegramIcon } from "Shared/UI/Icons/TelegramIcon";

interface Props {
  className?: string;
}
export const Footer = ({ className }: Props) => {
  return (
    <footer className={`footer ${className ? className : ""}`}>
      <div className="footer__content">
        <p className="footer__contentTitle">Остались вопросы?</p>
        <div className="footer__contentLink">
          Пишите нам в
          <Button tag="a" size="xsmall" className="footer__telegramLink">
            <TelegramIcon />
            <span className="footer__telegram">Телеграм</span>
          </Button>
        </div>
      </div>
      <div className="footer__info">
        <div className="footer__infoTop">
          <p>Политика конфиденциальности</p>
          <p>Публичная оферта</p>
        </div>
        <div className="footer__infoBottom">
          Разработано [ от котов для людей ] в REDCAT
        </div>
      </div>
    </footer>
  );
};
