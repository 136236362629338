import React, { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { login } from "../Model/Store/actionCreator";
import { Button, EyeBtnForPassword, Input } from "Shared/UI";
import { useValidationFieldForm } from "Shared/Lib";

export const LoginForm = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const AuthState = useAppSelector((state) => state.auth);

  const emailValidation = useValidationFieldForm({
    value: email.trim(),
    options: { required: true, maxLength: 256, isEmail: true },
  });
  const passwordValidation = useValidationFieldForm({
    value: password,
    options: {
      required: true,
      maxLength: 50,
      minLength: 8,
      isContainCapitalLetter: true,
      isContainDigit: true,
      isContainSpecCharacter: true,
      isPassword: true,
    },
    messageText: {
      maxLength: "Пароль должен состоять от 8 до 50 символов",
      minLength: "Пароль должен состоять от 8 до 50 символов",
    },
  });

  const [error, setError] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string | JSX.Element>("");
  useEffect(() => {
    if (AuthState.error !== null) {
      if (AuthState.error.errorCode === "403") {
        setErrorPassword("Неверный пароль.");
      } else if (AuthState.error.errorCode === "404") {
        setErrorEmail("Пользователь не зарегистрирован в системе.");
      } else {
        setError("Не удалось выполнить вход, попробуйте позже.");
      }
    } else {
      setError("");
      setErrorEmail("");
      setErrorPassword("");
    }
  }, [AuthState.error]);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(login(email.trim(), password));
  };

  const emailHelperText =
    errorEmail ||
    (emailValidation.isDirty &&
      Object.values(emailValidation.message).find((item) => {
        return item !== "";
      }));

  const passwordHelperText =
    errorPassword ||
    error ||
    (passwordValidation.isDirty &&
    Object.values(passwordValidation.message).find((item) => {
      return item !== "";
    })
      ? Object.values(passwordValidation.message).find((item) => {
          return item !== "";
        })
      : "");

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorEmail("");
    setError("");
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorPassword("");
    setError("");
  };

  return (
    <form className="loginForm">
      <Input
        onChange={onChangeEmail}
        value={email}
        type="text"
        placeholder="Введите адрес электронной почты"
        className="loginForm__field"
        onBlur={() => {
          emailValidation.setIsDirty(true);
        }}
        isError={
          (!emailValidation.isValid || !!error || !!errorEmail) &&
          emailValidation.isDirty
        }
        helperText={emailHelperText}
      />
      <Input
        onChange={onChangePassword}
        value={password}
        type={isVisiblePassword ? "text" : "password"}
        placeholder="Введите пароль"
        afterSlot={
          <EyeBtnForPassword
            className="loginForm__eyeBtnForPassword"
            val={isVisiblePassword}
            setVal={setIsVisiblePassword}
          />
        }
        onBlur={() => {
          passwordValidation.setIsDirty(true);
        }}
        isError={
          (!passwordValidation.isValid || !!error || !!errorPassword) &&
          passwordValidation.isDirty
        }
        helperText={passwordHelperText}
        className="loginForm__field"
      />
      <Button
        type="submit"
        tag="button"
        theme="bright"
        className="loginForm__btn"
        disabled={
          !emailValidation.isValid ||
          !passwordValidation.isValid ||
          !!errorEmail ||
          !!errorPassword ||
          !!error ||
          AuthState.isLoading
        }
        onClick={onSubmit}
      >
        Войти
      </Button>
      <Button
        type="button"
        theme="clear"
        className="loginForm__btn"
        onClick={() => {
          navigate(-1);
        }}
      >
        Назад
      </Button>
    </form>
  );
};
