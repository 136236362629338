import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FileCatalogError, FileCatalogItem } from "../Type/fileCatalogType";

type StatusType = {
  status: "success" | "error";
  message: string;
};

interface InitialStateProps {
  fileCatalogItems: FileCatalogItem[];
  isLoading: boolean;
  error: FileCatalogError | null;
  statusMessage: StatusType | null;
}
const initialState: InitialStateProps = {
  fileCatalogItems: [],
  isLoading: false,
  error: null,
  statusMessage: null,
};
export const FileCatalogSlice = createSlice({
  name: "fileCatalog",
  initialState,
  reducers: {
    onFetchFileCatalog(state) {
      state.isLoading = true;
    },
    onFetchFileCatalogError(
      state,
      action: PayloadAction<FileCatalogError | null>
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    onChangeStatusMessageFileCatalog(
      state,
      action: PayloadAction<StatusType | null>
    ) {
      state.statusMessage = action.payload;
    },

    onFetchAllFileCatalog(state, action: PayloadAction<FileCatalogItem[]>) {
      state.isLoading = false;
      state.error = null;
      state.fileCatalogItems = action.payload;
    },

    onCreateFileCatalog(state, action: PayloadAction<FileCatalogItem>) {
      state.fileCatalogItems.push(action.payload);
    },

    onChangeFileCatalog(state, action: PayloadAction<FileCatalogItem>) {
      const indexItem = state.fileCatalogItems.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (indexItem >= 0) {
        state.fileCatalogItems[indexItem] = action.payload;
      } else {
        console.log("Каталога с таким id не существует");
        throw new Error("Каталога с таким id не существует");
      }
    },

    onDeleteFileCatalog(state, action: PayloadAction<number>) {
      const indexItem = state.fileCatalogItems.findIndex((item) => {
        return item.id === action.payload;
      });
      if (indexItem >= 0) {
        state.fileCatalogItems.splice(indexItem, 1);
      } else {
        console.log("Каталога с таким id не существует");
        throw new Error("Каталога с таким id не существует");
      }
    },
    onResetFileCatalogState(state) {
      state.fileCatalogItems = initialState.fileCatalogItems;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.statusMessage = initialState.statusMessage;
    },
  },
});

export default FileCatalogSlice.reducer;
