import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, EyeBtnForPassword, Input } from "Shared/UI";
import { ValidationReturn, useValidationFieldForm } from "Shared/Lib";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { updatePersonalInfo } from "Features/UpdatePersonalInfo";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";

const passwordValidationOptions = {
  options: {
    required: true,
    maxLength: 50,
    minLength: 8,
    isPassword: true,
    isContainCapitalLetter: true,
    isContainDigit: true,
    isContainSpecCharacter: true,
  },
  messageText: {
    maxLength: "Поле должно содержать не менее 8 и не более 50 символов",
    minLength: "Поле должно содержать не менее 8 и не более 50 символов",
    isPassword: `Допустимые символы A-Z, a-z, 0-9 спецсимволы !@#$%^&* _-+='|\\() {} []:"" <>,.? /`,
    isContainCapitalLetter: `Пароль должен состоять из латинских букв нижнего регистра и не менее одной латинской буквы в верхнем регистре, одной цифры и не менее одного спец.символа из списка: !@#$%^&* _-+='|\\() {} []:"" <>,.? /`,
    isContainDigit: `Пароль должен состоять из латинских букв нижнего регистра и не менее одной латинской буквы в верхнем регистре, одной цифры и не менее одного спец.символа из списка: !@#$%^&* _-+='|\\() {} []:"" <>,.? /`,
    isContainSpecCharacter: `Пароль должен состоять из латинских букв нижнего регистра и не менее одной латинской буквы в верхнем регистре, одной цифры и не менее одного спец.символа из списка: !@#$%^&* _-+='|\\() {} []:"" <>,.? /`,
  },
};

const createHelperTextFunc = (validator: ValidationReturn) => {
  return (
    (validator.isDirty &&
      Object.values(validator.message).find((item) => {
        return item !== "";
      })) ||
    ""
  );
};

interface PasswordFormProps {
  className?: string;
  isShow?: boolean;
  setIsShow?: (v: boolean) => void;
  onClickSaveBtn?: () => void;
}
export const ChangePersonalPasswordForm = ({
  className,
  isShow,
  setIsShow,
  onClickSaveBtn,
}: PasswordFormProps) => {
  const dispatch = useAppDispatch();
  const AuthState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [errorPassWord, setErrorPassword] = useState<string>("");
  const passwordValidation = useValidationFieldForm({
    value: password,
    ...passwordValidationOptions,
  });

  const [newPassword, setNewPassword] = useState<string>("");
  const [isVisibleNewPassword, setIsVisibleNewPassword] =
    useState<boolean>(false);
  const newPasswordValidation = useValidationFieldForm({
    value: newPassword,
    ...passwordValidationOptions,
  });

  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [isVisibleRepeatPassword, setIsVisibleRepeatPassword] =
    useState<boolean>(false);
  const repeatPasswordValidation = useValidationFieldForm({
    value: repeatPassword,
    options: {
      required: true,
    },
  });

  const passwordIsError =
    (repeatPasswordValidation.isDirty &&
      (!repeatPasswordValidation.isValid || repeatPassword !== newPassword)) ||
    (passwordValidation.isDirty && !passwordValidation.isValid) ||
    !!error ||
    ((passwordValidation.isDirty || newPasswordValidation.isDirty) &&
      password === newPassword);

  const testNewPasswordIsError = repeatPassword === newPassword ? false : true;
  const helperTextTestNewPass = testNewPasswordIsError
    ? "Пароли не совпадают"
    : null;

  const generalValid =
    passwordValidation.isValid &&
    newPasswordValidation.isValid &&
    repeatPasswordValidation.isValid;

  const disabledSaveBtn =
    !generalValid ||
    password === newPassword ||
    newPassword !== repeatPassword ||
    !!error ||
    !!errorPassWord;

  const newPasswordHelperText =
    createHelperTextFunc(newPasswordValidation) ||
    (password === newPassword && newPasswordValidation.isDirty
      ? "Новый пароль должен отличаться от старого"
      : "");

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorPassword("");
    const pass = e.target.value;
    setPassword(pass);
    setError("");
  };
  const onAddNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setError("");
  };

  const onTestNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(e.target.value);
    setError("");
  };

  const handleShowingFormModal = () => {
    if (setIsShow) {
      if (!isShow) setIsShow(true);
    }
  };
  const onClickSaveBtnFunc = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onClickSaveBtn) onClickSaveBtn();

    const result = await dispatch(
      updatePersonalInfo({
        password: newPassword,
        old_password: password,
      })
    );
    if (result) {
      navigate("/" + routeEndpoint.profile);
    }
  };

  useEffect(() => {
    if (AuthState.error !== null) {
      if (AuthState.error.errorCode === "400") {
        setErrorPassword("Текущий пароль введен неверно.");
      } else {
        setError("Не удалось изменить пароль, попробуйте позже.");
      }
    }
  }, [AuthState.error]);

  return (
    <form
      className={`changePersonalPasswordForm ${className ? className : ""}`}
      onSubmit={onClickSaveBtnFunc}
    >
      <h2 className="changePersonalPasswordForm__head">Изменение данных</h2>
      <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onChangePassword}
        value={password}
        type={isVisiblePassword ? "text" : "password"}
        label="Текущий пароль"
        placeholder={`Введите пароль`}
        afterSlot={
          <EyeBtnForPassword
            val={isVisiblePassword}
            setVal={setIsVisiblePassword}
            className="registrationForm__eyeBtnForPassword"
          />
        }
        onBlur={() => {
          passwordValidation.setIsDirty(true);
        }}
        isError={
          (passwordValidation.isDirty && !passwordValidation.isValid) ||
          !!error ||
          !!errorPassWord
        }
        helperText={createHelperTextFunc(passwordValidation) || errorPassWord}
      />

      <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onAddNewPassword}
        value={newPassword}
        type={isVisibleNewPassword ? "text" : "password"}
        label="Новый пароль"
        placeholder={`Введите новый пароль`}
        afterSlot={
          <EyeBtnForPassword
            val={isVisibleNewPassword}
            setVal={setIsVisibleNewPassword}
            className="registrationForm__eyeBtnForPassword"
          />
        }
        onBlur={() => {
          newPasswordValidation.setIsDirty(true);
        }}
        isError={passwordIsError && newPasswordValidation.isDirty}
        helperText={newPasswordHelperText}
      />

      <Input
        sizeTheme="large"
        className="registrationForm__field"
        onChange={onTestNewPassword}
        value={repeatPassword}
        type={isVisibleRepeatPassword ? "text" : "password"}
        label="Подтвердите пароль"
        placeholder={`Повторите пароль`}
        afterSlot={
          <EyeBtnForPassword
            val={isVisibleRepeatPassword}
            setVal={setIsVisibleRepeatPassword}
            className="registrationForm__eyeBtnForPassword"
          />
        }
        onBlur={() => {
          repeatPasswordValidation.setIsDirty(true);
        }}
        isError={passwordIsError && repeatPasswordValidation.isDirty}
        helperText={
          createHelperTextFunc(repeatPasswordValidation) ||
          helperTextTestNewPass ||
          error
        }
      />
      <div className="changePersonalPasswordForm__btns">
        <Button
          type="submit"
          className="changePersonalPasswordForm__grayBtn"
          theme="bright"
          disabled={disabledSaveBtn}
        >
          Сохранить изменения
        </Button>
        <Button className="" theme="clear" onClick={handleShowingFormModal}>
          Отмена
        </Button>
      </div>
    </form>
  );
};
