import React from "react";
import { Button } from "Shared/UI";
import "./style.scss";

interface LogoutFormProps {
  className?: string;
  isShowForm: boolean;
  setSowingForm: (v: boolean) => void;
}

export const LogoutForm = ({
  className,
  isShowForm,
  setSowingForm,
}: LogoutFormProps) => {
  const showingQuestionForm = () => {
    if (!isShowForm) setSowingForm(true);
  };
  return (
    <Button
      theme="with_border"
      type="button"
      onClick={showingQuestionForm}
      className={"logoutForm" + (className ? " " + className : "")}
    >
      Выйти
    </Button>
  );
};
