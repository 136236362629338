import React from "react";
import "./style.scss";
import { PenEditIcon } from "../Icons/EyeIcon/PenEditIcon";
import { DeleteIcon } from "../Icons/DeleteIcon";
import classNames from "classnames";

interface Props {
  label: string;
  className?: string;
  isDisabled?: boolean;
  editITem?: () => void;
  deleteItem?: () => void;
  isActive?: boolean;
  onClick?: () => void;
}
export const ListItem = ({
  label,
  className,
  isDisabled = false,
  editITem,
  deleteItem,
  isActive,
  onClick,
}: Props) => {
  const listClassName = classNames(
    "listItem",
    className,
    isDisabled && "listItem-disabled",
    isActive && "listItem-active"
  );

  const iconsClassName = classNames("listItem__icons");

  return (
    <li className={listClassName} onClick={onClick}>
      <div className="listItem__label">{label}</div>
      <div className={iconsClassName}>
        <PenEditIcon onClick={editITem} />
        <DeleteIcon onClick={deleteItem} />
      </div>
    </li>
  );
};
