import React from "react";
import "./style.scss";
import classNames from "classnames";

interface Props {
  className?: string;
  itemType: string;
  typeValue?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}
export const InfoItem = ({ className, itemType, typeValue, isDisabled,onClick , ...props }: Props) => {
  const classNameItem = classNames(
    'infoItem',
    className && className,
    isDisabled && 'disabled'
  )
  const printLengthPassword = () => {
    let length = typeValue?.length;
    let point = "";
    while (length) {
      point = point + ".";
      length--;
    }
    return point;
  };
  return (
    <>
      {itemType !== "Пароль" ? (
        <div className={classNameItem} onClick={onClick} {...props}>
          <div className="infoItem__itemName">{itemType}</div>
          <div className="infoItem__itemValue">{typeValue}</div>
        </div>
      ) : (
        <div {...props} className={`infoItem ${className}`} onClick={onClick}>
          <div className="infoItem__itemName">{itemType}</div>
          <div className="infoItem__itemValue">{printLengthPassword()}</div>
        </div>
      )}
    </>
  );
};
