import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { PasswordService } from "../../API/services";
import { PasswordItem, PasswordSlice } from "Entities/Password";

export const createPassword =
  (passwordItem: Omit<PasswordItem, "id">) => async (dispatch: AppDispatch) => {
    const {
      onCreatePassword,
      onFetchPassword,
      onFetchPasswordError,
      onChengeStatusMessagePassword,
    } = PasswordSlice.actions;
    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessagePassword({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessagePassword(null));
      }, 3000);
    };
    try {
      dispatch(onFetchPassword());

      const response = await PasswordService.create(passwordItem);
      dispatch(onCreatePassword(response.data.password));
      onChengeStatusMessage("success", "Данные успешно добавлены");

      return true;
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchPasswordError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChengeStatusMessage(
          "error",
          "Не смогли добавить ваши данные, попробуйте позже"
        );
      } else {
        dispatch(
          onFetchPasswordError({
            message: "Неизвестная ошибка создания пароля",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage(
          "error",
          "Не смогли добавить ваши данные, попробуйте позже"
        );
      }
      return false;
    }
  };
