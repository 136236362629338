import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { CatalogService } from "../../API/services";
import { CatalogSlice } from "Entities/Catalog";
import { CatalogPageSlice } from "Entities/CatalogPage";
import { CATALOG_TYPE } from "Shared/Types";

export const deleteCatalog =
  (id: number, catalogType: CATALOG_TYPE) => async (dispatch: AppDispatch) => {
    const {
      onDeleteCatalog,
      onFetchCatalog,
      onFetchCatalogError,
      onChengeStatusMessageCatalog,
    } = CatalogSlice.actions;
    const { onChengeCurrentCatalog, onChengeSelectedCatalog } =
      CatalogPageSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageCatalog({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageCatalog(null));
      }, 3000);
    };

    try {
      dispatch(onFetchCatalog());
      await CatalogService.delete(id, catalogType);

      dispatch(onDeleteCatalog(id));
      dispatch(onChengeCurrentCatalog(null));
      dispatch(onChengeSelectedCatalog(null));

      onChengeStatusMessage("success", "Рубрика удалена");
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchCatalogError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChengeStatusMessage(
          "error",
          "Не удалось удалить каталог, попробуйте позже"
        );
      } else {
        dispatch(
          onFetchCatalogError({
            message: "Неизвестная ошибка создания каталога",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage(
          "error",
          "Не удалось удалить каталог, попробуйте позже"
        );
      }
    }
  };
