import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Checkbox, Input } from "Shared/UI";
import { ValidationReturn, useValidationFieldForm } from "Shared/Lib";
import { useAppSelector } from "Shared/Model";
import { NavLink } from "react-router-dom";
import { NameValidationOptions } from "../Model/ValidationItems/NameValidationOptions";
import { DropDown } from "Shared/UI/Dropdown/ui";
import { FileItem } from "Entities/File";
import { FileUploader } from "Shared/UI/FileUploader/ui";
import { CreateFileButton } from "Features/CreateFile";
import { UpdateFileButton } from "Features/UpdateFile";
import { FileComponent } from "Shared/UI/FileComponent/ui";

type createFile = {
  formType?: "create";
  fileItem?: FileItem;
};
type changeFile = {
  fileItem?: FileItem;
  formType?: "change";
};

type CreateAndChangeFileFormProps = (createFile | changeFile) & {
  onClickCancelFunc?: () => void;
  onClickSubmitFunc?: () => void;
} & React.FormHTMLAttributes<HTMLFormElement>;

export const CreateAndChangeFileForm = ({
  fileItem,
  formType = "create",
  className,
  onClickCancelFunc,
  onClickSubmitFunc,
  ...props
}: CreateAndChangeFileFormProps) => {
  const FileState = useAppSelector((state) => state.files);
  const FileCatalogState = useAppSelector((state) => state.fileCatalogs);
  const [loading, setLoading] = useState(false);

  const passwordsArr =
    formType === "change" && Array.isArray(FileState.fileItems)
      ? FileState.fileItems.filter((item) => {
          return item.id !== fileItem?.id;
        })
      : FileState.fileItems;

  const passwordNamesArr: string[] = Array.isArray(passwordsArr)
    ? passwordsArr.map((item) => {
        return item.name;
      })
    : [];

  const catalogNamesArr = FileCatalogState.fileCatalogItems.map((item) => {
    return {
      id: item.id,
      label: item.name,
    };
  });

  const createHelperTextFunc = (validator: ValidationReturn) => {
    return (
      (validator.isDirty &&
        Object.values(validator.message).find((item) => {
          return item !== "";
        })) ||
      ""
    );
  };

  const [name, setName] = useState(fileItem ? fileItem.name : "");
  const [file, setFile] = useState<File | undefined>(undefined);
  useEffect(() => {
    console.log(file);
  }, [file]);
  const nameValidator = useValidationFieldForm({
    value: name.trim(),
    ...NameValidationOptions(passwordNamesArr),
  });

  const [catalog, setCatalog] = useState(fileItem?.catalogue_id);
  const catalogValidator = useValidationFieldForm({
    value: catalog?.toString().trim() || "",
    options: {
      required: true,
    },
  });

  const [isAgree, setIsAgree] = useState<boolean>(false);

  const generalValid =
    !nameValidator.isValid || !catalogValidator.isValid || !isAgree;
  return (
    <form
      {...props}
      className={"createAndChangeFileForm" + (className ? " " + className : "")}
    >
      <h2 className="createAndChangeFileForm__head">Данные о файле</h2>
      <Input
        onChange={(e) => {
          setName(e.target.value);
        }}
        value={name}
        placeholder="Название"
        className="createAndChangeFileForm__field"
        isError={nameValidator.isDirty && !nameValidator.isValid}
        helperText={createHelperTextFunc(nameValidator)}
        onBlur={() => {
          nameValidator.setIsDirty(true);
        }}
      />

      <DropDown
        placeholder="Рубрикатор"
        helperText={createHelperTextFunc(catalogValidator)}
        choisesArr={catalogNamesArr.map((item) => {
          return {
            itemId: item.id,
            label: item.label,
          };
        })}
        setChickedArr={(val) => {
          setCatalog(Number(val[0].itemId));
          console.log(catalog);
        }}
        className="createAndChangeFileForm__dropdown"
      />
      {formType === "change" && fileItem && (
        <div className="createAndChangeFileForm__fileWrap">
          <FileComponent fileName={fileItem?.name} />
        </div>
      )}
      {formType === "create" && (
        <FileUploader
          initFile={fileItem ? file : undefined}
          loading={loading}
          onUpload={async (file) => {
            setFile(file);
          }}
        />
      )}
      <Checkbox
        checked={isAgree}
        setStatus={(v) => {
          setIsAgree(v);
        }}
      >
        Я ознакомлен и согласен с{" "}
        <NavLink to={"/"} className={"createAndChangeFileForm__politicLink"}>
          Политикой конфиденциальности
        </NavLink>
      </Checkbox>

      <div className="createAndChangeFileForm__btnWrap">
        <Button
          theme="clear"
          className="createAndChangeFileForm__btn_cancel createAndChangeFileForm__btn"
          onClick={() => {
            if (onClickCancelFunc) onClickCancelFunc();
          }}
        >
          {formType === "create" ? "Отменить" : "Назад"}
        </Button>
        {formType === "create" ? (
          <CreateFileButton
            loading={loading}
            setLoading={setLoading}
            fileItem={{
              name,
              catalogue_id: catalog,
              extension: file?.name.split(".").pop() || "",
              file: file,
            }}
            className="createAndChangeFileForm__btn_submit createAndChangeFileForm__btn"
            disabled={generalValid || !file}
            onClick={() => {
              if (onClickSubmitFunc) onClickSubmitFunc();
            }}
          />
        ) : (
          fileItem &&
          fileItem?.id && (
            <UpdateFileButton
              fileItem={{
                name,
                catalogue_id: catalog,
                extension: fileItem.extension,
                id: fileItem.id,
              }}
              className="createAndChangeFileForm__btn_submit createAndChangeFileForm__btn"
              disabled={generalValid}
              onClick={() => {
                if (onClickSubmitFunc) onClickSubmitFunc();
              }}
            />
          )
        )}
      </div>
    </form>
  );
};
