import React from "react";
import { BackgroundVideo } from "../../../Shared/UI/BackgroundVideo/ui";
import { GeneralInfo } from "../../../Shared/UI/GeneralInfo/ui";
import { SelectAuthForm } from "../../../Features/SelectingAuthForm/ui";
import "./style.scss"

export const TrustPage = () => {
    return <main className={"trustPage"}>
        <BackgroundVideo />
        <GeneralInfo withUsingExplain={true} className="trustPage__generalInfo"/>
        <SelectAuthForm classNames="trustPage__selectingAuth"/>
    </main>;
};
