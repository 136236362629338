import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PasswordError, PasswordItem } from "../Type/passwordType";

type StatusType = {
  status: "success" | "error";
  message: string;
};

interface InitialStateProps {
  passwordItems: PasswordItem[];
  isLoading: boolean;
  error: PasswordError | null;
  statusMessage: StatusType | null;
}
const initialState: InitialStateProps = {
  passwordItems: [],
  isLoading: false,
  error: null,
  statusMessage: null,
};

export const PasswordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    onFetchPassword(state) {
      state.isLoading = true;
    },
    onFetchPasswordError(state, action: PayloadAction<PasswordError | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onChengeStatusMessagePassword(
      state,
      action: PayloadAction<StatusType | null>
    ) {
      state.statusMessage = action.payload;
    },

    onFetchAllPassword(state, action: PayloadAction<PasswordItem[]>) {
      state.isLoading = false;
      state.error = null;
      state.passwordItems = action.payload;
    },
    onCreatePassword(state, action: PayloadAction<PasswordItem>) {
      state.passwordItems.push(action.payload);
    },
    onChangePassword(state, action: PayloadAction<PasswordItem>) {
      const indexItem = state.passwordItems.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (indexItem >= 0) {
        state.passwordItems[indexItem] = action.payload;
      } else {
        throw new Error("Пароля с таким id не существует");
      }
    },
    onDeletePassword(state, action: PayloadAction<number>) {
      const indexItem = state.passwordItems.findIndex((item) => {
        return item.id === action.payload;
      });
      if (indexItem >= 0) {
        state.passwordItems.splice(indexItem, 1);
      } else {
        throw new Error("Каталога с таким id не существует");
      }
    },
    onDeleteAllPasswordsFromOneCatalog(state, action: PayloadAction<number>) {
      const allIndexesItemFromCatalog: number[] = [];

      state.passwordItems.forEach((item, index) => {
        if (item.catalogue_id === action.payload) {
          allIndexesItemFromCatalog.push(index);
        }
      });

      allIndexesItemFromCatalog.forEach((index) => {
        state.passwordItems.splice(index, 1);
      });
    },
    onResetPasswordState(state) {
      state.passwordItems = initialState.passwordItems;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.statusMessage = initialState.statusMessage;
    },
  },
});

export default PasswordSlice.reducer;
