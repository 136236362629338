import React from 'react'

export const TelegramIcon = () => {
    return(
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.01822 9.84564C6.27018 7.99356 9.10481 6.77243 10.5221 6.18249C14.5736 4.49789 15.4144 4.20531 15.9635 4.1954C16.0843 4.19346 16.3532 4.22331 16.5286 4.36516C16.6745 4.48479 16.7155 4.64657 16.736 4.76016C16.7542 4.87364 16.7793 5.13226 16.7588 5.33415C16.54 7.64014 15.5898 13.236 15.1068 15.8189C14.904 16.9117 14.5006 17.2781 14.111 17.3139C13.2633 17.3918 12.6208 16.7543 11.8005 16.2167C10.5176 15.3752 9.79296 14.8516 8.54655 14.0306C7.10644 13.0818 8.04069 12.5602 8.861 11.708C9.07519 11.4849 12.8076 8.09086 12.8783 7.78301C12.8874 7.7445 12.8965 7.60095 12.8099 7.5253C12.7256 7.44942 12.6003 7.4754 12.5091 7.4959C12.3792 7.52507 10.3307 8.88041 6.35677 11.5617C5.77571 11.9614 5.24934 12.1562 4.77539 12.1459C4.25586 12.1348 3.25325 11.8515 2.50813 11.6095C1.59668 11.3126 0.869787 11.1556 0.933589 10.6514C0.965491 10.3889 1.3278 10.1202 2.01822 9.84564Z" fill="#ffffff"/>
            </svg> 
        </>
    );
}