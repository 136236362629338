export const generatePasswordFunc = () => {
  const capitalLettersArr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const lowerLettersArr = "abcdefghijklmnopqrstuvwxyz".split("");
  const numbersArr = "0123456789".split("");
  const specialSymbolsArr = `!@#$%^&*_-+='|\\(){}[]:""<>,.?/`.split("");

  let password = [];

  const addRandomChar = (arr: string[]) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  password = [
    addRandomChar(capitalLettersArr),
    addRandomChar(lowerLettersArr),
    addRandomChar(numbersArr),
    addRandomChar(specialSymbolsArr),
  ];

  for (let index = 3; index < Math.floor(Math.random() * 46) + 8; index++) {
    password.push(
      addRandomChar([
        ...capitalLettersArr,
        ...lowerLettersArr,
        ...numbersArr,
        ...specialSymbolsArr,
      ])
    );
  }

  return password.sort(() => Math.random() - 0.5).join("");
};
