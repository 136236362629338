import React, { useState } from "react";
import "./style.scss";
import { PasswordItem } from "Entities/Password";
import {
  Button,
  Checkbox,
  EyeBtnForPassword,
  Input,
  ThreeStarsIcon,
} from "Shared/UI";
import { ValidationReturn, useValidationFieldForm } from "Shared/Lib";
import { useAppSelector } from "Shared/Model";
import { CreatePasswordButton } from "Features/CreatePassword";
import { UpdatePasswordButton } from "Features/UpdatePassword";
import { NavLink } from "react-router-dom";
import { generatePasswordFunc } from "Features/generatePassword";
import { NameValidationOptions } from "../Model/ValidationItems/NameValidationOptions";
import { URLValidationOptions } from "../Model/ValidationItems/URLValidationOptions";
import { PasswordValidationOptions } from "../Model/ValidationItems/PasswordValidationOptions";
import { LoginValidationOptions } from "../Model/ValidationItems/LoginValidationOptions";
import { DropDown } from "Shared/UI/Dropdown/ui";

type createPassword = {
  formType?: "create";
  passwordItem?: PasswordItem;
};
type changePassword = {
  passwordItem: PasswordItem;
  formType?: "change";
};

type CreateAndChangePasswordFormProps = (createPassword | changePassword) & {
  onClickCancelFunc?: () => void;
  onClickSubmitFunc?: () => void;
} & React.FormHTMLAttributes<HTMLFormElement>;

export const CreateAndChangePasswordForm = ({
  passwordItem,
  formType = "create",
  className,
  onClickCancelFunc,
  onClickSubmitFunc,
  ...props
}: CreateAndChangePasswordFormProps) => {
  const PasswordState = useAppSelector((state) => state.passwords);
  const CatalogState = useAppSelector((state) => state.catalogs);

  const passwordsArr =
    formType === "change" && Array.isArray(PasswordState.passwordItems)
      ? PasswordState.passwordItems.filter((item) => {
          return item.id !== passwordItem?.id;
        })
      : PasswordState.passwordItems;

  const passwordNamesArr: string[] = Array.isArray(passwordsArr)
    ? passwordsArr.map((item) => {
        return item.name;
      })
    : [];

  const catalogNamesArr = Array.isArray(CatalogState.catalogItems)
    ? CatalogState.catalogItems.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      })
    : [];

  const createHelperTextFunc = (validator: ValidationReturn) => {
    return (
      (validator.isDirty &&
        Object.values(validator.message).find((item) => {
          return item !== "";
        })) ||
      ""
    );
  };

  const [name, setName] = useState(passwordItem ? passwordItem.name : "");
  const nameValidator = useValidationFieldForm({
    value: name.trim(),
    ...NameValidationOptions(passwordNamesArr),
  });

  const [login, setLogin] = useState<string>(
    passwordItem && typeof passwordItem.login === "string"
      ? passwordItem.login
      : ""
  );
  const loginValidator = useValidationFieldForm({
    value: login.trim(),
    ...LoginValidationOptions,
  });

  const [siteURL, setSiteURL] = useState(
    passwordItem && typeof passwordItem.url === "string" ? passwordItem.url : ""
  );
  const siteURLValidator = useValidationFieldForm({
    value: siteURL.trim(),
    ...URLValidationOptions,
  });

  const [catalog, setCatalog] = useState(
    passwordItem && passwordItem.catalogue_id
  );
  const initialCatalogName = Array.isArray(CatalogState.catalogItems)
    ? CatalogState.catalogItems.find((item) => {
        return item.id === catalog;
      })?.name
    : "";

  const [catalogName, setCatalogName] = useState(
    initialCatalogName ? initialCatalogName : ""
  );
  const catalogValidator = useValidationFieldForm({
    value: catalog?.toString().trim() || "",
    options: {
      required: true,
    },
  });

  const [password, setPassword] = useState(
    passwordItem && typeof passwordItem.password === "string"
      ? passwordItem.password
      : ""
  );
  const passwordValidator = useValidationFieldForm({
    value: password.trim(),
    ...PasswordValidationOptions,
  });

  const [isAgree, setIsAgree] = useState<boolean>(false);

  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);

  const generalValid =
    !nameValidator.isValid ||
    !siteURLValidator.isValid ||
    !catalogValidator.isValid ||
    !loginValidator.isValid ||
    !passwordValidator.isValid ||
    !isAgree;
  return (
    <form
      {...props}
      className={
        "createAndChangePasswordForm" + (className ? " " + className : "")
      }
    >
      <h2 className="createAndChangePasswordForm__head">Добавление пароля</h2>
      <Input
        onChange={(e) => {
          setName(e.target.value);
        }}
        value={name}
        placeholder="Название"
        className="createAndChangePasswordForm__field"
        isError={nameValidator.isDirty && !nameValidator.isValid}
        helperText={createHelperTextFunc(nameValidator)}
        onBlur={() => {
          nameValidator.setIsDirty(true);
        }}
      />
      <Input
        onChange={(e) => {
          setSiteURL(e.target.value);
        }}
        value={siteURL}
        placeholder="Ссылка на сайт"
        className="createAndChangePasswordForm__field"
        isError={siteURLValidator.isDirty && !siteURLValidator.isValid}
        helperText={createHelperTextFunc(siteURLValidator)}
        onBlur={() => {
          siteURLValidator.setIsDirty(true);
        }}
      />
      <Input
        onChange={(e) => {
          setLogin(e.target.value);
        }}
        value={login}
        placeholder="Логин"
        className="createAndChangePasswordForm__field"
        isError={loginValidator.isDirty && !loginValidator.isValid}
        helperText={createHelperTextFunc(loginValidator)}
        onBlur={() => {
          loginValidator.setIsDirty(true);
        }}
      />
      <Input
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        value={password}
        type={isVisiblePassword ? "text" : "password"}
        placeholder="Придумайте пароль"
        className="createAndChangePasswordForm__field"
        isError={passwordValidator.isDirty && !passwordValidator.isValid}
        helperText={createHelperTextFunc(passwordValidator)}
        onBlur={() => {
          passwordValidator.setIsDirty(true);
        }}
        afterSlot={
          <EyeBtnForPassword
            val={isVisiblePassword}
            setVal={setIsVisiblePassword}
          />
        }
      />
      <DropDown
        placeholder="Рубрикатор"
        helperText={createHelperTextFunc(catalogValidator)}
        choisesArr={catalogNamesArr.map((item) => {
          return {
            itemId: item.id,
            label: item.label,
          };
        })}
        setChickedArr={(val) => {
          setCatalog(Number(val[0].id));
          setCatalogName(val[0].label);
        }}
        className="createAndChangePasswordForm__dropdown"
        initialVal={
          catalog !== undefined
            ? { itemId: catalog, label: catalogName }
            : undefined
        }
      />
      <Checkbox
        checked={isAgree}
        setStatus={(v) => {
          setIsAgree(v);
        }}
      >
        Я ознакомлен и согласен с{" "}
        <NavLink
          to={"/"}
          className={"createAndChangePasswordForm__politicLink"}
        >
          Политикой конфиденциальности
        </NavLink>
      </Checkbox>
      <Button
        theme="with_border"
        className="createAndChangePasswordForm__btn"
        onClick={() => {
          setPassword(generatePasswordFunc());
        }}
      >
        Сгенерировать пароль <ThreeStarsIcon />
      </Button>
      <div className="createAndChangePasswordForm__btnWrap">
        <Button
          theme="clear"
          className="createAndChangePasswordForm__btn_cancel createAndChangePasswordForm__btn"
          onClick={() => {
            if (onClickCancelFunc) onClickCancelFunc();
          }}
        >
          {formType === "create" ? "Отменить" : "Назад"}
        </Button>
        {formType === "create" ? (
          <CreatePasswordButton
            passwordItemInfo={{
              name,
              url: siteURL,
              catalogue_id: catalog,
              login,
              password,
            }}
            className="createAndChangePasswordForm__btn_submit createAndChangePasswordForm__btn"
            disabled={generalValid}
            onClick={() => {
              if (onClickSubmitFunc) onClickSubmitFunc();
            }}
          />
        ) : (
          passwordItem && (
            <UpdatePasswordButton
              passwordItem={{
                id: passwordItem.id,
                name,
                url: siteURL,
                catalogue_id: catalog,
                login,
                password,
              }}
              className="createAndChangePasswordForm__btn_submit createAndChangePasswordForm__btn"
              disabled={generalValid}
              onClick={() => {
                if (onClickSubmitFunc) onClickSubmitFunc();
              }}
            />
          )
        )}
      </div>
    </form>
  );
};
