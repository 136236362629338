import React from "react";
import "./style.scss";
import { PenEditIcon } from "../Icons/EyeIcon/PenEditIcon";
import { DeleteIcon } from "../Icons/DeleteIcon";
import { PasswordItem } from "Entities/Password";
import { FileItem } from "Entities/File";

interface Props {
  item: PasswordItem | FileItem;
  editItem?: () => void;
  deleteItem?: () => void;
  handleActiveItem: () => void;
  activeId?: number | null;
  isPassword: boolean;
}

export const ItemPasswordAndFile = ({
  item,
  editItem,
  deleteItem,
  handleActiveItem,
  activeId,
  isPassword = true,
}: Props) => {
  const reTurnPassowrd = (item: PasswordItem | FileItem) => {
    if (isPassword) {
      return (
        <div className="ItemPasswordAndFile__content">
          <div className="ItemPasswordAndFile__textInfo">
            <h2 className="ItemPasswordAndFile__title">{item.name}</h2>
          </div>
        </div>
      );
    } else {
      const file = item as FileItem;
      return (
        <div className="ItemPasswordAndFile__content">
          <div className="ItemPasswordAndFile__textInfo">
            <h2 className="ItemPasswordAndFile__title">{file.name}</h2>
            <p className="ItemPasswordAndFile__fileName">
              {file.name + "." + file?.extension}
            </p>
          </div>
        </div>
      );
    }
  };
  return (
    <div
      className={`ItemPasswordAndFile ${
        activeId === item.id && "ItemPasswordAndFile-active"
      }`}
      onClick={handleActiveItem}
    >
      {reTurnPassowrd(item)}
      <div
        className={
          "ItemPasswordAndFile__icons" +
          (activeId === item.id ? " ItemPasswordAndFile__icons_show" : "")
        }
      >
        <PenEditIcon onClick={editItem} />
        <DeleteIcon onClick={deleteItem} />
      </div>
    </div>
  );
};
