import { QRSendItem, QRSlice } from "Entities/QR";
import { QRService } from "../../API/services";
import { AppDispatch } from "Shared/Model";
import axios from "axios";

export const sendQRActionCreator =
  (qrItem: QRSendItem, type?: "password" | "file") =>
  async (dispatch: AppDispatch) => {
    const { onFetchQR, onFetchQRError, onChengeStatusMessageQR } =
      QRSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageQR({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageQR(null));
      }, 3000);
    };

    try {
      dispatch(onFetchQR());
      const response = await QRService.send(qrItem, type);
      console.log("sendQRActionCreator response", response);

      if (type === "file") {
        onChengeStatusMessage("success", "Файл успешно передан");
      } else {
        onChengeStatusMessage("success", "Пароль успешно передан");
      }
      return response.data.status === "success";
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchQRError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChengeStatusMessage("error", "Ошибка сканирования QR");
      } else {
        dispatch(
          onFetchQRError({
            message: "Неизвестная ошибка сканирования QR",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage("error", "Ошибка сканирования QR");
      }
      return false;
    }
  };
