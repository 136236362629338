import { AxiosResponse } from "axios";
import { FileDownloadResponse } from "Entities/File";
import { $api, urlEndpoints } from "Shared/API";

export class FileService {
  static async downloadFile(
    fileId: number
  ): Promise<AxiosResponse<FileDownloadResponse>> {
    return $api.get<FileDownloadResponse>(urlEndpoints.FILE.getLink, {
      params: {
        id: fileId,
      },
    });
  }
}
