import { Button, ListItem, PickerSigment } from "Shared/UI";
import React, { useState } from "react";
import "./style.scss";
import { CatalogItem } from "Entities/Catalog";
import classNames from "classnames";
import { SearchInput } from "Features/SearchInput";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";
import { FileCatalogItem } from "Entities/FileCatalog";
import { CATALOG_TYPE } from "Shared/Types";

interface Props {
  className?: string;
  catalogType: CATALOG_TYPE;
  catalogChoisen: number | null;
  setChoisenCatalog: (e: number) => void;
  showPassowrdsOrFiles: "left" | "right";
  setShowPasswordsOrFiles: (v: "left" | "right") => void;
  catalogList: CatalogItem[] | FileCatalogItem[];
  onClickNewCatalogBtn?: () => void;
  onClickUpdateCatalog?: (v: CatalogItem | FileCatalogItem) => void;
  onClickDeleteCatalog?: (v: CatalogItem | FileCatalogItem) => void;
}

export const CatalogList = ({
  className,
  catalogType,
  catalogChoisen,
  setChoisenCatalog,
  setShowPasswordsOrFiles,
  showPassowrdsOrFiles,
  catalogList,
  onClickNewCatalogBtn,
  onClickUpdateCatalog,
  onClickDeleteCatalog,
}: Props) => {
  const [filterList, setFilterList] = useState<
    CatalogItem[] | FileCatalogItem[]
  >(catalogList);

  const navigate = useNavigate();
  const handleAdd = () => {
    const endpoint =
      catalogType === CATALOG_TYPE.FILES
        ? routeEndpoint.addFile
        : routeEndpoint.addPassword;
    navigate("/" + endpoint);
  };

  const handleActiveItemId = (id: number) => {
    setChoisenCatalog(id);
  };
  const classNameList = classNames("catalogList", className);
  return (
    <div className={classNameList}>
      <div className="catalogList__head">
        <h1 className="catalogList__title">Каталог</h1>
        <PickerSigment
          choisenSigment={showPassowrdsOrFiles}
          setChoisenSigment={setShowPasswordsOrFiles}
          labeLeftSigment="Пароли"
          labelRightSigment="Файлы"
          className="catalogList__pickerSigment"
        />
        <SearchInput<CatalogItem | FileCatalogItem>
          allItems={catalogList}
          setFilterListItem={setFilterList}
          placeholder="Поиск по каталогу..."
        />
        <div className="catalogList__list">
          {Array.isArray(filterList) &&
            filterList.map((e) => (
              <ListItem
                label={e.name}
                key={e.id}
                isActive={catalogChoisen === e.id}
                onClick={() => handleActiveItemId(e.id)}
                editITem={() => {
                  if (onClickUpdateCatalog) onClickUpdateCatalog(e);
                }}
                deleteItem={() => {
                  if (onClickDeleteCatalog) onClickDeleteCatalog(e);
                }}
              />
            ))}
        </div>
      </div>
      <div className="catalogList__buttonsToAdd">
        <Button onClick={onClickNewCatalogBtn}>Новый раздел каталога</Button>
        <Button
          theme="with_border"
          className="catalogList__btnToAddSite"
          onClick={handleAdd}
          disabled={Array.isArray(catalogList) && catalogList.length === 0}
        >
          {catalogType === CATALOG_TYPE.PASSWORDS
            ? "Добавить сайт"
            : "Добавить файл"}
        </Button>
      </div>
    </div>
  );
};
