import React from "react";
import "./style.scss";
import { Button } from "Shared/UI";
import { useAppDispatch } from "Shared/Model";
import { logout } from "Features/Logout/Model/Store/actionCreator";
import { useNavigate } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";

interface Props {
  className?: string;
  isShowForm: boolean;
  setShowingForm: (v: boolean) => void;
}
export const LogoutQuestionForm = ({
  className,
  isShowForm,
  setShowingForm,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await dispatch(logout());
    if (result) {
      await navigate("/" + routeEndpoint.login);
    }
  };

  const cancleShowQuestionForm = () => {
    setShowingForm(false);
  };
  return (
    <form
      className={`logoutQuestionForm ${
        !isShowForm ? "logoutQuestionForm_hidden" : ""
      } ${className && className}`}
    >
      <div className="logoutQuestionForm__container">
        <h2 className="logoutQuestionForm__head">
          Вы действительно хотите
          <br />
          выйти из аккаунта?
        </h2>
        <hr className="logoutQuestionForm__delimiter" />
        <div className="logoutQuestionForm__btns">
          <Button theme="clear" type="button" onClick={cancleShowQuestionForm}>
            Нет
          </Button>
          <Button theme="bright" type="button" onClick={onSubmit}>
            Да
          </Button>
        </div>
      </div>
    </form>
  );
};
