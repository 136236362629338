import { QRSendItem, QRSendResponse } from "Entities/QR";
import { $api, urlEndpoints } from "Shared/API";
import { AxiosResponse } from "axios";

export class QRService {
  static async send(
    qrSendItem: QRSendItem,
    type?: "password" | "file"
  ): Promise<AxiosResponse<QRSendResponse>> {
    const url =
      type && type === "file"
        ? urlEndpoints.QR.sendFile +
          `?id=${
            qrSendItem.id
          }&password_id=${qrSendItem.password_id.toString()}&security_key=${
            qrSendItem.security_key
          }`
        : urlEndpoints.QR.send;
    if (type && type === "file") {
      return $api.put<QRSendResponse, AxiosResponse<QRSendResponse>>(url);
    }

    return $api.put<QRSendResponse, AxiosResponse<QRSendResponse>>(url, {
      password_id: qrSendItem.password_id.toString(),
      id: qrSendItem.id,
      security_key: qrSendItem.security_key,
    });
  }
}
