import { AxiosResponse } from "axios";
import { PasswordItem, PasswordResponse } from "Entities/Password";
import { $api, urlEndpoints } from "Shared/API";

export class PasswordService {
  static async update(
    passwordItem: PasswordItem
  ): Promise<AxiosResponse<PasswordResponse>> {
    return $api.put<PasswordResponse>(
      urlEndpoints.PASSWORD.update,
      passwordItem
    );
  }
}
