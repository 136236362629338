import { useAppDispatch } from "Shared/Model";
import { Button } from "Shared/UI";
import React from "react";
import { updateFile } from "../Model/Store/actionCreator";
import { FileRedactRequest } from "Entities/File";

interface UpdateFileButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  fileItem: FileRedactRequest;
}

export const UpdateFileButton = ({
  className,
  fileItem,
  onClick,
  ...props
}: UpdateFileButtonProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await dispatch(updateFile(fileItem));
    if (onClick && result) onClick(e);
  };
  return (
    <Button
      {...props}
      theme="bright"
      type="button"
      onClick={onSubmit}
      className={className}
    >
      Сохранить
    </Button>
  );
};
