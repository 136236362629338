import { useAppDispatch } from "Shared/Model";
import {
  Button,
  DelimiterForm,
  EyeBtnForPassword,
  Input,
  Modal,
} from "Shared/UI";
import React from "react";
import { DeleteAccountActionCreator } from "../Model/Store/actionCreator";
import "./style.scss";
import { useNavigate } from "react-router-dom";

interface DeleteAccountButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const DeleteAccountButton = ({ ...props }: DeleteAccountButtonProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showApproveForm, setShowApproveForm] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    e.preventDefault();
    const result = await dispatch(DeleteAccountActionCreator(currentPassword));
    if (result) {
      navigate("/");
      window.location.reload();
    }
    setLoading(false);
  };
  return (
    <>
      <Modal isShow={showDeleteModal} setIsShow={setShowDeleteModal}>
        <form className="deleteAccountForm">
          <div className="deleteAccountForm__text">
            <h3 className="deleteAccountForm__head">
              Вы действительно хотите удалить аккаунт?
            </h3>
            <p className="deleteAccountForm__description">
              Сервис не предусматривает возможность восстановления учетной
              записи. Удаление приведет к полной потери личных данных.
            </p>
          </div>
          <DelimiterForm />
          <div className="deleteAccountForm__btnWrap">
            <Button
              theme="clear"
              onClick={() => setShowDeleteModal(false)}
              type="button"
            >
              Нет
            </Button>
            <Button
              theme="bright"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setShowDeleteModal(false);
                setShowApproveForm(true);
              }}
              type="submit"
            >
              Да
            </Button>
          </div>
        </form>
      </Modal>
      <Modal isShow={showApproveForm} setIsShow={setShowApproveForm}>
        <form className="deleteAccountForm">
          <div className="deleteAccountForm__text">
            <h3 className="deleteAccountForm__head">
              Для подтверждения удаления аккаунта введите пароль
            </h3>
          </div>
          <DelimiterForm />
          <Input
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            type={isShowPassword ? "text" : "password"}
            afterSlot={
              <EyeBtnForPassword
                className="loginForm__eyeBtnForPassword"
                val={isShowPassword}
                setVal={setIsShowPassword}
              />
            }
            placeholder="Текущий пароль"
          />
          <div className="deleteAccountForm__btnWrap">
            <Button
              theme="clear"
              onClick={() => setShowApproveForm(false)}
              type="button"
            >
              Назад
            </Button>
            <Button
              disabled={loading}
              theme="bright"
              onClick={onSubmit}
              type="submit"
            >
              Далее
            </Button>
          </div>
        </form>
      </Modal>
      <Button
        {...props}
        theme="clear"
        type="button"
        onClick={() => setShowDeleteModal(true)}
        className={"deleteAccountButton"}
      >
        Удалить акаунт
      </Button>
    </>
  );
};
