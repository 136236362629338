import { PasswordsResponse } from "Entities/Password";
import { $api, urlEndpoints } from "Shared/API";
import { AxiosResponse } from "axios";

export class PasswordService {
  static async fetchAll(
    catalogId: number
  ): Promise<AxiosResponse<PasswordsResponse>> {
    return $api.get<PasswordsResponse>(
      urlEndpoints.PASSWORD.fetchAll + "?catalogue_id=" + catalogId
    );
  }
}
