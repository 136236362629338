import React from "react";
import "./style.scss";
import classNames from "classnames";

interface CheckboxProps {
  checked?: boolean;
  size?: "large" | "medium" | "small";
  setStatus?: (status: boolean) => void;
  children?: React.ReactNode;
}

export const Checkbox = ({
  checked,
  size = "medium",
  setStatus,
  children,
  ...props
}: CheckboxProps) => {
  const checkboxClassNames = classNames("checkbox", `checkbox_${size}`);

  const handleStatusChange = () => {
    if (checked) {
      setStatus?.(false);
    } else {
      setStatus?.(true);
    }
  };

  return (
    <label className={checkboxClassNames}>
      <input
        type="checkbox"
        className="checkbox__input"
        checked={checked}
        onChange={handleStatusChange}
        {...props}
      />
      {children}
    </label>
  );
};
