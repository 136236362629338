import React from "react";

export const DropDownItem = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.5C0 1.80964 0.559645 1.25 1.25 1.25H18.75C19.4404 1.25 20 1.80964 20 2.5V5C20 5.69036 19.4404 6.25 18.75 6.25V15.625C18.75 17.3509 17.3509 18.75 15.625 18.75H4.375C2.64911 18.75 1.25 17.3509 1.25 15.625V6.25C0.559644 6.25 0 5.69036 0 5V2.5ZM2.5 6.25V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625V6.25H2.5ZM18.75 2.5H1.25V5H18.75V2.5ZM6.25 9.375C6.25 9.02982 6.52982 8.75 6.875 8.75H13.125C13.4702 8.75 13.75 9.02982 13.75 9.375C13.75 9.72018 13.4702 10 13.125 10H6.875C6.52982 10 6.25 9.72018 6.25 9.375Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};
