import React, { useState } from "react";
import "./style.scss";
import { CatalogItem } from "Entities/Catalog";
import { Button, DelimiterForm, Input } from "Shared/UI";
import { CreateCatalogButton } from "Features/CreateCatalog";
import { UpdateCatalogButton } from "Features/UpdateCatalog";
import { useValidationFieldForm } from "Shared/Lib";
import { useAppSelector } from "Shared/Model";

type CreateAndChangeCatalogFormProps = (
  | {
      catalogItem: CatalogItem;
      formType?: "change";
    }
  | {
      catalogItem?: undefined;
      formType?: "create";
    }
) & {
  onClickCancelFunc?: () => void;
  onClickSubmitFunc?: () => void;
} & React.FormHTMLAttributes<HTMLFormElement>;

export const CreateAndChangeCatalogForm = ({
  catalogItem,
  formType = "create",
  className,
  onClickCancelFunc,
  onClickSubmitFunc,
  ...props
}: CreateAndChangeCatalogFormProps) => {
  const [name, setName] = useState(catalogItem ? catalogItem.name : "");

  const CatalogState = useAppSelector((state) => state.catalogs);
  const AuthState = useAppSelector((state) => state.auth);

  const catalogArr =
    formType === "change" && Array.isArray(CatalogState.catalogItems)
      ? CatalogState.catalogItems.filter((item) => {
          return item.id !== catalogItem?.id;
        })
      : CatalogState.catalogItems;

  const catalogNamesArr: string[] = Array.isArray(catalogArr)
    ? catalogArr.map((item) => {
        return item.name;
      })
    : [];

  const nameValidator = useValidationFieldForm({
    value: name.trim(),
    options: {
      required: true,
      isNotIncludedInTheList: catalogNamesArr,
      maxLength: 256,
    },
    messageText: {
      required: "Название каталога не должно быть пустым",
      isNotIncludedInTheList: "Данный каталог уже существует",
      maxLength: "Имя каталога не должно состоять больше чем из 256 символов",
    },
  });

  const helperText =
    nameValidator.isDirty &&
    Object.values(nameValidator.message).find((item) => {
      return item !== "";
    });

  const onClickSubmit = () => {
    if (onClickSubmitFunc) onClickSubmitFunc();
    setName("");
    nameValidator.setIsDirty(false);
  };

  return (
    <form
      {...props}
      className={
        "createAndChangeCatalogForm" + (className ? " " + className : "")
      }
    >
      <h2 className="createAndChangeCatalogForm__head">
        {formType === "create"
          ? "Введите название каталога"
          : "Данные о каталоге"}
      </h2>
      <DelimiterForm />
      <Input
        onChange={(e) => {
          setName(e.target.value);
          nameValidator.setIsDirty(true);
        }}
        value={name}
        placeholder="Социальные сети..."
        className="createAndChangeCatalogForm__field"
        isError={!!helperText}
        helperText={helperText}
      />
      <div className="createAndChangeCatalogForm__btnWrap">
        <Button
          theme="clear"
          className="createAndChangeCatalogForm__btn_cancel createAndChangeCatalogForm__btn"
          onClick={() => {
            if (onClickCancelFunc) onClickCancelFunc();
          }}
        >
          {formType === "create" ? "Отменить" : "Назад"}
        </Button>
        {formType === "create" ? (
          <CreateCatalogButton
            name={name}
            className="createAndChangeCatalogForm__btn_submit createAndChangeCatalogForm__btn"
            disabled={!nameValidator.isValid}
            onClick={onClickSubmit}
          />
        ) : (
          catalogItem &&
          AuthState.userItem && (
            <UpdateCatalogButton
              catalogItem={{
                id: catalogItem.id,
                name,
                uer_id: AuthState.userItem.id,
              }}
              className="createAndChangeCatalogForm__btn_submit createAndChangeCatalogForm__btn"
              disabled={!nameValidator.isValid}
              onClick={onClickSubmit}
            />
          )
        )}
      </div>
    </form>
  );
};
