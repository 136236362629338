import React from "react";

interface QRFocusIconProps extends React.SVGProps<SVGSVGElement> {
  width?: string;
  height?: string;
  color?: string;
}

export const QRFocusIcon = ({
  width = "18",
  height = "18",
  color = "currentColor",
  ...props
}: QRFocusIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V13C0 12.7167 0.0958333 12.4792 0.2875 12.2875C0.479167 12.0958 0.716667 12 1 12C1.28333 12 1.52083 12.0958 1.7125 12.2875C1.90417 12.4792 2 12.7167 2 13V16H5C5.28333 16 5.52083 16.0958 5.7125 16.2875C5.90417 16.4792 6 16.7167 6 17C6 17.2833 5.90417 17.5208 5.7125 17.7125C5.52083 17.9042 5.28333 18 5 18H2ZM16 18H13C12.7167 18 12.4792 17.9042 12.2875 17.7125C12.0958 17.5208 12 17.2833 12 17C12 16.7167 12.0958 16.4792 12.2875 16.2875C12.4792 16.0958 12.7167 16 13 16H16V13C16 12.7167 16.0958 12.4792 16.2875 12.2875C16.4792 12.0958 16.7167 12 17 12C17.2833 12 17.5208 12.0958 17.7125 12.2875C17.9042 12.4792 18 12.7167 18 13V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18ZM0 5V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H5C5.28333 0 5.52083 0.0958333 5.7125 0.2875C5.90417 0.479167 6 0.716667 6 1C6 1.28333 5.90417 1.52083 5.7125 1.7125C5.52083 1.90417 5.28333 2 5 2H2V5C2 5.28333 1.90417 5.52083 1.7125 5.7125C1.52083 5.90417 1.28333 6 1 6C0.716667 6 0.479167 5.90417 0.2875 5.7125C0.0958333 5.52083 0 5.28333 0 5ZM16 5V2H13C12.7167 2 12.4792 1.90417 12.2875 1.7125C12.0958 1.52083 12 1.28333 12 1C12 0.716667 12.0958 0.479167 12.2875 0.2875C12.4792 0.0958333 12.7167 0 13 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V5C18 5.28333 17.9042 5.52083 17.7125 5.7125C17.5208 5.90417 17.2833 6 17 6C16.7167 6 16.4792 5.90417 16.2875 5.7125C16.0958 5.52083 16 5.28333 16 5ZM9 12C8.16667 12 7.45833 11.7083 6.875 11.125C6.29167 10.5417 6 9.83333 6 9C6 8.16667 6.29167 7.45833 6.875 6.875C7.45833 6.29167 8.16667 6 9 6C9.83333 6 10.5417 6.29167 11.125 6.875C11.7083 7.45833 12 8.16667 12 9C12 9.83333 11.7083 10.5417 11.125 11.125C10.5417 11.7083 9.83333 12 9 12Z"
        fill={color}
      />
    </svg>
  );
};
