import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, DelimiterForm, NotificationBox } from "Shared/UI";
import { FileComponent } from "Shared/UI/FileComponent/UI/ui";
import { DownloadFileButton } from "Features/DownloadFile";
import { formatDateToDDMMYYYY } from "Shared/Lib";
import { FileItem } from "Entities/File";

interface DisplayDataAboutFileProps {
  className?: string;
  readyBtnFunc: () => void;
  iconSlot?: React.ReactNode;
  titleForm?: string;
  labelBtn?: string;
  createDate: string;
  gapForm?: "32" | "16";
  currentFile: FileItem;
}

export const DisplayDataAboutFile = ({
  className,
  createDate,
  currentFile,
  titleForm = "Данные получены",
  labelBtn = "Готово!",
  gapForm = "32",
  readyBtnFunc,
}: DisplayDataAboutFileProps) => {
  const [textForCopy, setTextForCopy] = useState<string>("");
  useEffect(() => {
    if (textForCopy !== "") {
      setTimeout(() => {
        setTextForCopy("");
      }, 3000);
    }
  }, [textForCopy]);

  return (
    <>
      {textForCopy !== "" && (
        <NotificationBox
          className="displayDataAboutFile__notification"
          content={`Строка ${textForCopy} успешно скопирована в буфер обмена`}
          correct={true}
        />
      )}
      <section
        className={
          "displayDataAboutFile" +
          (className ? " " + className : "") +
          (" displayDataAboutFile_gapForm" + gapForm)
        }
      >
        <div className="displayDataAboutFile__headWrap">
          <h2 className="displayDataAboutFile__head">{titleForm}</h2>
        </div>
        <DelimiterForm />
        <div className="displayDataAboutFile__data">
          <div className="displayDataAboutFile__info">
            <div className="displayDataAboutFile__fileInfoWrap">
              <div className="displayDataAboutFile__fileInfo">
                <p className="displayDataAboutFile__fileName">Название</p>
                <p className="displayDataAboutFile__createDate">
                  дата загрузки
                </p>
              </div>
            </div>
            <div className="displayDataAboutFile__fileInfo">
              <p className="displayDataAboutFile__fileName">
                {currentFile?.name}
              </p>
              <p className="displayDataAboutFile__createDate">
                {formatDateToDDMMYYYY(createDate)}
              </p>
            </div>
          </div>
          <div className="displayDataAboutFile__fileWrap">
            <FileComponent
              fileSize={currentFile?.size}
              fileName={currentFile?.name + "." + currentFile?.extension}
            />
          </div>
        </div>
        <DelimiterForm />
        <Button
          theme="bright"
          className="displayDataAboutFile__readyBtn"
          onClick={readyBtnFunc}
        >
          {labelBtn}
        </Button>
        {currentFile && <DownloadFileButton id={currentFile?.id} />}
      </section>
    </>
  );
};
