import React from "react";

interface ThreeStarsIconProps extends React.SVGProps<SVGSVGElement> {
  width?: string;
  height?: string;
  color?: string;
}

export const ThreeStarsIcon = ({
  width = "20",
  height = "20",
  color = "currentColor",
  ...props
}: ThreeStarsIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2026_4312)">
        <path
          d="M11.646 5.33848C9.22157 4.83023 7.32661 2.93527 6.81844 0.51082C6.75602 0.213203 6.49352 0 6.18934 0C5.88516 0 5.62266 0.213203 5.56028 0.510859C5.05207 2.93527 3.15711 4.8302 0.7327 5.33836C0.435044 5.40074 0.221802 5.66328 0.221802 5.96742C0.221802 6.27156 0.435005 6.5341 0.7327 6.59648C3.15708 7.10473 5.05196 8.99965 5.56016 11.4241C5.62254 11.7218 5.88504 11.935 6.18922 11.935C6.49336 11.935 6.7559 11.7218 6.81829 11.4241C7.32653 8.99969 9.22153 7.10477 11.6459 6.59664C11.9436 6.53426 12.1568 6.27176 12.1568 5.96758C12.1568 5.6634 11.9436 5.40086 11.646 5.33848ZM6.18926 9.34895C5.4602 7.88551 4.27126 6.69652 2.80786 5.96746C4.27129 5.2384 5.46028 4.04945 6.18934 2.58602C6.9184 4.04949 8.10735 5.23844 9.57075 5.96754C8.10731 6.6966 6.91833 7.88551 6.18926 9.34895Z"
          fill={color}
        />
        <path
          d="M10.5835 15.9564C9.4412 15.717 8.54838 14.8241 8.30889 13.6818C8.24651 13.3841 7.98401 13.1709 7.67983 13.1709C7.37569 13.1709 7.11315 13.3841 7.05077 13.6818C6.81127 14.8241 5.91842 15.7169 4.77616 15.9563C4.4785 16.0187 4.26526 16.2813 4.26526 16.5854C4.26526 16.8895 4.47846 17.1521 4.77616 17.2145C5.91838 17.4539 6.81124 18.3468 7.05069 19.4891C7.11307 19.7867 7.37557 20 7.67975 20C7.98389 20 8.24643 19.7868 8.30881 19.4891C8.54831 18.3468 9.44116 17.4539 10.5835 17.2145C10.8811 17.1521 11.0944 16.8896 11.0944 16.5854C11.0944 16.2813 10.8812 16.0188 10.5835 15.9564ZM7.67979 17.7345C7.37479 17.2811 6.98416 16.8905 6.53084 16.5855C6.98424 16.2804 7.37479 15.8898 7.67979 15.4365C7.98483 15.8899 8.37541 16.2805 8.82874 16.5855C8.37541 16.8905 7.98479 17.2811 7.67979 17.7345Z"
          fill={color}
        />
        <path
          d="M19.2673 10.0052C17.7233 9.68156 16.5166 8.47477 16.1929 6.93082C16.1305 6.63316 15.868 6.41992 15.5639 6.41992C15.2597 6.41992 14.9972 6.63309 14.9348 6.93078C14.6111 8.47477 13.4044 9.68152 11.8604 10.0052C11.5627 10.0675 11.3495 10.3301 11.3495 10.6342C11.3495 10.9384 11.5627 11.2009 11.8604 11.2633C13.4043 11.587 14.6111 12.7937 14.9347 14.3377C14.9971 14.6354 15.2596 14.8486 15.5638 14.8486C15.8679 14.8486 16.1305 14.6354 16.1928 14.3377C16.5166 12.7937 17.7233 11.587 19.2673 11.2634C19.5649 11.2009 19.7782 10.9384 19.7782 10.6343C19.7782 10.3302 19.565 10.0676 19.2673 10.0052ZM15.5639 12.4612C15.1164 11.7104 14.4877 11.0817 13.737 10.6343C14.4877 10.1868 15.1164 9.5582 15.5639 8.80738C16.0114 9.5582 16.64 10.1868 17.3907 10.6343C16.64 11.0818 16.0113 11.7104 15.5639 12.4612Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2026_4312">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
