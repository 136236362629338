import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Layout } from "App/UI/Layout/ui";
import { LoginPage } from "Page/LoginPage";
import { MainPage } from "Page/MainPage";
import { ProfilePage } from "Page/ProfilePage";
import { RegistrationPage } from "Page/RegistrationPage";
import { routeEndpoint } from "Shared/Routes";
import { TrustPage } from "Page/TrustPage";
import { NotTrustPage } from "Page/NotTrustPage";
import { CatalogPage } from "Page/CatalogPage";
import { AddPasswordPage } from "Page/AddPasswordPage";
import { AddFilePage } from "Page/AddFilePage";
import { UpdatePasswordPage } from "Page/UpdatePasswordPage";

import { ChangePersonalNamePage } from "Page/ChangePersonalNamePage";
import { ChangeGmailPage } from "Page/ChangeGmailPage";
import { ChangePersonalPasswordPage } from "Page/ChangePersonalPasswordPage";

import { ScannerQRPage } from "Page/ScannerQRPage";

import { UpdateFilePage } from "Page/UpdateFilePage";

import { LayoutLoader } from "App/UI/Layout/loader";
import { IsAuth, IsNotAuth } from "Features/IsAuth";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: routeEndpoint.notTrust,
        loader: LayoutLoader,
        element: (
          <IsNotAuth>
            <NotTrustPage />
          </IsNotAuth>
        ),
      },
      {
        path: routeEndpoint.main,
        loader: LayoutLoader,
        element: (
          <IsNotAuth>
            <MainPage />
          </IsNotAuth>
        ),
      },
      {
        path: routeEndpoint.selectAuth,
        loader: LayoutLoader,
        element: (
          <IsNotAuth>
            <TrustPage />
          </IsNotAuth>
        ),
      },
      {
        path: routeEndpoint.login,
        loader: LayoutLoader,
        element: (
          <IsNotAuth delay={3000}>
            <LoginPage />
          </IsNotAuth>
        ),
      },
      {
        path: routeEndpoint.registration,
        loader: LayoutLoader,
        element: (
          <IsNotAuth delay={3000}>
            <RegistrationPage />
          </IsNotAuth>
        ),
      },
      {
        path: routeEndpoint.profile,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <ProfilePage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.catalog,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <CatalogPage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.addPassword,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <AddPasswordPage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.addFile,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <AddFilePage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.updatePassword,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <UpdatePasswordPage />
          </IsAuth>
        ),
      },

      {
        path: routeEndpoint.updateFile,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <UpdateFilePage />
          </IsAuth>
        ),
      },

      {
        path: routeEndpoint.updateName,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <ChangePersonalNamePage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.updateEmail,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <ChangeGmailPage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.updatePersonalPassword,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <ChangePersonalPasswordPage />
          </IsAuth>
        ),
      },
      {
        path: routeEndpoint.scannerQR,
        loader: LayoutLoader,
        element: (
          <IsAuth>
            <ScannerQRPage />
          </IsAuth>
        ),
      },
    ],
  },
]);
