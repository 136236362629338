import { useAppDispatch } from "Shared/Model";
import "./style.scss";
import { Button, DelimiterForm } from "Shared/UI";
import React from "react";
import { deletePassword } from "../Model/Store/actionCreator";

interface CreatePasswordButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  passwordId: number;
  cancelFunc?: () => void;
  submitFunc?: () => void;
}

export const DeletePasswordForm = ({
  className,
  passwordId,
  cancelFunc,
  submitFunc,
  ...props
}: CreatePasswordButtonProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await dispatch(deletePassword(passwordId));
    if (submitFunc && result) submitFunc();
  };

  const onClickNoDelFunc = () => {
    if (cancelFunc) cancelFunc();
  };
  return (
    <form className={"deletePasswordForm" + (className ? " " + className : "")}>
      <h2 className="deletePasswordForm__head">
        Вы действительно хотите удалить данные?
      </h2>
      <DelimiterForm />
      <div className="deletePasswordForm__btnWrap">
        <Button className="deletePasswordForm__btn" onClick={onClickNoDelFunc}>
          Нет
        </Button>
        <Button
          {...props}
          theme="bright"
          type="button"
          onClick={onSubmit}
          className={"deletePasswordForm__btn"}
        >
          Да
        </Button>
      </div>
    </form>
  );
};
