import React from "react";

interface Props {
    onClick?: () => void
}
export const DeleteIcon = ({onClick}: Props) => {
    return(
        <svg onClick={onClick} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.30775 17.5C2.80908 17.5 2.38308 17.3234 2.02975 16.9702C1.67658 16.6169 1.5 16.1909 1.5 15.6922V2.99998H0.5V1.49998H5V0.615479H11V1.49998H15.5V2.99998H14.5V15.6922C14.5 16.1974 14.325 16.625 13.975 16.975C13.625 17.325 13.1974 17.5 12.6923 17.5H3.30775ZM13 2.99998H3V15.6922C3 15.7821 3.02883 15.8558 3.0865 15.9135C3.14417 15.9711 3.21792 16 3.30775 16H12.6923C12.7692 16 12.8398 15.9679 12.9038 15.9037C12.9679 15.8397 13 15.7692 13 15.6922V2.99998ZM5.404 14H6.90375V4.99998H5.404V14ZM9.09625 14H10.596V4.99998H9.09625V14Z" fill="#A4A4A4"/>
        </svg>
    )
}