import Cookies from "js-cookie";
import axios, { AxiosError } from "axios";
import { useAppDispatch } from "Shared/Model";
import { $api, API_URL, urlEndpoints } from "Shared/API";
import { AuthResponse } from "Entities/User";
import { checkAuthAction } from "./Store/actionCreator";

export const checkAuth = async (
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  $api.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error instanceof AxiosError &&
        error.response?.status == 401 &&
        originalRequest &&
        !originalRequest._isRetry
      ) {
        originalRequest._isRetry = true;
        try {
          const response = await axios.get<AuthResponse>(
            urlEndpoints.AUTH.refresh,
            {
              withCredentials: true,
              baseURL: API_URL,
            }
          );
          Cookies.set("token", response.data.authorisation.token);
          return $api.request(originalRequest);
        } catch (e) {
          if (error instanceof AxiosError && error.response?.status == 401) {
            console.log("Закончилось действие refresh token", e);
          } else {
            console.log("НЕ АВТОРИЗОВАН по не понятным причинам", e);
          }
        }
      }
      throw error;
    }
  );
  if (Cookies.get("token")) {
    return await dispatch(checkAuthAction());
  }
  return false;
};
