import { AxiosResponse } from "axios";
import { CatalogResponse } from "Entities/Catalog";
import { $api, urlEndpoints } from "Shared/API";
import { CATALOG_TYPE } from "Shared/Types";

export class CatalogService {
  static async delete(
    id: number,
    catalogType: CATALOG_TYPE
  ): Promise<AxiosResponse<CatalogResponse>> {
    return $api.delete<CatalogResponse>(
      catalogType === CATALOG_TYPE.FILES
        ? urlEndpoints.FILE_CATALOG.delete
        : urlEndpoints.CATALOG.delete,
      {
        data: { id },
      }
    );
  }
}
