interface MinAndMaxLength {
  text: string;
  params: number;
}
interface patternProps {
  params: RegExp;
  text: string;
}
interface Text {
  text: string;
}
interface formList {
  text: string;
  params: string[];
}

export const minLength = ({ text, params }: MinAndMaxLength): boolean => {
  return text.length >= params;
};

export const maxLength = ({ text, params }: MinAndMaxLength): boolean => {
  return text.length <= params;
};

export const isMatchPattern = ({ params, text }: patternProps) => {
  return params.test(text);
};

export const isContainCharacterFromList = ({ text, params }: formList) => {
  return params.some((char) => text.includes(char));
};

export const isEndWithStringFromList = ({
  text,
  params,
}: formList): boolean => {
  const lastLetterOfString = text.slice(-1);
  return params.includes(lastLetterOfString);
};

export const requiredFunc = (v: string) => {
  return v !== "";
};

export const isEmail = (v: string) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,255}\.[a-zA-Z]{2,}$/;
  return emailRegex.test(v);
};
export const isContainCapitalLetter = ({ text }: Text) => {
  const regexCapitalLetter = /^(?=.*[A-Z])/;
  return regexCapitalLetter.test(text);
};
export const isContainSmallLetter = ({ text }: Text) => {
  const regexSmallLetter = /^(?=.*[a-z])/;
  return regexSmallLetter.test(text);
};

export const isContainDigit = (v: string) => {
  return isContainCharacterFromList({
    text: v,
    params: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  });
};

export const isContainSpecCharacter = (v: string) => {
  return isContainCharacterFromList({
    text: v,
    params: [
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "_",
      "-",
      "+",
      "=",
      "'",
      "\\",
      "|",
      "(",
      ")",
      "{",
      "}",
      "[",
      "]",
      ":",
      `"`,
      `"`,
      "<",
      ">",
      ",",
      ".",
      "?",
      "/",
    ],
  });
};

export const isPassword = (password: string) => {
  const allowedCharactersRegex =
    /^[A-Za-z0-9!@#$%^&*_\-+='\\|(){}[\]:"<>,.?/]+$/;
  return allowedCharactersRegex.test(password);
};

export const isOnlyRussAndEngLetters = (v: string) => {
  const reg = /^[a-zA-Zа-яА-ЯёЁ-\s]+$/;
  return reg.test(v);
};
export const isNotIncludedInTheList = (v: string, list: string[]) => {
  const newList = Array.isArray(list)
    ? list.map((item) => {
        return item.toLocaleLowerCase().trim();
      })
    : [];
  return newList.reduce((sum, item) => {
    return sum && item !== v.toLocaleLowerCase().trim();
  }, true);
};
