import React, { useState } from "react";
import "./style.scss";
import { ChangePersonalNameAndEmailForm } from "Widgets/ChangePersonalNameAndEmailForm";
import { useNavigate } from "react-router-dom";
import { Modal } from "Shared/UI";
import { CancleEditingForm } from "Widgets/CancleEditingForm";
import { useAppSelector } from "Shared/Model";
import { routeEndpoint } from "Shared/Routes";

export const ChangePersonalNamePage = () => {
  const AuthState = useAppSelector((state) => state.auth);
  const [isShowMessegeDontSave, setIsShowMessegeDontSave] =
    useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <main className="changePersonalName">
      {AuthState.userItem && (
        <ChangePersonalNameAndEmailForm
          formType="name"
          userInfo={AuthState.userItem}
          isShow={isShowMessegeDontSave}
          setIsShow={setIsShowMessegeDontSave}
        />
      )}
      <Modal
        setIsShow={setIsShowMessegeDontSave}
        isShow={isShowMessegeDontSave}
      >
        <CancleEditingForm
          isShowForm={isShowMessegeDontSave}
          setShowingForm={setIsShowMessegeDontSave}
          onClickCancelFunc={() => {
            navigate("/" + routeEndpoint.profile);
          }}
        />
      </Modal>
    </main>
  );
};
