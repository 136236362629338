import { getAllCatalog } from "Features/GetAllCatalogs";
import { checkAuth } from "Features/Refresh";
import { store } from "Shared/Model";
import { LoaderFunction } from "react-router-dom";

export const LayoutLoader: LoaderFunction<boolean> = async () => {
  const authResult = await checkAuth(store.dispatch);
  if (authResult) {
    await getAllCatalog(store.dispatch, 0);
    await getAllCatalog(store.dispatch, 1);
  }
  return null;
};
