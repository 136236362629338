import React, { useLayoutEffect } from "react";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { RegistrationForm } from "Features/Registration";
import { NotificationBox } from "Shared/UI";
import { AuthSlice } from "Entities/User";

export const RegistrationPage = () => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const { onFetchAuthError } = AuthSlice.actions;
    dispatch(onFetchAuthError(null));
  }, []);
  return (
    <main className={"registrationPage"}>
      <h1 className="registrationPage__head">Регистрация</h1>
      {isAuth ? (
        <NotificationBox
          title={"Регистрация завершена"}
          content={"Добро пожаловать в сервис!"}
        />
      ) : (
        <>
          <RegistrationForm />
        </>
      )}
    </main>
  );
};
