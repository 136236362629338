import { Input } from "Shared/UI";
import React, { useState, useEffect } from "react";

interface Props<T> {
  allItems: T[];
  setFilterListItem: (items: T[]) => void;
  placeholder?: string;
}

export const SearchInput = <T extends { name: string }>({
  allItems,
  setFilterListItem,
  placeholder,
}: Props<T>) => {
  const [textToSearch, setTextToSearch] = useState<string>("");

  useEffect(() => {
    const filteredItems = Array.isArray(allItems)
      ? allItems.filter((item) => {
          return item.name.toLowerCase().includes(textToSearch.toLowerCase());
        })
      : [];
    setFilterListItem(filteredItems);
  }, [textToSearch, allItems]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextToSearch(event.target.value);
  };

  return (
    <Input
      placeholder={placeholder}
      onChange={handleChange}
      value={textToSearch}
    />
  );
};
