import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { PersonalService } from "../../API/services";
import { AuthSlice, UserType } from "Entities/User";

export const updatePersonalInfoActionCreator =
  (userItem: Partial<UserType> | null) => async (dispatch: AppDispatch) => {
    const {
      onFetchAuth,
      onFetchAuthError,
      onChengeUserItem,
      onChengeStatusMessageAuth,
    } = AuthSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageAuth({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageAuth(null));
      }, 3000);
    };

    try {
      dispatch(onFetchAuth());
      if (userItem !== null) {
        await PersonalService.update({
          name: "",
          email: "",
          password: "",
          updated_at: "",
          created_at: "",
          ...userItem,
        });
        dispatch(onChengeUserItem(userItem));
      } else {
        dispatch(onChengeUserItem(null));
      }
      onChengeStatusMessage("success", "Изменения успешно сохранены");
      return true;
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchAuthError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
      } else {
        dispatch(
          onFetchAuthError({
            message: "Неизвестная ошибка обновления персональных данных",
            errorCode: "unknownError",
          })
        );
      }
      onChengeStatusMessage(
        "error",
        "Изменения не были сохранены, попробуйте позже"
      );
      return false;
    }
  };
