import React from "react";

interface Props {
  className?: string;
  onclick?: () => void
}
export const ArrowIcon = ({ className, onclick }: Props) => {
  return (
    <svg
      onClick={onclick}
      className={className}
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05896 8.92489L1.06366 2.07313C0.356465 1.2649 0.930437 0 2.00438 0H13.995C15.0689 0 15.6429 1.2649 14.9357 2.07313L8.9404 8.92489C8.44238 9.49405 7.55697 9.49405 7.05896 8.92489Z"
        fill="#A4A4A4"
      />
    </svg>
  );
};
