import { AxiosResponse } from "axios";
import { FileResponse } from "Entities/File";
import { $api, urlEndpoints } from "Shared/API";
import { FileCreateRequest } from "../Model/Type/CreateFileType";

export class FileService {
  static async create(
    fileItem: FileCreateRequest
  ): Promise<AxiosResponse<FileResponse>> {
    const formData = new FormData();
    if (
      !fileItem.file ||
      !fileItem.catalogue_id ||
      !fileItem.name ||
      !fileItem.extension
    ) {
      return Promise.reject(
        `${fileItem.file} ${fileItem.catalogue_id} ${fileItem.name} ${fileItem.extension}`
      );
    }
    formData.append("file", fileItem.file as Blob);
    formData.append("catalogue_id", fileItem.catalogue_id?.toString());
    formData.append("name", fileItem.name);
    formData.append("extension", fileItem.extension);
    return $api.post<FileResponse>(urlEndpoints.FILE.create, formData);
  }
}
