import React, { useEffect } from "react";
import "./style.scss";
import { Portal } from "../../Portal/UI/ui";

interface ModalProps {
  children: React.ReactNode;
  isShow: boolean;
  setIsShow: (val: boolean) => void;
  withCloseBtn?: boolean;
}

export const Modal = ({
  children,
  setIsShow,
  isShow,
  withCloseBtn = false,
}: ModalProps) => {
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isShow]);
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  });

  const onClickInModel = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget) {
      setIsShow(false);
    }
  };

  return (
    <Portal idPortal="modal">
      <div
        className={"modal" + (!isShow ? " modal_hidden" : "")}
        onClick={onClickInModel}
      >
        <div className="modal__window">
          {children}
          {withCloseBtn && (
            <button
              className={"modal__closeBtn"}
              onClick={() => {
                setIsShow(false);
              }}
            >
              Open/close modal
            </button>
          )}
        </div>
      </div>
    </Portal>
  );
};
