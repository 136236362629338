import React from 'react'


export const HeaderIcon = () => {
    return(
        <>
            <svg width="76" height="16" viewBox="0 0 76 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.77449 16C2.3969 16 2.04792 15.9085 1.72754 15.7254C1.4186 15.5309 1.16688 15.2792 0.972364 14.9702C0.789291 14.6498 0.697754 14.3009 0.697754 13.9233V0.00485364H2.10513V13.9233C2.10513 14.1063 2.16806 14.2665 2.29392 14.4038C2.43123 14.5297 2.59142 14.5926 2.77449 14.5926H4.45648V16H2.77449Z" fill="white"/>
                <path d="M7.0823 16C6.70471 16 6.35573 15.9085 6.03535 15.7254C5.72641 15.5309 5.47469 15.2792 5.28017 14.9702C5.0971 14.6498 5.00556 14.3009 5.00556 13.9233V8.12214C5.00556 7.74455 5.0971 7.40129 5.28017 7.09235C5.47469 6.77198 5.72641 6.52025 6.03535 6.33718C6.35573 6.14266 6.70471 6.0454 7.0823 6.0454H13.0551C13.4441 6.0454 13.7931 6.14266 14.102 6.33718C14.4224 6.52025 14.6741 6.77198 14.8572 7.09235C15.0517 7.40129 15.149 7.74455 15.149 8.12214V13.9233C15.149 14.3009 15.0517 14.6498 14.8572 14.9702C14.6741 15.2792 14.4224 15.5309 14.102 15.7254C13.7931 15.9085 13.4441 16 13.0551 16H7.0823ZM7.0823 14.5926H13.0551C13.2381 14.5926 13.3926 14.5297 13.5185 14.4038C13.6558 14.2665 13.7244 14.1063 13.7244 13.9233V8.12214C13.7244 7.93907 13.6558 7.7846 13.5185 7.65874C13.3926 7.52143 13.2381 7.45278 13.0551 7.45278H7.0823C6.89923 7.45278 6.73904 7.52143 6.60173 7.65874C6.47587 7.7846 6.41294 7.93907 6.41294 8.12214V13.9233C6.41294 14.1063 6.47587 14.2665 6.60173 14.4038C6.73904 14.5297 6.89923 14.5926 7.0823 14.5926Z" fill="white"/>
                <path d="M18.2792 16C17.9016 16 17.5526 15.9085 17.2323 15.7254C16.9233 15.5309 16.6716 15.2792 16.4771 14.9702C16.294 14.6498 16.2025 14.3009 16.2025 13.9233V8.12214C16.2025 7.74455 16.294 7.40129 16.4771 7.09235C16.6716 6.77198 16.9233 6.52025 17.2323 6.33718C17.5526 6.14266 17.9016 6.0454 18.2792 6.0454H26.3116V7.45278H18.2792C18.0961 7.45278 17.936 7.52143 17.7987 7.65874C17.6728 7.7846 17.6099 7.93907 17.6099 8.12214V13.9233C17.6099 14.1063 17.6728 14.2665 17.7987 14.4038C17.936 14.5297 18.0961 14.5926 18.2792 14.5926H26.3459V16H18.2792Z" fill="white"/>
                <path d="M27.166 16V0.00485364H28.5733V10.319H31.3194L35.2326 6.0454H37.0862V6.06257L32.5723 11.0227L37.0691 15.9828V16H35.2326L31.3194 11.7264H28.5733V16H27.166Z" fill="white"/>
                <path d="M39.5166 16C39.139 16 38.79 15.9085 38.4696 15.7254C38.1607 15.5309 37.909 15.2792 37.7144 14.9702C37.5314 14.6498 37.4398 14.3009 37.4398 13.9233V8.12214C37.4398 7.74455 37.5314 7.40129 37.7144 7.09235C37.909 6.77198 38.1607 6.52025 38.4696 6.33718C38.79 6.14266 39.139 6.0454 39.5166 6.0454H45.4893C45.8784 6.0454 46.2273 6.14266 46.5363 6.33718C46.8567 6.52025 47.1084 6.77198 47.2915 7.09235C47.486 7.40129 47.5832 7.74455 47.5832 8.12214V11.7264H38.8472V13.9233C38.8472 14.1063 38.9101 14.2665 39.036 14.4038C39.1733 14.5297 39.3335 14.5926 39.5166 14.5926H47.5832V16H39.5166ZM38.8472 10.319H46.1587V8.12214C46.1587 7.93907 46.09 7.7846 45.9527 7.65874C45.8269 7.52143 45.6724 7.45278 45.4893 7.45278H39.5166C39.3335 7.45278 39.1733 7.52143 39.036 7.65874C38.9101 7.7846 38.8472 7.93907 38.8472 8.12214V10.319Z" fill="white"/>
                <path d="M48.5701 16V8.12214C48.5701 7.74455 48.6616 7.40129 48.8447 7.09235C49.0392 6.77198 49.291 6.52025 49.5999 6.33718C49.9203 6.14266 50.2693 6.0454 50.6468 6.0454H56.242V7.45278H50.6468C50.4638 7.45278 50.3036 7.52143 50.1663 7.65874C50.0404 7.7846 49.9775 7.93907 49.9775 8.12214V16H48.5701Z" fill="white"/>
                <path d="M56.242 4.49362V0.00624105H59.8931C60.047 0.00624105 60.1864 0.0457682 60.3112 0.124823C60.4402 0.199716 60.5421 0.301655 60.617 0.430638C60.6961 0.555461 60.7356 0.694846 60.7356 0.848794V2.08454C60.7356 2.23433 60.6961 2.37371 60.617 2.50269C60.5421 2.63168 60.4402 2.73362 60.3112 2.80851C60.1864 2.8834 60.047 2.92085 59.8931 2.92085L56.9909 2.92709V4.49362H56.242ZM57.1033 2.16567H59.8681C59.8972 2.16567 59.9222 2.15527 59.943 2.13447C59.968 2.11366 59.9804 2.08662 59.9804 2.05333V0.867517C59.9804 0.838392 59.968 0.813428 59.943 0.792624C59.9222 0.767659 59.8972 0.755177 59.8681 0.755177H57.1033C57.0742 0.755177 57.0471 0.767659 57.0221 0.792624C57.0013 0.813428 56.9909 0.838392 56.9909 0.867517V2.05333C56.9909 2.08662 57.0013 2.11366 57.0221 2.13447C57.0471 2.15527 57.0742 2.16567 57.1033 2.16567Z" fill="white"/>
                <path d="M60.9721 4.49362V0.842553C60.9721 0.688605 61.0096 0.547139 61.0845 0.418156C61.1594 0.289172 61.2613 0.187234 61.3903 0.11234C61.5193 0.0374468 61.6608 0 61.8147 0H64.6232C64.7772 0 64.9165 0.0374468 65.0414 0.11234C65.1703 0.187234 65.2723 0.289172 65.3472 0.418156C65.4262 0.547139 65.4658 0.688605 65.4658 0.842553V4.49362H64.7106V2.95206H61.7211V4.49362H60.9721ZM61.7211 2.20312H64.7106V0.867517C64.7106 0.834231 64.6981 0.807186 64.6731 0.786383C64.6523 0.765579 64.6274 0.755177 64.5982 0.755177H61.8334C61.8043 0.755177 61.7772 0.765579 61.7523 0.786383C61.7315 0.807186 61.7211 0.834231 61.7211 0.867517V2.20312Z" fill="white"/>
                <path d="M66.7448 4.49362C66.5909 4.49362 66.4494 4.45617 66.3204 4.38128C66.1956 4.30638 66.0957 4.20444 66.0208 4.07546C65.9459 3.94648 65.9085 3.80501 65.9085 3.65106V3.35149H66.6574V3.6261C66.6574 3.65938 66.6678 3.68643 66.6886 3.70723C66.7094 3.72804 66.7365 3.73844 66.7698 3.73844H69.5283C69.5616 3.73844 69.5887 3.72804 69.6095 3.70723C69.6344 3.68643 69.6469 3.65938 69.6469 3.6261V2.73362C69.6469 2.70449 69.6344 2.67953 69.6095 2.65872C69.5887 2.63376 69.5616 2.62128 69.5283 2.62128H66.7448C66.5909 2.62128 66.4494 2.58383 66.3204 2.50894C66.1956 2.43404 66.0957 2.33418 66.0208 2.20936C65.9459 2.08038 65.9085 1.93891 65.9085 1.78496V0.842553C65.9085 0.688605 65.9459 0.547139 66.0208 0.418156C66.0957 0.289172 66.1956 0.187234 66.3204 0.11234C66.4494 0.0374468 66.5909 0 66.7448 0H69.5596C69.7135 0 69.8529 0.0374468 69.9777 0.11234C70.1067 0.187234 70.2086 0.289172 70.2835 0.418156C70.3626 0.547139 70.4021 0.688605 70.4021 0.842553V1.14213H69.6469V0.867517C69.6469 0.834231 69.6344 0.807186 69.6095 0.786383C69.5887 0.765579 69.5616 0.755177 69.5283 0.755177H66.7698C66.7365 0.755177 66.7094 0.765579 66.6886 0.786383C66.6678 0.807186 66.6574 0.834231 66.6574 0.867517V1.76C66.6574 1.78912 66.6678 1.81617 66.6886 1.84113C66.7094 1.86194 66.7365 1.87234 66.7698 1.87234H69.5596C69.7135 1.87234 69.8529 1.90979 69.9777 1.98468C70.1067 2.05957 70.2086 2.16151 70.2835 2.2905C70.3626 2.41532 70.4021 2.5547 70.4021 2.70865V3.65106C70.4021 3.80501 70.3626 3.94648 70.2835 4.07546C70.2086 4.20444 70.1067 4.30638 69.9777 4.38128C69.8529 4.45617 69.7135 4.49362 69.5596 4.49362H66.7448Z" fill="white"/>
                <path d="M71.6453 4.49362C71.4914 4.49362 71.3499 4.45617 71.2209 4.38128C71.0961 4.30638 70.9962 4.20444 70.9213 4.07546C70.8465 3.94648 70.809 3.80501 70.809 3.65106V3.35149H71.5579V3.6261C71.5579 3.65938 71.5683 3.68643 71.5891 3.70723C71.6099 3.72804 71.637 3.73844 71.6703 3.73844H74.4289C74.4621 3.73844 74.4892 3.72804 74.51 3.70723C74.535 3.68643 74.5474 3.65938 74.5474 3.6261V2.73362C74.5474 2.70449 74.535 2.67953 74.51 2.65872C74.4892 2.63376 74.4621 2.62128 74.4289 2.62128H71.6453C71.4914 2.62128 71.3499 2.58383 71.2209 2.50894C71.0961 2.43404 70.9962 2.33418 70.9213 2.20936C70.8465 2.08038 70.809 1.93891 70.809 1.78496V0.842553C70.809 0.688605 70.8465 0.547139 70.9213 0.418156C70.9962 0.289172 71.0961 0.187234 71.2209 0.11234C71.3499 0.0374468 71.4914 0 71.6453 0H74.4601C74.614 0 74.7534 0.0374468 74.8782 0.11234C75.0072 0.187234 75.1091 0.289172 75.184 0.418156C75.2631 0.547139 75.3026 0.688605 75.3026 0.842553V1.14213H74.5474V0.867517C74.5474 0.834231 74.535 0.807186 74.51 0.786383C74.4892 0.765579 74.4621 0.755177 74.4289 0.755177H71.6703C71.637 0.755177 71.6099 0.765579 71.5891 0.786383C71.5683 0.807186 71.5579 0.834231 71.5579 0.867517V1.76C71.5579 1.78912 71.5683 1.81617 71.5891 1.84113C71.6099 1.86194 71.637 1.87234 71.6703 1.87234H74.4601C74.614 1.87234 74.7534 1.90979 74.8782 1.98468C75.0072 2.05957 75.1091 2.16151 75.184 2.2905C75.2631 2.41532 75.3026 2.5547 75.3026 2.70865V3.65106C75.3026 3.80501 75.2631 3.94648 75.184 4.07546C75.1091 4.20444 75.0072 4.30638 74.8782 4.38128C74.7534 4.45617 74.614 4.49362 74.4601 4.49362H71.6453Z" fill="white"/>
            </svg>
        </>
    )
}