import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { FileService } from "../../API/services";
import { FileSlice } from "Entities/File";

export const deleteFile = (id: number) => async (dispatch: AppDispatch) => {
  const {
    onDeleteFile,
    onFetchFile,
    onFetchFileError,
    onChangeStatusMessageFile,
  } = FileSlice.actions;

  const onChengeStatusMessage = (
    status: "error" | "success",
    message: string
  ) => {
    dispatch(
      onChangeStatusMessageFile({
        status,
        message,
      })
    );
    setTimeout(() => {
      dispatch(onChangeStatusMessageFile(null));
    }, 3000);
  };
  try {
    dispatch(onFetchFile());
    const response = await FileService.delete(id);
    console.log("deleteFile", response);

    dispatch(onDeleteFile(id));
    // dispatch(onDeleteFile(response.data.File.id));

    onChengeStatusMessage("success", "Данные успешно удалены");
    return true;
  } catch (e) {
    console.log("deleteFile error", e);

    if (axios.isAxiosError(e) && typeof e.response?.data.message === "string") {
      dispatch(
        onFetchFileError({
          message: e.response?.data.message,
          errorCode: e.response?.status.toString(),
        })
      );
      onChengeStatusMessage(
        "error",
        "Не удалось удалить данные о сайте, попробуйте позже"
      );
    } else {
      dispatch(
        onFetchFileError({
          message: "Неизвестная ошибка удаления пароля",
          errorCode: "unknownError",
        })
      );
      onChengeStatusMessage(
        "error",
        "Не удалось удалить данные о сайте, попробуйте позже"
      );
    }
  }
  return false;
};
