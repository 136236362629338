const regStr = /^[A-Za-zА-Яа-я0-9!@#$%^&*_\-+='|\\(){}[\]:""<>.,?/ ]+$/;

export const NameValidationOptions = (passwordNamesArr: string[]) => {
  return {
    options: {
      required: true,
      isNotIncludedInTheList: passwordNamesArr,
      maxLength: 256,
      isMatchPattern: regStr,
    },
    messageText: {
      required: "Название файла не должно быть пустым",
      isNotIncludedInTheList: "Название должно быть уникальным",
      isMatchPattern:
        "Название для записи должен состоять лишь из русских или латинских букв, цифр и специальных символов: " +
        `!@#$%^&* _-+='|` +
        "\\" +
        `() {} []:"" <>,.? / "`,
    },
  };
};
