import { AxiosResponse } from "axios";
import { CatalogItem, CatalogResponse } from "Entities/Catalog";
import { $api, urlEndpoints } from "Shared/API";
import { CATALOG_TYPE } from "Shared/Types";

export class CatalogService {
  static async update(
    catalogItem: CatalogItem,
    catalogType: CATALOG_TYPE
  ): Promise<AxiosResponse<CatalogResponse>> {
    return $api.put<CatalogResponse>(
      catalogType === CATALOG_TYPE.FILES
        ? urlEndpoints.FILE_CATALOG.update
        : urlEndpoints.CATALOG.update,
      catalogItem
    );
  }
}
