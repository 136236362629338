import { useAppDispatch } from "Shared/Model";
import { Button } from "Shared/UI";
import React from "react";
import { createPassword } from "../Model/Store/actionCreator";
import { PasswordItem } from "Entities/Password";

interface CreatePasswordButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  passwordItemInfo: Omit<PasswordItem, "id">;
}

export const CreatePasswordButton = ({
  className,
  passwordItemInfo,
  onClick,
  ...props
}: CreatePasswordButtonProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await dispatch(createPassword(passwordItemInfo));
    if (result) {
      if (onClick) onClick(e);
    }
  };
  return (
    <Button
      {...props}
      theme="bright"
      type="submit"
      onClick={onSubmit}
      className={"createPasswordButton" + (className ? " " + className : "")}
    >
      Добавить
    </Button>
  );
};
