import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./style.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  sizeTheme?: "large" | "medium" | "small";
  helperText?: string | React.ReactNode;
  beforeSlot?: React.ReactNode;
  afterSlot?: React.ReactNode;
  isError?: boolean;
}

const InputField = forwardRef(
  (
    {
      helperText,
      beforeSlot,
      afterSlot,
      sizeTheme = "large",
      label,
      className,
      isError,
      onChange,
      ...props
    }: InputProps,
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current);

    const [value, setValue] = useState<string>("");

    useEffect(() => {
      if (typeof props.value === "string") setValue(props.value);
    }, [props.value]);

    const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      } else {
        setValue(e.target.value);
      }
    };

    return (
      <div
        className={
          "input" +
          (className ? " " + className : "") +
          (isError ? " input_error" : "") +
          (props.disabled ? " input_disabled" : "")
        }
      >
        {label && <div className="input__title">{label}</div>}
        <label
          className={
            "input__wrap input__wrap_size_" +
            sizeTheme +
            (value !== "" ? " input__wrap_noEmpty" : "")
          }
        >
          {label && <span className="input__label">{label}</span>}
          {beforeSlot && (
            <div className={"input__slot input__slot_before"}>{beforeSlot}</div>
          )}
          <input
            {...props}
            className={"input__field"}
            ref={innerRef}
            onChange={onChangeInner}
          />
          {afterSlot && (
            <div className={"input__slot input__slot_after"}>{afterSlot}</div>
          )}
        </label>
        {helperText && <div className="input__helperText">{helperText}</div>}
      </div>
    );
  }
);

InputField.displayName = "Input";

export const Input = InputField;
