import React from "react";
import { FileIcon } from "../Icons/FileIcon";
import "./style.scss";

interface FileComponentProps {
  fileName: string;
  fileSize?: number;
}

export const FileComponent = ({
  fileName,
  fileSize = 1024 * 1024 * 5,
}: FileComponentProps) => {
  return (
    <div className="FileComponent__container">
      <div className={`FileComponent__file `}>
        <div className="FileComponent__fileName">
          <FileIcon color={"white"} />
          <p className="FileComponent__fileNameText">{fileName}</p>
        </div>
        <div className="FileComponent__fileSizeContainer">
          <p className="FileComponent__fileSize">
            {(fileSize / 1024 / 1024).toFixed(1)} MB
          </p>
        </div>
      </div>
    </div>
  );
};
