import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { PersonalService } from "../../API/services";
import { AuthSlice } from "Entities/User";

export const DeleteAccountActionCreator =
  (password: string) => async (dispatch: AppDispatch) => {
    const {
      onFetchAuth,
      onChengeUserItem,
      onFetchAuthError,
      onChengeStatusMessageAuth,
    } = AuthSlice.actions;

    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessageAuth({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessageAuth(null));
      }, 3000);
    };

    try {
      dispatch(onFetchAuth());
      const response = await PersonalService.delete(password);
      console.log("DeleteAccountActionCreator: ", response);
      onChengeStatusMessage("success", "Аккаунт успешно удален");
      dispatch(onChengeUserItem(null));
      dispatch(onFetchAuth());
      return true;
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchAuthError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
      } else {
        dispatch(
          onFetchAuthError({
            message: "Неизвестная ошибка получения персональных данных",
            errorCode: "unknownError",
          })
        );
      }
      onChengeStatusMessage(
        "error",
        "Ошибка удаления аккаунта. Попробуйте позже."
      );
      return false;
    }
  };
