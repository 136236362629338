const UrlRegex =
  /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/;

export const URLValidationOptions = {
  options: {
    required: true,
    isMatchPattern: UrlRegex,
  },
  messageText: {
    required: "Укажите адрес сайта, для которого вы хотите создать запись",
    isMatchPattern: "Не похоже, что вы ввели корректную ссылку на сайт",
  },
};
