import axios from "axios";
import Cookies from "js-cookie";
import AuthService from "../../API/services";
import { AppDispatch } from "Shared/Model";
import { AuthSlice } from "Entities/User";

export const registration =
  (email: string, password: string, nickname: string) =>
  async (dispatch: AppDispatch) => {
    const { onFetchAuth, onFetchAuthSucces, onFetchAuthError } =
      AuthSlice.actions;
    try {
      dispatch(onFetchAuth());
      const response = await AuthService.registration(
        email,
        password,
        nickname
      );
      Cookies.set("token", response.data.authorisation.token);
      dispatch(onFetchAuthSucces(response.data.user));
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchAuthError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
      } else {
        dispatch(
          onFetchAuthError({
            message: "Неизвестная ошибка логанизации",
            errorCode: "unknownError",
          })
        );
      }
    }
  };
