import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { PasswordService } from "../../API/services";
import { PasswordItem, PasswordSlice } from "Entities/Password";

export const updatePassword =
  (passwordItem: PasswordItem) => async (dispatch: AppDispatch) => {
    const {
      onChangePassword,
      onFetchPassword,
      onFetchPasswordError,
      onChengeStatusMessagePassword,
    } = PasswordSlice.actions;
    const onChengeStatusMessage = (
      status: "error" | "success",
      message: string
    ) => {
      dispatch(
        onChengeStatusMessagePassword({
          status,
          message,
        })
      );
      setTimeout(() => {
        dispatch(onChengeStatusMessagePassword(null));
      }, 3000);
    };
    try {
      dispatch(onFetchPassword());
      const response = await PasswordService.update(passwordItem);

      const password = response.data.password;

      if (password) {
        dispatch(
          onChangePassword(Array.isArray(password) ? password[0] : password)
        );
      } else {
        dispatch(onChangePassword(passwordItem));
      }
      onChengeStatusMessage("success", "Данные успешно обновлены");
      return true;
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchPasswordError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
        onChengeStatusMessage("error", "Не удалось обновить данные");
      } else {
        dispatch(
          onFetchPasswordError({
            message: "Неизвестная ошибка обновления записи о пароле",
            errorCode: "unknownError",
          })
        );
        onChengeStatusMessage("error", "Не удалось обновить данные");
      }
    }
    return false;
  };
