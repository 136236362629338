const regStr = /^[A-Za-zА-Яа-я0-9!@#$%^&*_\-+='|\\(){}[\]:""<>.,?/ ]+$/;

export const NameValidationOptions = (passwordNamesArr: string[]) => {
  return {
    options: {
      required: true,
      isNotIncludedInTheList: passwordNamesArr,
      maxLength: 256,
      isMatchPattern: regStr,
    },
    messageText: {
      required: "Название записи о пароле не должно быть пустым.",
      isNotIncludedInTheList:
        "В данном каталоге запись о пароле с таким названием уже существует.",
      isMatchPattern:
        "Название для записи должен состоять лишь из русских или латинских букв, цифр и специальных символов: " +
        `!@#$%^&* _-+='|` +
        "\\" +
        `() {} []:"" <>,.? / "`,
    },
  };
};
