import React from "react";

interface Props {
  className?: string;
}

export const PenIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2197 0.21967C18.5126 -0.0732233 18.9875 -0.0732233 19.2804 0.21967L23.7804 4.71967C24.0732 5.01256 24.0732 5.48744 23.7804 5.78033L8.78035 20.7803C8.70853 20.8521 8.62287 20.9086 8.52856 20.9464L1.02856 23.9464C0.750002 24.0578 0.431839 23.9925 0.219692 23.7803C0.00754483 23.5682 -0.0577607 23.25 0.0536643 22.9715L3.05366 15.4715C3.09139 15.3772 3.14787 15.2915 3.21969 15.2197L18.2197 0.21967ZM16.8107 3.75L20.25 7.18934L22.1894 5.25L18.75 1.81066L16.8107 3.75ZM19.1894 8.25L15.75 4.81066L6.00002 14.5607V15H6.75002C7.16424 15 7.50002 15.3358 7.50002 15.75V16.5H8.25002C8.66424 16.5 9.00002 16.8358 9.00002 17.25V18H9.43936L19.1894 8.25ZM4.5475 16.0132L4.38921 16.1715L2.09631 21.9037L7.82854 19.6108L7.98684 19.4525C7.70245 19.3459 7.50002 19.0716 7.50002 18.75V18H6.75002C6.33581 18 6.00002 17.6642 6.00002 17.25V16.5H5.25002C4.92842 16.5 4.65409 16.2976 4.5475 16.0132Z"
        fill="white"
      />
    </svg>
  );
};
