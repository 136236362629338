import { AxiosResponse } from "axios";
import { UserDeleteResponse } from "Entities/User";
import { $api, urlEndpoints } from "Shared/API";

export class PersonalService {
  static async delete(
    password: string
  ): Promise<AxiosResponse<UserDeleteResponse>> {
    return $api.delete<UserDeleteResponse>(urlEndpoints.PERSONAL.delete, {
      params: {
        password: password,
      },
    });
  }
}
