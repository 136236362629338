import { AxiosResponse } from "axios";
import { CatalogsResponse } from "Entities/Catalog";
import { FileCatalogsResponse } from "Entities/FileCatalog";
import { $api, urlEndpoints } from "Shared/API";
import { CATALOG_TYPE } from "Shared/Types";

export class CatalogService {
  static async fetchAll(
    items: CATALOG_TYPE = CATALOG_TYPE.PASSWORDS
  ): Promise<AxiosResponse<CatalogsResponse | FileCatalogsResponse>> {
    return $api.get<CatalogsResponse | FileCatalogsResponse>(
      items === CATALOG_TYPE.FILES
        ? urlEndpoints.FILE_CATALOG.fetchAll
        : urlEndpoints.CATALOG.fetchAll
    );
  }
}
