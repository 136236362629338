import React from "react";
import "./style.scss";
import { HeaderIcon } from "Shared/UI/Icons/HeaderIcon";
import { Button } from "Shared/UI/Button/ui";
import { useAppSelector } from "Shared/Model";
import { NavLink } from "react-router-dom";
import { routeEndpoint } from "Shared/Routes";
import { imgsList } from "Shared/Assets";
interface HeaderProps {
  className?: string;
}
export const Header = ({ className }: HeaderProps) => {
  const AuthState = useAppSelector((state) => {
    return state.auth;
  });
  const userImgsArr = imgsList.UserAvatar;
  const avatarSrc =
    AuthState.userItem?.avatar && Array.isArray(userImgsArr)
      ? userImgsArr.find((item) => item.id === AuthState.userItem?.avatar)
          ?.img || userImgsArr[0].img
      : userImgsArr[0].img;
  const logoLink =
    "/" + (AuthState.isAuth ? routeEndpoint.catalog : routeEndpoint.main);
  return (
    <header className={"header" + (className ? " " + className : "")}>
      <Button tag="NavLink" to={logoLink} className="header__logo">
        <HeaderIcon />
      </Button>
      <div className="header__navbar"></div>
      {AuthState.isAuth && (
        <NavLink to={"/profile"}>
          <img
            className="header__userImg"
            src={avatarSrc}
            alt="Личный кабинет"
          />
        </NavLink>
      )}
    </header>
  );
};
