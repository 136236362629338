import { useAppDispatch, useAppSelector } from "Shared/Model";
import { Button } from "Shared/UI";
import React from "react";
import { updateCatalog } from "../Model/Store/actionCreator";
import { CatalogItem } from "Entities/Catalog";
import { CATALOG_TYPE } from "Shared/Types";

interface UpdateCatalogButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  catalogItem: CatalogItem;
}

export const UpdateCatalogButton = ({
  className,
  catalogItem,
  onClick,
  ...props
}: UpdateCatalogButtonProps) => {
  const dispatch = useAppDispatch();

  const { passwordsOrFiles } = useAppSelector((state) => state.catalogPage);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(
      updateCatalog(
        catalogItem,
        passwordsOrFiles === "left"
          ? CATALOG_TYPE.PASSWORDS
          : CATALOG_TYPE.FILES
      )
    );
    if (onClick) onClick(e);
  };
  return (
    <Button
      {...props}
      theme="bright"
      type="button"
      onClick={onSubmit}
      className={"updateCatalogButton" + (className ? " " + className : "")}
    >
      Сохранить
    </Button>
  );
};
