import React, { useState } from "react";
import "./style.scss";
import { QRScannerModal } from "Features/QRScanner";
import { useNavigate } from "react-router-dom";
import { QRSesseonItem } from "Entities/QR";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { sendQR } from "Features/SendPassword";
import { Button, NotificationBox } from "Shared/UI";
import { routeEndpoint } from "Shared/Routes";

export const ScannerQRPage = () => {
  const navigate = useNavigate();
  const CatalogPageState = useAppSelector((state) => state.catalogPage);
  const QRState = useAppSelector((state) => state.qr);
  const dispatch = useAppDispatch();

  const [isScannerSuccess, setIsScannerSuccess] = useState<boolean>(false);
  const [isLoadingScanner, setIsLoadingScanner] = useState<boolean>(false);

  const QRScannerSuccess = async (text: string) => {
    if (!isScannerSuccess && !isLoadingScanner) {
      await setIsLoadingScanner(true);
      let result = false;
      const QRItem = JSON.parse(text) as QRSesseonItem;

      if (CatalogPageState.selectedPassword !== null) {
        result = await dispatch(
          sendQR({
            id: QRItem.id,
            security_key: QRItem.security_key,
            password_id: CatalogPageState.selectedPassword,
          })
        );
      }
      if (CatalogPageState.currentFile !== null) {
        result = await dispatch(
          sendQR(
            {
              id: QRItem.id,
              security_key: QRItem.security_key,
              password_id: CatalogPageState.currentFile.id,
            },
            "file"
          )
        );
      }

      if (result) {
        await setIsScannerSuccess(true);
        setTimeout(() => {
          navigate("/" + routeEndpoint.catalog);
        }, 3000);
      }
      await setIsLoadingScanner(false);
    }
  };
  const QRScannerStop = () => {
    navigate("/" + routeEndpoint.catalog);
  };
  const QRScannerError = (e: unknown) => {
    console.log("ScannerQRPage QRScannerError", e);
  };

  const onClickCloseBtn = () => {
    navigate("/" + routeEndpoint.catalog);
  };

  return (
    <>
      <div>
        {QRState.statusMessage !== null && (
          <NotificationBox
            correct={QRState.statusMessage.status === "success"}
            content={QRState.statusMessage.message}
            className={"scannerQRPage__notification"}
          />
        )}
        <Button
          type="button"
          theme="bright"
          onClick={onClickCloseBtn}
          className="scannerQRPage__closeBtn"
        >
          Назад
        </Button>
      </div>
      <main className="scannerQRPage">
        <QRScannerModal
          QRScannerSuccess={QRScannerSuccess}
          QRScannerStop={QRScannerStop}
          QRScannerError={QRScannerError}
          QRboxWidth={400}
          QRboxHeight={400}
        />
      </main>
    </>
  );
};
