import React from "react";
import { FileIcon } from "../Icons/FileIcon";
import { XIcon } from "../Icons/XIcon";
import { UploadIcon } from "../Icons/UploadIcon";
import { RetryIcon } from "../Icons/RetryIcon";
import "./style.scss";

interface FileUploaderProps {
  size?: "small" | "large";
  label?: string;
  initFile?: File;
  maxSize?: number;
  formats?: string[];
  helperText?: string;
  loading?: boolean;
  disabled?: boolean;
  onUpload?: (file: File) => void;
  error?: string | null;
}

export const FileUploader = ({
  size = "small",
  label = "",
  initFile,
  helperText = "",
  loading = false,
  maxSize = 10,
  formats = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf",
  ],
  disabled = false,
  onUpload = () => {},
  error = "",
}: FileUploaderProps) => {
  const [file, setFile] = React.useState<File | null>(initFile || null);
  const [validError, setValidError] = React.useState<string | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const checkFile = (file: File) => {
    if (!file) {
      setValidError("No file selected");
      return false;
    }
    if (file.size / 1024 / 1024 > maxSize) {
      setValidError(`Размер файла не должен превышать ${maxSize} Мб`);
      return false;
    }
    if (!formats) {
      return true;
    }
    if (!formats.includes(file.type)) {
      setValidError(
        "Формат файла не поддерживается. Файл должен быть в формате: doc(x), xls(x), pdf"
      );
      return false;
    }
    return true;
  };

  const onReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setValidError(null);
    setFile(null);
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (disabled) return;
    const file = e.dataTransfer.files[0];
    if (!checkFile(file)) return;
    setFile(file);
  };

  React.useEffect(() => {
    if (file) {
      onUpload(file);
    }
  }, [file]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target.files?.[0];
    if (!file) return;
    if (!checkFile(file)) return;
    file && setFile(file);
  };

  return (
    <div className="FileUploader__container">
      <p className="FileUploader__label">{label}</p>
      {file ? (
        <div
          className={`FileUploader__file ${
            disabled && "FileUploader__file_disabled"
          }`}
        >
          <div className="FileUploader__fileName">
            <FileIcon color={disabled ? "grey" : "white"} />
            <p className="FileUploader__fileNameText">{file.name}</p>
          </div>
          <div className="FileUploader__fileSizeContainer">
            <p className="FileUploader__fileSize">
              {(file.size / 1024 / 1024).toFixed(1)} MB
            </p>
            <button
              className="FileUploader__closeButton"
              onClick={() => setFile(null)}
            >
              <XIcon width="7" height="7" color="white" />
            </button>
          </div>
        </div>
      ) : (
        <div
          aria-disabled={disabled}
          onDrop={onDrop}
          onDragOver={(e) => e.preventDefault()}
          className={"FileUploader__dropZone FileUploader__dropZone-" + size}
          data-disabled={disabled ? "true" : "false"}
        >
          {error || validError ? (
            <>
              <button onClick={onReset} className="FileUploader__retryButton">
                <RetryIcon />
              </button>
              <div className="FileUploader__dropZoneTextContainer">
                <p
                  className={
                    "FileUploader__dropZoneText " +
                    `FileUploader__dropZoneText-${size}`
                  }
                >
                  Повторить
                </p>
                <p
                  className={
                    "FileUploader__dropZoneText " +
                    `FileUploader__dropZoneText-${size}`
                  }
                >
                  Загрузку
                </p>
              </div>
            </>
          ) : (
            <>
              <UploadIcon width="16" height="16" />
              {loading ? (
                <p
                  className={
                    "FileUploader__dropZoneLoading FileUploader__dropZoneLoading-" +
                    size
                  }
                >
                  Loading...
                </p>
              ) : (
                <div>
                  <p
                    className={
                      "FileUploader__dropZoneText " +
                      `FileUploader__dropZoneText-${size}`
                    }
                  >
                    Drop file here or
                  </p>
                  <button
                    className={
                      "FileUploader__browseButton FileUploader__browseButton-" +
                      size
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      inputRef.current?.click();
                    }}
                  >
                    <p>Browse file</p>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {error || validError ? (
        <p className="FileUploader__error">{validError || error}</p>
      ) : (
        <p className="FileUploader__helper">{helperText}</p>
      )}
      <input
        disabled={disabled}
        type="file"
        hidden
        ref={inputRef}
        onChange={handleChange}
      />
    </div>
  );
};
