import React from "react";

interface EyeIconProps extends React.SVGProps<SVGSVGElement> {
  isVisible?: boolean;
}
export const EyeIcon = ({
  isVisible = false,
  width = "20",
  height = "20",
  ...props
}: EyeIconProps) => {
  return !isVisible ? (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_420_5663"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_420_5663)">
        <path
          d="M12.0018 15.577C13.135 15.577 14.0974 15.1804 14.8891 14.3872C15.6808 13.5941 16.0766 12.6309 16.0766 11.4978C16.0766 10.3646 15.68 9.40217 14.8868 8.6105C14.0937 7.81883 13.1305 7.423 11.9973 7.423C10.8642 7.423 9.90175 7.81958 9.11009 8.61275C8.31842 9.40592 7.92259 10.3691 7.92259 11.5023C7.92259 12.6354 8.31917 13.5978 9.11234 14.3895C9.9055 15.1812 10.8687 15.577 12.0018 15.577ZM11.9996 14.2C11.2496 14.2 10.6121 13.9375 10.0871 13.4125C9.56209 12.8875 9.29959 12.25 9.29959 11.5C9.29959 10.75 9.56209 10.1125 10.0871 9.5875C10.6121 9.0625 11.2496 8.8 11.9996 8.8C12.7496 8.8 13.3871 9.0625 13.9121 9.5875C14.4371 10.1125 14.6996 10.75 14.6996 11.5C14.6996 12.25 14.4371 12.8875 13.9121 13.4125C13.3871 13.9375 12.7496 14.2 11.9996 14.2ZM12.0008 18.5C9.70134 18.5 7.60609 17.8657 5.71509 16.597C3.82409 15.3285 2.43175 13.6295 1.53809 11.5C2.43175 9.3705 3.82359 7.6715 5.71359 6.403C7.60375 5.13433 9.69867 4.5 11.9983 4.5C14.2978 4.5 16.3931 5.13433 18.2841 6.403C20.1751 7.6715 21.5674 9.3705 22.4611 11.5C21.5674 13.6295 20.1756 15.3285 18.2856 16.597C16.3954 17.8657 14.3005 18.5 12.0008 18.5ZM11.9996 17C13.8829 17 15.6121 16.5042 17.1871 15.5125C18.7621 14.5208 19.9663 13.1833 20.7996 11.5C19.9663 9.81667 18.7621 8.47917 17.1871 7.4875C15.6121 6.49583 13.8829 6 11.9996 6C10.1163 6 8.38709 6.49583 6.81209 7.4875C5.23709 8.47917 4.03292 9.81667 3.19959 11.5C4.03292 13.1833 5.23709 14.5208 6.81209 15.5125C8.38709 16.5042 10.1163 17 11.9996 17Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ) : (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_420_5664"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_420_5664)">
        <path
          d="M15.7726 12.973L14.6496 11.85C14.7996 11.0218 14.5633 10.2773 13.9408 9.61625C13.3185 8.95542 12.5548 8.7 11.6496 8.85L10.5266 7.727C10.7523 7.62567 10.9837 7.54967 11.2208 7.499C11.458 7.44834 11.7176 7.423 11.9996 7.423C13.1343 7.423 14.0974 7.81884 14.8891 8.6105C15.6808 9.40217 16.0766 10.3653 16.0766 11.5C16.0766 11.782 16.0513 12.0448 16.0006 12.2885C15.9499 12.532 15.8739 12.7602 15.7726 12.973ZM18.9533 16.0845L17.8496 15.05C18.4829 14.5667 19.0454 14.0375 19.5371 13.4625C20.0288 12.8875 20.4496 12.2333 20.7996 11.5C19.9663 9.81667 18.7704 8.47917 17.2121 7.4875C15.6538 6.49584 13.9163 6 11.9996 6C11.5163 6 11.0413 6.03334 10.5746 6.1C10.1079 6.16667 9.64959 6.26667 9.19959 6.4L8.03434 5.23475C8.66634 4.98342 9.31184 4.79817 9.97084 4.679C10.6298 4.55967 11.3061 4.5 11.9996 4.5C14.3431 4.5 16.4565 5.14617 18.3398 6.4385C20.2232 7.73084 21.5969 9.418 22.4611 11.5C22.0906 12.3935 21.6121 13.2275 21.0256 14.002C20.4389 14.7763 19.7482 15.4705 18.9533 16.0845ZM19.7611 21.8693L15.7151 17.8538C15.2023 18.0436 14.6339 18.1988 14.0101 18.3193C13.3864 18.4398 12.7163 18.5 11.9996 18.5C9.64959 18.5 7.53617 17.8538 5.65934 16.5615C3.78234 15.2692 2.40859 13.582 1.53809 11.5C1.90725 10.6167 2.38417 9.79267 2.96884 9.028C3.5535 8.26317 4.19709 7.6 4.89959 7.0385L2.13034 4.2385L3.18434 3.18475L20.8148 20.8153L19.7611 21.8693ZM5.95359 8.09225C5.42525 8.51275 4.91142 9.01825 4.41209 9.60875C3.91275 10.1991 3.50859 10.8295 3.19959 11.5C4.03292 13.1833 5.22875 14.5208 6.78709 15.5125C8.34542 16.5042 10.0829 17 11.9996 17C12.4548 17 12.908 16.9615 13.3593 16.8845C13.8105 16.8077 14.1932 16.7283 14.5073 16.6463L13.2418 15.35C13.0713 15.4192 12.8739 15.4743 12.6496 15.5153C12.4253 15.5564 12.2086 15.577 11.9996 15.577C10.8649 15.577 9.90175 15.1812 9.11009 14.3895C8.31842 13.5978 7.92259 12.6347 7.92259 11.5C7.92259 11.2975 7.94317 11.0857 7.98434 10.8645C8.02534 10.6433 8.08042 10.4411 8.14959 10.2578L5.95359 8.09225Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
