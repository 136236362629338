export enum routeEndpoint {
  login = "authorization",
  main = "",
  registration = "registration",
  profile = "profile",
  selectAuth = "auth",
  notTrust = "notTrust",
  catalog = "catalog",
  addFile = "addFile",
  updateFile = "updateFile",
  addPassword = "addPassword",
  updatePassword = "updatePassword",

  updateName = "updateName",
  updateEmail = "updateEmail",
  updatePersonalPassword = "updatePersonalPassword",

  scannerQR = "scannerQR",
}
