import { UserResponse, UserType } from "Entities/User";
import { $api, urlEndpoints } from "Shared/API";
import { AxiosResponse } from "axios";

export class PersonalService {
  static async update(
    userItem: Omit<UserType, "id">
  ): Promise<AxiosResponse<UserResponse>> {
    return $api.put<UserType, AxiosResponse<UserResponse>>(
      urlEndpoints.PERSONAL.update,
      {
        name: userItem.name,
        avatar: userItem.avatar !== null ? userItem.avatar : "1",
        email: userItem.email,
        password: userItem.password,
        old_password: userItem.old_password,
      }
    );
  }
}
