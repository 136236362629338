import React, { useEffect, useState } from "react";
import "./style.scss";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import {
  Button,
  CopyIcon,
  DelimiterForm,
  Input,
  NotificationBox,
} from "Shared/UI";
import logoImg from "./Assets/logoImg.png";
import { formatDateToDDMMYYYY } from "Shared/Lib";

interface copyButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  textForCopy: string;
}
const CopyButton = ({
  textForCopy,
  onClick,
  className,
  ...props
}: copyButtonProps) => {
  const [, setCopiedText] = useCopyToClipboard();

  const copyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    setCopiedText(textForCopy);
  };

  return (
    <Button
      {...props}
      type="button"
      onClick={copyClick}
      className={"copyButton" + (className ? " " + className : "")}
    >
      <CopyIcon color="currentColor" />
    </Button>
  );
};

interface DisplayDataAboutSiteFormProps {
  className?: string;
  siteLogo?: string;
  siteName: string;
  siteDate?: string;
  userName: string;
  login: string;
  password: string;
  readyBtnFunc: () => void;
  iconSlot?: React.ReactNode;
  titleForm?: string;
  labelBtn?: string;
  gapForm?: "32" | "16";
}

export const DisplayDataAboutSiteForm = ({
  className,
  siteLogo,
  siteName,
  siteDate,
  userName,
  login,
  password,
  iconSlot,
  titleForm = "Данные получены",
  labelBtn = "Готово!",
  gapForm = "32",
  readyBtnFunc,
}: DisplayDataAboutSiteFormProps) => {
  const [Login, setLogin] = useState<string>(login);
  const [Password, setPassword] = useState<string>(password);
  const [textForCopy, setTextForCopy] = useState<string>("");

  useEffect(() => {
    if (textForCopy !== "") {
      setTimeout(() => {
        setTextForCopy("");
      }, 3000);
    }
  }, [textForCopy]);

  const date = siteDate ? formatDateToDDMMYYYY(siteDate) : null;

  return (
    <>
      {textForCopy !== "" && (
        <NotificationBox
          className="displayDataAboutSiteForm__notification"
          content={`Строка ${textForCopy} успешно скопирована в буфер обмена`}
          correct={true}
        />
      )}
      <section
        className={
          "displayDataAboutSiteForm" +
          (className ? " " + className : "") +
          (" displayDataAboutSiteForm_gapForm" + gapForm)
        }
      >
        <div className="displayDataAboutSiteForm__headWrap">
          {iconSlot}
          <h2 className="displayDataAboutSiteForm__head">{titleForm}</h2>
        </div>
        <DelimiterForm />
        <div className="displayDataAboutSiteForm__data">
          <div className="displayDataAboutSiteForm__info">
            <div className="displayDataAboutSiteForm__siteInfoWrap">
              <div className="displayDataAboutSiteForm__siteLogoWrap">
                <img
                  alt="Лого сайта"
                  src={siteLogo ? siteLogo : logoImg}
                  className="displayDataAboutSiteForm__siteLogo"
                />
              </div>
              <div className="displayDataAboutSiteForm__siteInfo">
                <p className="displayDataAboutSiteForm__siteName">{siteName}</p>
                {date && (
                  <p className="displayDataAboutSiteForm__siteDate">{date}</p>
                )}
              </div>
            </div>
            <div className="displayDataAboutSiteForm__userInfo">
              <p className="displayDataAboutSiteForm__userName">{userName}</p>
              <p className="displayDataAboutSiteForm__userStatusAccount">
                Личный аккаунт
              </p>
            </div>
          </div>
          <div className="displayDataAboutSiteForm__inputWrap">
            <Input
              className="displayDataAboutSiteForm__field"
              value={Login}
              onChange={() => {
                setLogin(Login);
              }}
              afterSlot={
                <CopyButton
                  textForCopy={login}
                  className="displayDataAboutSiteForm__copyBtn"
                  onClick={() => {
                    setTextForCopy(login);
                  }}
                />
              }
            />
            <Input
              className="displayDataAboutSiteForm__field"
              value={Password}
              onChange={() => {
                setPassword(Password);
              }}
              afterSlot={
                <CopyButton
                  textForCopy={password}
                  className="displayDataAboutSiteForm__copyBtn"
                  onClick={() => {
                    setTextForCopy(password);
                  }}
                />
              }
            />
          </div>
        </div>
        <DelimiterForm />
        <Button
          theme="bright"
          className="displayDataAboutSiteForm__readyBtn"
          onClick={readyBtnFunc}
        >
          {labelBtn}
        </Button>
      </section>
    </>
  );
};
