import axios from "axios";
import { AppDispatch } from "Shared/Model";
import { PasswordService } from "../../API/services";
import { PasswordSlice } from "Entities/Password";

export const fetchAllPassword =
  (catalogId: number | null) => async (dispatch: AppDispatch) => {
    const { onFetchAllPassword, onFetchPassword, onFetchPasswordError } =
      PasswordSlice.actions;
    try {
      dispatch(onFetchPassword());

      if (catalogId !== null) {
        const response = await PasswordService.fetchAll(catalogId);
        console.log("fetchAllPassword", response);

        dispatch(onFetchAllPassword(response.data.password));
      } else {
        dispatch(onFetchAllPassword([]));
      }
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        typeof e.response?.data.message === "string"
      ) {
        dispatch(
          onFetchPasswordError({
            message: e.response?.data.message,
            errorCode: e.response?.status.toString(),
          })
        );
      } else {
        dispatch(
          onFetchPasswordError({
            message: "Неизвестная ошибка загрузки паролей",
            errorCode: "unknownError",
          })
        );
      }
    }
  };
