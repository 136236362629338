import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AuthReducer } from "Entities/User";
import { CatalogReducer } from "Entities/Catalog";
import { PasswordReducer } from "Entities/Password";
import { CatalogPageReducer } from "Entities/CatalogPage";
import { FileCatalogReducer } from "Entities/FileCatalog";
import { FileReducer } from "Entities/File";
import { QRReducer } from "Entities/QR";

const rootReducer = combineReducers({
  auth: AuthReducer,
  catalogs: CatalogReducer,
  passwords: PasswordReducer,
  files: FileReducer,
  catalogPage: CatalogPageReducer,
  fileCatalogs: FileCatalogReducer,
  qr: QRReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
