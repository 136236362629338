import React, { useRef, useState } from "react";
import "./style.scss";
import classNames from "classnames";
import { ArrowIcon } from "../Icons/ArrowIcon";
import { XIcon } from "../Icons/XIcon";
import { ChoiseForDropDown } from "../ChoiseForDropDown/ui";

export interface dropDownItemProps
  extends React.HtmlHTMLAttributes<HTMLLIElement> {
  itemId: number;
  label: string;
  count?: string;
  rightIcon?: boolean;
  leftIcon?: boolean;
}
interface Props {
  sizeTheme?: "large" | "medium" | "small" | "xsmall";
  status?: "success" | "error" | "checked";
  className?: string;
  titleDropDown?: string;
  helperText?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMultiSelecting?: boolean;
  choisesArr: dropDownItemProps[];
  setChickedArr: (v: dropDownItemProps[]) => void;
  initialVal?: dropDownItemProps;
}

export const DropDown = ({
  sizeTheme = "large",
  status,
  className,
  titleDropDown,
  helperText,
  placeholder,
  isDisabled,
  isMultiSelecting,
  choisesArr,
  setChickedArr,
  initialVal,
}: Props) => {
  const dropDown = useRef<HTMLDivElement>(null);
  const selectEle = useRef<HTMLUListElement>(null);
  const [chickedChoise, setChickedChoise] = useState<dropDownItemProps | null>(
    initialVal || null
  );
  const [assignedList, setAssignedList] = useState<dropDownItemProps[]>([]);
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [isChicked, setIsChicked] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const classNameDropDown = classNames(
    "dropDown",
    className,
    status ? "dropDown-" + status : "",
    isDisabled ? "dropDown-disabled" : dropDownOpen && "dropDown-active"
  );
  const classNameContainer = classNames(
    "dropDown__container",
    sizeTheme ? "dropDown__container-" + sizeTheme : "",
    isChicked && "dropDown__container-chicked",
    isMultiSelecting
      ? "dropDown__container-multiSelecting"
      : "dropDown__container-nunMultiSelecting"
  );

  const selectClassName = classNames(
    "dropDown__container__select",
    dropDownOpen
      ? "dropDown__container__select-opend"
      : "dropDown__container__select-closed"
  );

  const handleShowingElements = () => {
    if (
      !isDisabled &&
      dropDown.current &&
      selectEle.current &&
      setDropDownOpen
    ) {
      if (
        dropDown.current.classList.contains("dropDown-active") &&
        selectEle.current.classList.contains("dropDown__select-opend") &&
        dropDownOpen
      ) {
        setDropDownOpen(!dropDownOpen);
      } else {
        setDropDownOpen(!dropDownOpen);
      }
    }
  };

  const renderPlaceholder = () => {
    if (!isMultiSelecting) {
      if (chickedChoise !== null) {
        return chickedChoise.label;
      } else {
        return placeholder || "";
      }
    } else {
      if (Array.isArray(assignedList) && assignedList.length > 0) {
        return assignedList.map((item) => item.label).join(", ");
      }
      return placeholder;
    }
  };

  const handleBtnShowMore = () => {
    if (setShowAll && !showAll) setShowAll(true);
  };

  function handleAssign(item: dropDownItemProps) {
    setAssignedList((prevList) => {
      const isAlreadyAssigned = prevList.some(
        (assignedItem) => assignedItem.itemId === item.itemId
      );
      if (isAlreadyAssigned) {
        return Array.isArray(prevList)
          ? prevList.filter(
              (assignedItem) => assignedItem.itemId !== item.itemId
            )
          : [];
      } else {
        return [...prevList, item];
      }
    });
    setChickedArr(assignedList);
  }

  const handleChickedChoise = (item: dropDownItemProps) => {
    setChickedChoise(item);
    setChickedArr([item]);
    setDropDownOpen(false);
    setIsChicked(true);
  };

  const showCountSelectedChoisesInMultiSelecting = () => {
    if (
      isMultiSelecting &&
      assignedList.length !== undefined &&
      assignedList.length > 0
    ) {
      return (
        <span className="dropDown__container__count">
          {assignedList.length} <XIcon className="dropDown__container__xIcon" />
        </span>
      );
    }
  };

  const showChoisesIntoList = () => {
    if (
      !isDisabled &&
      dropDownOpen &&
      choisesArr &&
      choisesArr.length > 4 &&
      showAll
    ) {
      return choisesArr.map((e) => (
        <ChoiseForDropDown
          key={e.itemId}
          sizeTheme="large"
          handleAssign={handleAssign}
          handleChickedChoise={handleChickedChoise}
          child={e}
          chickedChoise={chickedChoise}
          assignedList={assignedList}
          isMultiSelecting={isMultiSelecting}
          status={status}
        />
      ));
    } else {
      return Array.from({ length: Math.min(choisesArr.length, 4) }, (_, n) => (
        <ChoiseForDropDown
          key={choisesArr[n].itemId}
          sizeTheme="large"
          handleAssign={handleAssign}
          handleChickedChoise={handleChickedChoise}
          child={choisesArr[n]}
          chickedChoise={chickedChoise}
          assignedList={assignedList}
          isMultiSelecting={isMultiSelecting}
          status={status}
        />
      ));
    }
  };

  return (
    <div ref={dropDown} className={classNameDropDown}>
      {titleDropDown && (
        <label className="dropDown__title">{titleDropDown}</label>
      )}
      <div className={classNameContainer}>
        {showCountSelectedChoisesInMultiSelecting()}
        <ArrowIcon
          className="dropDown__container__arrowIcon"
          onclick={handleShowingElements}
        />
        <span className="dropDown__container__placeholder">
          {renderPlaceholder()}
        </span>
        <ul className={selectClassName} ref={selectEle}>
          {showChoisesIntoList()}
          {choisesArr !== undefined && choisesArr.length > 3 && !showAll && (
            <button
              className="dropDown__container__btn"
              onClick={handleBtnShowMore}
            >
              Показать больше
            </button>
          )}
        </ul>
      </div>
      {helperText && <span className="dropDown__helperText">{helperText}</span>}
    </div>
  );
};
