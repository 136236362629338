import React from "react";
import { Button } from "Shared/UI";
import { downloadFile } from "../Model/actionCreator";

interface Props {
  id: number;
}

export const DownloadFileButton: React.FC<Props> = ({ id }) => {
  const getFile = async () => {
    await downloadFile(id);
  };
  return (
    <Button onClick={getFile} theme="dark">
      Скачать файл
    </Button>
  );
};
