import { useEffect, useState } from "react";
import {
  isContainCapitalLetter,
  isContainCharacterFromList,
  isContainDigit,
  isContainSpecCharacter,
  isEmail,
  isEndWithStringFromList,
  isMatchPattern,
  isNotIncludedInTheList,
  isOnlyRussAndEngLetters,
  isPassword,
  maxLength,
  minLength,
  requiredFunc,
} from "../Validation/validationFuncs";

interface ValidatorProps {
  value: string;
  options: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    isMatchPattern?: RegExp;
    isContainCapitalLetter?: boolean;
    isContainCharacterFromList?: string[];
    isEndWithStringFromList?: string[];
    isEmail?: boolean;
    isContainDigit?: boolean;
    isContainSpecCharacter?: boolean;
    isPassword?: boolean;
    isOnlyRussAndEngLetters?: boolean;
    isNotIncludedInTheList?: string[];
  };
  messageText?: validatorMessageType;
}

type validatorMessageType = {
  [key in keyof ValidatorProps["options"]]: string;
};

type validatorIsValidType = {
  [key in keyof ValidatorProps["options"]]: boolean;
};

export interface ValidationReturn {
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isValidByOption: validatorIsValidType;
  isValid: boolean;
  message: validatorMessageType;
}

export const useValidationFieldForm = ({
  value,
  options,
  messageText,
}: ValidatorProps): ValidationReturn => {
  const [isDirty, setIsDirty] = useState<boolean>(!!value);
  const [message, setMessage] = useState<validatorMessageType>({});
  const [isValid, setIsValid] = useState<validatorIsValidType>({});
  const [generalValid, setGeneralValid] = useState<boolean>(false);

  useEffect(() => {
    const mess: validatorMessageType = message;
    const status: validatorIsValidType = {};

    const validityСheckItem = (item: string) => {
      const arrKey = Object.keys(options) as Array<
        keyof ValidatorProps["options"]
      >;
      arrKey.forEach((optionName) => {
        const params = options[optionName];
        const defaultMessage: validatorMessageType = {};
        if (params) {
          switch (optionName) {
            case "required":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = requiredFunc(item);
                  defaultMessage[optionName] =
                    "Данное поле обязательно для заполнения";
                }
              }
              break;
            case "minLength":
              {
                if (typeof params === "number") {
                  status[optionName] = minLength({ text: item, params });
                  defaultMessage[optionName] =
                    "Значение данного поля должно быть не короче " +
                    params +
                    " символа(ов)";
                }
              }
              break;
            case "maxLength":
              {
                if (typeof params === "number") {
                  status[optionName] = maxLength({ text: item, params });
                  defaultMessage[optionName] =
                    "Значение данного поля должно быть не длинее " +
                    params +
                    " символа(ов)";
                }
              }
              break;
            case "isMatchPattern":
              {
                if (params instanceof RegExp) {
                  status[optionName] = isMatchPattern({ text: item, params });
                  defaultMessage[optionName] =
                    "Значение данного поля должно соответствовать следующему паттерну: " +
                    params;
                }
              }
              break;
            case "isContainCapitalLetter":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = isContainCapitalLetter({ text: item });
                  defaultMessage[optionName] =
                    "Значение данного поля должно содержать в себе хотя бы одну заглавную букву латинского алфавита";
                }
              }
              break;
            case "isContainCharacterFromList":
              {
                if (Array.isArray(params)) {
                  status[optionName] = isContainCharacterFromList({
                    text: item,
                    params,
                  });
                  defaultMessage[optionName] =
                    "Значение данного поля должно содержать хотя бы один специальный символ: " +
                    params.reduce((sum, item) => {
                      return sum + ", " + item;
                    }, "");
                }
              }
              break;
            case "isEndWithStringFromList":
              {
                if (Array.isArray(params)) {
                  status[optionName] = isEndWithStringFromList({
                    text: item,
                    params,
                  });
                  defaultMessage[optionName] =
                    "Значение данного поля должно оканчиваться определенным образом: " +
                    params.reduce((sum, item) => {
                      return sum + ", " + item;
                    }, "");
                }
              }
              break;
            case "isEmail":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = isEmail(item);
                  defaultMessage[optionName] =
                    "В данное поле нужно ввести email";
                }
              }
              break;
            case "isContainDigit":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = isContainDigit(item);
                  defaultMessage[optionName] =
                    "Данное поле должно содержать хотя бы одну цифру";
                }
              }
              break;
            case "isContainSpecCharacter":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = isContainSpecCharacter(item);
                  defaultMessage[optionName] =
                    "Данное поле должно содержать хотя бы один специальный символ: " +
                    `!@#$%^&* _-+='|` +
                    "\\" +
                    `() {} []:"" <>,.? / "`;
                }
              }
              break;
            case "isPassword":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = isPassword(item);
                  defaultMessage[optionName] =
                    "Данное поле должно состоять только из латинских букв, цифр или спецсимволов: " +
                    `!@#$%^&*_-+='|` +
                    "\\" +
                    `(){}[]:""<>,.?/"`;
                }
              }
              break;
            case "isOnlyRussAndEngLetters":
              {
                if (typeof params === "boolean" && params) {
                  status[optionName] = isOnlyRussAndEngLetters(item);
                  defaultMessage[optionName] =
                    "Поле должно содержать только русские или английские буквы";
                }
              }
              break;
            case "isNotIncludedInTheList":
              {
                if (Array.isArray(params)) {
                  status[optionName] = isNotIncludedInTheList(item, params);
                  defaultMessage[optionName] =
                    "Значение данного поля должно быть уникальным";
                }
              }
              break;
            default:
              break;
          }
        }
        if (status[optionName] === false) {
          if (messageText && typeof messageText[optionName] === "string") {
            mess[optionName] = messageText[optionName];
          } else {
            mess[optionName] = defaultMessage[optionName];
          }
        } else {
          mess[optionName] = "";
        }
      });
    };
    validityСheckItem(value);

    setMessage(mess);

    setIsValid(status);

    setGeneralValid(
      Object.values(status).reduce((sum, item) => sum && item, true)
    );
  }, [value]);

  return {
    isDirty,
    setIsDirty,
    isValidByOption: isValid,
    isValid: generalValid,
    message,
  };
};
