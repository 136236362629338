import React, { useEffect, useState } from "react";
import "./style.scss";
import { QRShow } from "Features/QRShow";
import {
  BackgroundVideo,
  GeneralInfo,
  NotificationBox,
  SuccessTickIcon,
} from "Shared/UI";
import { useAppDispatch, useAppSelector } from "Shared/Model";
import { DisplayDataAboutSiteForm } from "Features/DisplayDataAboutSiteForm";
import { getQRSession } from "Features/GetQRSession";
import { recieveQR } from "Features/RecieveQR";
import { DisplayDataAboutFile } from "Features/DisplayDataAboutFile";

export const NotTrustPage = () => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const QRState = useAppSelector((state) => state.qr);
  const dispatch = useAppDispatch();
  const [statusPage, setStatusPage] = useState<"Loading" | "QR" | "Form">(
    "Loading"
  );
  // const socket = useRef<WebSocket>();

  const onClickUpdateQRBtn = () => {
    setStatusPage("Loading");
  };

  useEffect(() => {
    if (QRState.QRResponsePasswordItem === null && QRState.QRSession === null) {
      setStatusPage("Loading");
    }
    if (QRState.QRResponsePasswordItem === null && QRState.QRSession !== null) {
      setStatusPage("QR");
    }

    if (QRState.QRResponsePasswordItem !== null && QRState.QRSession === null) {
      setStatusPage("Form");
    }
  }, [QRState.QRResponsePasswordItem, QRState.QRSession]);

  const getSiteItemByQR = async () => {
    if (QRState.QRSession) {
      const result = await dispatch(
        recieveQR(QRState.QRSession.id, QRState.QRSession.security_key)
      );
      if (result) {
        setStatusPage("Form");
      }
    }
  };

  useEffect(() => {
    if (statusPage === "Loading") {
      dispatch(getQRSession());
    }
    if (statusPage === "QR") {
      // socket.current = new WebSocket(
      //   "ws://localhost:6001/app/1?protocol=7&client=js&version=4.3.1"
      // );

      // socket.current.onopen = (e) => {
      //   console.log("socket.current.onopen", e);
      // };
      // socket.current.onclose = (e) => {
      //   console.log("socket.current.onclose", e);
      // };
      // socket.current.onmessage = (e) => {
      //   console.log("socket.current.onmessage", e);
      // };
      // socket.current.onerror = (e) => {
      //   console.log("socket.current.onerror", e);
      // };

      setTimeout(() => {
        getSiteItemByQR();
      }, 60000);
    }
  }, [statusPage]);

  const context = () => {
    switch (statusPage) {
      case "Loading":
        return (
          <QRShow
            value={null}
            onClickUpdateQRBtn={onClickUpdateQRBtn}
            isLoading={true}
          />
        );
      case "QR":
        return (
          <>
            {QRState.QRSession !== null && (
              <QRShow
                value={QRState.QRSession}
                onClickUpdateQRBtn={onClickUpdateQRBtn}
              />
            )}
          </>
        );
      case "Form":
        return (
          QRState.QRResponsePasswordItem &&
          (QRState.QRResponsePasswordItem.isFile ? (
            QRState.QRResponsePasswordItem.url && (
              <DisplayDataAboutFile
                currentFile={{
                  id: Number(QRState.QRResponsePasswordItem.id),
                  name: QRState.QRResponsePasswordItem.name,
                  extension: QRState.QRResponsePasswordItem.login,
                }}
                createDate={QRState.QRResponsePasswordItem.updated_at}
                readyBtnFunc={() => {
                  setStatusPage("Loading");
                }}
              />
            )
          ) : (
            <DisplayDataAboutSiteForm
              siteName={QRState.QRResponsePasswordItem.name}
              siteDate={QRState.QRResponsePasswordItem.updated_at}
              userName={QRState.QRResponsePasswordItem.auth}
              login={QRState.QRResponsePasswordItem.login}
              password={QRState.QRResponsePasswordItem.password}
              readyBtnFunc={() => {
                setStatusPage("Loading");
              }}
              iconSlot={<SuccessTickIcon />}
            />
          ))
        );
      default:
        return <></>;
    }
  };
  return (
    <main className="notTrustPage">
      <BackgroundVideo />
      <GeneralInfo
        withUsingExplain={false}
        className="notTrustPage__messegeTop"
      />
      {isAuth ? (
        <NotificationBox
          title={"Вы уже авторизованы!"}
          content={
            <>
              <p>
                Если хотите воспользоваться данной страницей, выйдете из своего
                аккаунта
              </p>
              <p>Перенаправляем на страницу личного кабинета.</p>
            </>
          }
        />
      ) : (
        context()
      )}
    </main>
  );
};
