import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FileError, FileItem } from "../Type/fileType";

type StatusType = {
  status: "success" | "error";
  message: string;
};

interface InitialStateProps {
  fileItems: FileItem[];
  isLoading: boolean;
  error: FileError | null;
  statusMessage: StatusType | null;
}
const initialState: InitialStateProps = {
  fileItems: [],
  isLoading: false,
  error: null,
  statusMessage: null,
};

export const FileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    onFetchFile(state) {
      state.isLoading = true;
    },
    onFetchFileError(state, action: PayloadAction<FileError | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onChangeStatusMessageFile(state, action: PayloadAction<StatusType | null>) {
      state.statusMessage = action.payload;
    },

    onFetchAllFile(state, action: PayloadAction<FileItem[]>) {
      state.isLoading = false;
      state.error = null;
      state.fileItems = action.payload;
    },
    onCreateFile(state, action: PayloadAction<FileItem>) {
      state.fileItems?.push(action.payload);
    },
    onChangeFile(state, action: PayloadAction<FileItem>) {
      const indexItem = state.fileItems.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (indexItem >= 0) {
        state.fileItems[indexItem] = action.payload;
      } else {
        console.log("Пароля с таким id не существует");
        throw new Error("Пароля с таким id не существует");
      }
    },
    onDeleteFile(state, action: PayloadAction<number>) {
      const indexItem = state.fileItems.findIndex((item) => {
        return item.id === action.payload;
      });
      if (indexItem >= 0) {
        state.fileItems.splice(indexItem, 1);
      } else {
        console.log("Каталога с таким id не существует");
        throw new Error("Каталога с таким id не существует");
      }
    },
    onDeleteAllFilesFromOneCatalog(state, action: PayloadAction<number>) {
      const allIndexesItemFromCatalog: number[] = [];

      state.fileItems.forEach((item, index) => {
        if (item.catalogue_id === action.payload) {
          allIndexesItemFromCatalog.push(index);
        }
      });

      allIndexesItemFromCatalog.forEach((index) => {
        state.fileItems.splice(index, 1);
      });
    },
    onResetFileState(state) {
      state.fileItems = initialState.fileItems;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.statusMessage = initialState.statusMessage;
    },
  },
});

export default FileSlice.reducer;
