import React from "react";
import "./style.scss";
import { CreateAndChangePasswordForm } from "Widgets/CreateAndChengePasswordForm";
import { useNavigate } from "react-router-dom";
import { PasswordItem } from "Entities/Password";
import { useAppSelector } from "Shared/Model";
import { routeEndpoint } from "Shared/Routes";
import { NotificationBox } from "Shared/UI";

export const UpdatePasswordPage = () => {
  const navigate = useNavigate();

  const CatalogPageState = useAppSelector((state) => state.catalogPage);
  const PasswordsState = useAppSelector((state) => state.passwords);

  const PasswordItem: PasswordItem | undefined =
    CatalogPageState.currentPassword !== null
      ? CatalogPageState.currentPassword
      : undefined;

  return (
    <main className="updatePasswordPage">
      {PasswordsState.statusMessage && (
        <NotificationBox
          position="absolute"
          correct={PasswordsState.statusMessage.status === "success"}
          content={PasswordsState.statusMessage.message}
        />
      )}
      {PasswordItem && (
        <CreateAndChangePasswordForm
          formType="change"
          passwordItem={PasswordItem}
          onClickCancelFunc={() => {
            navigate("/" + routeEndpoint.catalog);
          }}
          onClickSubmitFunc={() => {
            navigate("/" + routeEndpoint.catalog);
          }}
        />
      )}
    </main>
  );
};
