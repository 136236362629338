import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QRError, QRResponsePasswordItem, QRSesseonItem } from "../Type/QRType";

type StatusType = {
  status: "success" | "error";
  message: string;
};

interface InitialStateProps {
  QRSession: QRSesseonItem | null;
  QRResponsePasswordItem: QRResponsePasswordItem | null;
  isLoading: boolean;
  error: QRError | null;
  statusMessage: StatusType | null;
}
const initialState: InitialStateProps = {
  QRResponsePasswordItem: null,
  QRSession: null,
  isLoading: false,
  error: null,
  statusMessage: null,
};

export const QRSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {
    onFetchQR(state) {
      state.isLoading = true;
    },
    onFetchQRError(state, action: PayloadAction<QRError | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onChengeStatusMessageQR(state, action: PayloadAction<StatusType | null>) {
      state.statusMessage = action.payload;
    },
    onChengeQRSesion(state, action: PayloadAction<QRSesseonItem | null>) {
      state.QRSession = action.payload;
    },
    onChengeQRResponsePasswordItem(
      state,
      action: PayloadAction<QRResponsePasswordItem | null>
    ) {
      state.QRResponsePasswordItem = action.payload;
    },
  },
});

export default QRSlice.reducer;
