import React from "react";
import { Button } from "../../Shared/UI/Button/ui";
import "./style.scss";
import { routeEndpoint } from "Shared/Routes";

interface Props {
  classNames?: string;
}

export const SelectAuthForm = ({ classNames = "" }: Props) => {

  return (
    <section className={`selectAuthForm ${classNames ? " " + classNames : ""}`}>
      <div className="selectAuthForm__messege">
        <h3 className="selectAuthForm__title">Отлично!</h3>
        <p className="selectAuthForm__description">
          Теперь вы можете продолжить работу<br/>
          с сервисом.
        </p>
      </div>
      <div className="selectAuthForm__choises">
        <Button
          theme="bright"
          tag="NavLink"
          to={"/" + routeEndpoint.registration}
          className={`choise`}
        >
          Зарегистрироваться
        </Button>
        <Button
          tag="NavLink"
          to={"/" + routeEndpoint.login}
          theme="dark"
          className={`choise`}
        >
          Войти
        </Button>
      </div>
    </section>
  );
};
